import React from 'react';

import './StorageInfoBar.css';

function StorageInfoBar({ storageInfo }) {

    return (
        <div className="row justify-content-center storage-info-container">
            <div className="w-75 mt-3">
                <div className='storage-info-text-top d-flex justify-content-between text-sm'>
                    <div className='storage-info-text-used'>Used: {storageInfo.Storage_Used}</div>
                    <div className='storage-info-text-free'>Free: {storageInfo.Storage_Available}</div>
                </div>
                <div className='storage-info-bar'>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: `${storageInfo.Percentage_Used}%` }} aria-valuenow={storageInfo.Percentage_Used} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
                {storageInfo.Is_Running_Out && (
                    <div className='storage-info-text-bottom text-muted'>
                        <p>You are almost out of space. To change your storage plan <a className='text-bold'>click here!</a></p>
                    </div>
                )}
                {!storageInfo.Is_Running_Out && (
                    <div className='my-4'></div>
                )}
            </div>
        </div>
    )
}

export default StorageInfoBar;