// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';

// Styles
import './ProductOverviewModal.css';

// Graphic Components
import Modal from "react-bootstrap/Modal";
import { Card } from 'react-bootstrap';
import product_catalog from '../../../Utils/svg_icons/product_catalog.svg'

function ProductOverviewModal({show, onHide, productInfo}){

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="productOverviewModal"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex h-100 box-grow-scroll'>
                    <Card className='product-info'>
                        <Card.Img variant="top" src={product_catalog} />
                        <Card.Body className='d-flex flex-column justify-content-center'>
                            <Card.Title className='text-center'>7702090048704</Card.Title>
                            <Card.Subtitle className='text-center'>Té Hatsu</Card.Subtitle>
                            <div className='product-info-details mt-4'>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label col-form-label-sm px-2">SKU</label>
                                    <div className="col-sm-8 px-2">
                                        <div className='form-control form-control-sm'></div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label col-form-label-sm px-2">CADENA</label>
                                    <div className="col-sm-8 px-2">
                                        <div className='form-control form-control-sm'></div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label col-form-label-sm px-2">AÑO</label>
                                    <div className="col-sm-8 px-2">
                                        <div className='form-control form-control-sm'></div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className='product-statistics'>
                        <Card.Body className='d-flex flex-column justify-content-center'>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label col-form-label-sm px-2">Inicio de ventas</label>
                                <div className="col-sm-6 px-2">
                                    <div className='form-control form-control-sm border-0'>(Primer mes)</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label col-form-label-sm px-2">Último mes con venta</label>
                                <div className="col-sm-6 px-2">
                                    <div className='form-control form-control-sm border-0'>(Último mes)</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label col-form-label-sm px-2">Ventas Val YTD</label>
                                <div className="col-sm-6 px-2">
                                    <div className='form-control form-control-sm border-0'>(Ventas ACU)</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label col-form-label-sm px-2">Precio Prom</label>
                                <div className="col-sm-6 px-2">
                                    <div className='form-control form-control-sm border-0'>(Precio prom del año)</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label col-form-label-sm px-2">Precio Min</label>
                                <div className="col-sm-6 px-2">
                                    <div className='form-control form-control-sm border-0'>(Precio mínimo del año)</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label col-form-label-sm px-2">Precio Max</label>
                                <div className="col-sm-6 px-2">
                                    <div className='form-control form-control-sm border-0'>(Precio máximo del año)</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label col-form-label-sm px-2">Ranking Subcategoria</label>
                                <div className="col-sm-6 px-2">
                                    <div className='form-control form-control-sm border-0'>(Ranking)</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label col-form-label-sm px-2">Dimensiones</label>
                                <div className="col-sm-6 px-2">
                                    <div className='form-control form-control-sm border-0'>(12 x 11 x 4)</div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <div className='d-flex flex-wrap product-charts-grid'>
                        <Card className='product-chart'></Card>
                        <Card className='product-chart'></Card>
                        <Card className='product-chart'></Card>
                        <Card className='product-chart'></Card>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

ProductOverviewModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}

export default ProductOverviewModal;