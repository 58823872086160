import axios from "axios"
import CryptoJS from "crypto-js"
import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/Key';

export async function getKey(token) {
	try {
		let url = `${mainUrl}/Encrypt`
		let config = {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		}
		const response = await axios.get(url, config);
		const data = response.data;
		return data;
	}
	catch (e) {
		console.log(e);
		return {};
	}
}

export async function getSecret2(token) {
	try {
		let url = `${mainUrl}/Key/Secret`
		let config = {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		}
		const response = await axios.get(url, config);
		const data = response.data;
		return data;
	}
	catch (e) {
		console.log(e);
		return null;
	}
}

export async function getSecret(token) {
	return "My secret phrase";
}

function getMessageEncoding(msg) {
	let enc = new TextEncoder();
	return enc.encode(msg);
}

/*
  Get the encoded message, encrypt it and display a representation
  of the ciphertext in the "Ciphertext" element.
  */
export async function encryptMessage(apiToken, message) {
	try {
		let encoded = getMessageEncoding(message);
		let keyRaw = await getKey(apiToken);
		let key = await window.crypto.subtle.importKey('raw', new Uint8Array(keyRaw), 'RSA-OAEP', true, ['encrypt', 'decrypt']);
		let ciphertext = await window.crypto.subtle.encrypt(
			{
				name: "RSA-OAEP"
			},
			key,
			encoded
		);

		return ciphertext;
	} catch (e) {
		console.log(e);
		return null;
	}
}

export async function decryptMessage(apiToken, ciphertext) {
	try {
		let keyRaw = await getKey(apiToken);
		let key = await window.crypto.subtle.importKey('raw', new Uint8Array(keyRaw), 'RSA-OAEP', true, ['encrypt', 'decrypt']);
		let decrypted = await window.crypto.subtle.decrypt(
			{
				name: "RSA-OAEP"
			},
			key,
			ciphertext
		);

		let dec = new TextDecoder();
		return dec.decode(decrypted);
	} catch (e) {
		console.log(e);
		return null;
	}

}

export async function encryptMessageAES(text, token){
	try{
		let secret = await getSecret(token);
		let ciphertext = CryptoJS.AES.encrypt(text, secret);
		return ciphertext;
	} catch(e){
		return null;
	}
}

export async function decryptMessageAES(ciphertext, token){
	try{
		let secret = await getSecret(token);
		var bytes  = CryptoJS.AES.decrypt(ciphertext, secret);
		var originalText = bytes.toString(CryptoJS.enc.Utf8);
		return originalText;
	} catch(e){
		return null;
	}
}