// packages
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { AbortController } from "@azure/abort-controller";

// styles
import './AsyncFunctionalities.css';

// services
import { newFile } from '../../../Services/Helpers/filesHelper';
import filesService from '../../../Services/HttpService/FilesService';
import { uploadFile } from '../../../Services/Other/BlobStorageService';
import { apiLoginRequest } from '../../../authConfig';

// React Components
import { Route, Switch } from 'react-router-dom';
import SystemSelect from '../../Routes/SystemSelect/SystemSelect';
import Home from '../../Routes/Home/Home';
import CreateLayout from '../../Routes/CreateLayout/CreateLayout';
import FilesComponent from '../../Routes/Files/FilesComponent';
import TransformationProcess from '../../Routes/TransformationProcess/TransformationProcess';
import ActiveProcesses from '../../Routes/ActiveProcesses/ActiveProcesses';
import Test from '../../Routes/Test/Test';
import FilesProgressSideBar from "./Children/FilesProgressSideBar/FilesProgressSideBar";
import DashboardAdmin from '../../Routes/DashboardAdmin/DashboardAdmin';
import DashboardVisualization from '../../Routes/DashboardVisualization/DashboardVisualization';
import InformationModal from '../../Reusable/CustomModal/InformationModal/InformationModal';
import ConnectionTest from '../../Routes/ConnectionTest/ConnectionTest';
import DataPuller from '../../Routes/DataPuller/DataPuller';
import CatalogMaster from '../../Routes/Catalogs/CatalogMaster';
import Catalogs from '../../Routes/Catalogs/Catalogs';
import QuickOverview from '../../Routes/QuickOverview/QuickOverview';
import DistributorsTest from '../../Routes/DistributorsTest/DistributorsTest';
import PBIVisualization from '../../Routes/PBIVisualization/PBIVisualization';
import Landing from '../../Routes/LandingPage/LandingPage';
import QuickOverviewPepsico from '../../Routes/QuickOverview/QuickOverviewPepsico';
import LegacyProcessing from '../../Routes/LegacyProcessing/LegacyProcessing';
import HelpPage from '../../Routes/HelpPage/HelpPage';
import ProductPhotos from '../../Routes/ProductPhotos/ProductPhotos';

function AsyncFunctionalities() {
    const [ filesInProgress, setFilesInProgress ] = useState([]);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ barVisible, setBarVisible ] = useState(false);
    const systemId = useSelector(state => state.systems.selectedSystem);
    const systemsList = useSelector(state => state.systems.systemsList);
    const menu = useSelector(state => state.menu.selectedMenu);
    const { instance, accounts, inProgress } = useMsal();

    const updateProgressState = (fileName, fileId, progress, onProcessCompleted) => {
        setFilesInProgress((prev) => {
            let files = [ ...prev ];

            let flag = files.filter(f => f.id === fileId).length > 0;

            if (flag) {
                for (let i = 0; i < files.length; i++)
                    if (files[i].id === fileId){
                        files[i].progress = progress;
                        if(progress === 100){
                            onProcessCompleted();
                        }
                    }
            } else {
                files.push({
                    id: fileId,
                    name: fileName,
                    progress: progress,
                    controller: null
                });
            }

            return files;
        });
    }

    const addAbortController = (fileId, controller) => {
        setFilesInProgress(prev => {
            let files = [ ...prev ];

            files = files.map(file => {
               if (file.id === fileId) {
                   file.controller = controller;
                   return file;
               }
               else
                   return file;
            });

            return files;
        });
    }

    const handleUploadAbort = (controller, id, progress) => {

        if (progress < 100) {
            try {
                controller.abort();
                (async () => {
                    if (inProgress === 'none' && accounts.length > 0) {
                        let authResult = await instance.acquireTokenSilent({
                            account: accounts[0]
                        });
                        await filesService.deleteFileRef(id, authResult.accessToken);
                    }
                })();
            }
            catch (e) {}
        }

        setFilesInProgress(prev => {
            let files = [ ...prev ];
            let filteredFiles = files.filter(f => f.id !== id);
            return filteredFiles;
        });

        if(filesInProgress.length == 0) setBarVisible(false);
    }

    const clearAllItems = () => {
        setFilesInProgress(prev => {
            let files = [...prev];
            let filteredFiles = files.filter(f => f.progress != 100);
            console.log(filteredFiles);
            return filteredFiles;
        });
    }

    const componentAsyncFileUpload = (event, parentId, loadContent, onUploadCompleted, triggerMenu, fileName = null) => {
        if (inProgress !== 'none' || accounts.length === 0) { return };

        const owner = accounts[0].username.replace('.com', '');
        const localSystemsList = [ ...systemsList ];
        const localSystem = localSystemsList.filter(sys => sys["System_Id"] === systemId);
        const container = localSystem[0]["Container"];

        const localName = `${fileName == null ? event.target.files[0].name.split('.')[0] : fileName}`;
        const size = Math.ceil(event.target.files[0].size / 1024);
        const ext = event.target.files[0].name.split('.')[1];
        const fileInfo = newFile(localName, container, ext, parentId, systemId, menu.Menu_Id, owner, size);

        (async() => {
            let authResult = await instance.acquireTokenSilent({
                ...apiLoginRequest,
                account: accounts[0]
            });
            let fileId = await filesService.createNewFileRef(fileInfo, authResult.accessToken);

            if ((fileId || fileId === 0) && fileId != -100) {
                // update progress callback function
                const uploadCompleted = () => onUploadCompleted(parentId, fileId);
                updateProgressState(localName, fileId, 0, uploadCompleted);
                const updateProgress = (per) => updateProgressState(localName, fileId, per, uploadCompleted);

                // abort upload signal definition
                const controller = new AbortController();
                const abortSignal = controller.signal;
                addAbortController(fileId, controller);

                setBarVisible(true);

                let uploadResponse = await uploadFile(event, container, fileId+"."+ext, abortSignal, updateProgress);

                if (uploadResponse === -1) {
                    await filesService.deleteFileRef(fileId, authResult.accessToken);
                } else {
                    // if (menu.Menu_Id === triggerMenu){
                    //     loadContent();
                    // }
                }
            } else {
                // TODO - notify user there was some error when trying to upload the file
                console.log(fileId);
                setShowErrorModal(true);
            }
        })();
    };

    const handleVisibilityChange = (val) => {
        setBarVisible(val);
    }

    return (
        <main className="app-main">
            <Switch>
                <Route exact path="/">
                    <Landing />
                </Route>
                <Route path="/home/:menuId?">
                    <Home />
                </Route>
                <Route path="/create-layout">
                    <CreateLayout />
                </Route>
                <Route path="/files">
                    <FilesComponent asyncFileUpload={componentAsyncFileUpload} />
                </Route>
                <Route path="/transform">
                    <TransformationProcess />
                </Route>
                <Route path="/selector">
                    <DataPuller />
                </Route>
                <Route path='/processes'>
                    <ActiveProcesses />
                </Route>
                <Route path="/test">
                    <Test />
                </Route>
                <Route path="/configurations">
                    <DashboardAdmin />
                </Route>
                <Route path="/dashboards">
                    <DashboardVisualization />
                </Route>
                <Route path="/catalogs">
                    <Catalogs />
                </Route>
                <Route path="/connection-test">
                    <ConnectionTest />
                </Route>
                <Route path="/test-api">
                    <DistributorsTest />
                </Route>
                <Route path="/quick-overview">
                    <QuickOverviewPepsico />
                </Route>
                {/* <Route path="/dashboards-pbi">
                    <PBIVisualization />
                </Route>
                <Route path="/data-upload">
                    <LegacyProcessing />
                </Route>
                <Route path="/help">
                    <HelpPage />
                </Route>
                <Route path="/product-photos">
                    <ProductPhotos asyncFileUpload={componentAsyncFileUpload} />
                </Route> */}
            </Switch>
            {
                filesInProgress.length > 0 ?
                    <FilesProgressSideBar 
                        files={filesInProgress}
                        barVisible={barVisible}
                        visibilityChange={handleVisibilityChange} 
                        handleUploadAbort={handleUploadAbort}
                        handleClearAll={clearAllItems}
                    />
                    : null
            }
            <InformationModal
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
                onCloseBtnClick={() => setShowErrorModal(false)}
                onAcceptBtnClick={() => setShowErrorModal(false)}
                title={"An error ocurred while uploading the file."}
                text={"The file could not be uploaded. Verify that you still have enough storage space and try again."}
            />
        </main>
    );
}

export default AsyncFunctionalities;