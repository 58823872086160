import axios from "axios"
import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/Layout';

const layoutsService = {

    getNodes: async (systemId, menuId, parentId) => {
        try {
            let local_url = null;
            if (parentId || parentId === 0)
                local_url = `${mainUrl}/${systemId}/${menuId}/?ParentId=${parentId}`;
            else
                local_url = `${mainUrl}/${systemId}/${menuId}`;

            let res = await axios.get(local_url);
            return res.data;
        }
        catch (e) {
            // TODO - log error to server
            return [];
        }
    },

    getLayoutGoInput: async (layoutId) => {
        try {
            let res = await axios.get(`${mainUrl}/Input/${layoutId}`);
            return res.data;
        }
        catch (e) {
            // TODO - log error to server
            return []
        }

    },

    getLayoutDefaultParameterValues: async (layoutId) => {
        try {
            let res = await axios.get(`${mainUrl}/Remove/${layoutId}`);
            return res.data;
        }
        catch (e) {
            // TODO - log error to server
            console.log(e);
            return {
                RemoveRow: 0,
                RemoveColumn: 0,
                HasHeader: true,
                PivotRow: 0
            }
        }
    },

    getCurrencyTypes: () => {
        return [
            { value: "USD", dsc: "USD"}, { value: "GTQ", dsc: "GTQ"}, { value: "MXN", dsc: "MXN"}
        ];
    }
}

export default layoutsService;