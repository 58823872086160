// Packages
import PropTypes from "prop-types";

// Styles
import './SelectComponent.css';

function SelectComponent(props) {
    return (
        <div className='mb-3'>
            <label className='selectComponentTitle'>{props.title}</label>
            <select
                className='custom-select d-block'
                disabled={props.selectDisabled}
                name={props.name}
                value={props.value}
                onChange={props.handler}
            >
                {
                    props.options ?
                        props.options.map(
                            (opt, index) => {
                                return (
                                    <option
                                        value={opt.value}
                                        key={`${opt+String(index)}`}
                                        className={props.optionColor}
                                        hidden={opt.hidden}
                                    >
                                        {opt.dsc}
                                    </option>)
                            }
                        ) : null
                }
            </select>
        </div>
    );
}

SelectComponent.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    selectDisabled: PropTypes.bool,
    value: PropTypes.any,
    name: PropTypes.string,
    handler: PropTypes.func.isRequired,
    optionColor: PropTypes.string
}

export default SelectComponent;