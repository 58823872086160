import { useState, useEffect } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//services
import * as TableauDataService from "../../../../../Services/HttpService/TableauDataService"
import * as DashboardDataService from "../../../../../Services/HttpService/DashboardDataService"

//graphic components
import PreviewGroup from "./Children/PreviewGroup";
import SelectedItemsPanel from "./Children/SelectedItemsPanel";
import { useMsal } from "@azure/msal-react";
import InputModal from "../../../../Reusable/CustomModal/InputModal/InputModal";
import ConfirmationModal from "../../../../Reusable/CustomModal/ConfirmationModal/ConfirmationModal";
import { apiLoginRequest } from "../../../../../authConfig";

function EditSelection(props){
    const history = useHistory();
    const { fatherType, id, childType } = useParams();
    const [items, setItems] = useState([]);
    const [originalItems, setOriginalItems] = useState([]);
    const [itemsType, setItemsType] = useState(1);
    const location = useLocation();
    const [ currentProject, setCurrentProject ] = useState();
    const [ currentWorkbook, setCurrentWorkbook ] = useState();
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ azureToken, setAzureToken ] = useState('');
    const [ showInputModal, setShowInputModal ] = useState(false);
    const [ showConfirmModal, setShowConfirmModal ] = useState(false);

    const systemId = useSelector(state => state.systems.selectedSystem);
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        getItems();
        getFolderItems();
    }, [])

    useEffect(() => {
        getItems();
    }, [location]);

    useEffect(() => {
        if (inProgress === 'none' && accounts.length > 0) {
            instance.acquireTokenSilent({
                account: accounts[0],
                ...apiLoginRequest
            }).then(res => {
                console.log(res);
                setAzureToken(res.accessToken);
            })
        }
    }, [ instance, accounts, inProgress ]);

    const getItems = () => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authRes = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let siteFolder = (await DashboardDataService.getSite(props.folderId, authRes.accessToken))[0];
                let serverUrl = siteFolder.Server_Url;
                let siteUrl = siteFolder.Site_Url;
                let siteId = siteFolder.Internal_Id
                let username = siteFolder.Site_User;
                let password = siteFolder.Site_Pass;
                
                await TableauDataService.signin(serverUrl, username, password, siteUrl);
                let data = [];
                if (fatherType && fatherType === 'projects') {
                    if (childType && childType === 'workbooks' && id) {
                        setItemsType(2);
                        data = await TableauDataService.getWorkbooksForProject(serverUrl, siteId, id);
                        data = data.map(item => {
                            return {
                                ...item,
                                server: serverUrl,
                                site: siteId,
                            }
                        })
                        setItems(data);
                    }else if(childType && childType === 'projects' && id){
                        setItemsType(1);
                        data = await TableauDataService.getProjectsForSite(serverUrl, siteId);
                        setOriginalItems(data.projects.project);
                        setItems(data.projects.project.filter(x => x.parentProjectId === id));
                        //setHelpText("Select a project/folder to view its workbooks.");
                    } else {
                        setItemsType(1);
                        data = await TableauDataService.getProjectsForSite(serverUrl, siteId);
                        setOriginalItems(data.projects.project);
                        setItems(data.projects.project.filter(x => x.parentProjectId === undefined));
                        //setHelpText("Select a project/folder to view its workbooks.");
                    }
                }
                else if (fatherType && fatherType === 'workbooks') {
                    if (childType && childType === 'views' && id) {
                        setItemsType(3);
                        data = await TableauDataService.getViewsForWorkbook(serverUrl, siteId, id);
                        data = data.views.view.map(item => {
                            return {
                                ...item,
                                workbookId: id,
                                server: serverUrl,
                                site: siteId
                            }
                        });
                        setItems(data);
                    }
                }
            }
        })();
    }

    const getFolderItems = () => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0){
                let authRes = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let siteFolder = (await DashboardDataService.getSite(props.folderId, authRes.accessToken))[0];
                let workbooksInFolder = [];
                workbooksInFolder = await DashboardDataService.getWorkbooks(siteFolder.Site_Id, authRes.accessToken);
                let selectedViews = []
                workbooksInFolder.forEach(async (workbook) => {
                    let views = await DashboardDataService.getViews(workbook.Workbook_Id, authRes.accessToken);
                    views.forEach(async (view) => {
                        let tableauView = await TableauDataService.getViewDetails(siteFolder.Server_Url, siteFolder.Internal_Id, view.Internal_Id);
                        selectedViews.push(tableauView.view);
                    })
                });
                setSelectedItems(selectedViews);
            }
        })();
    }

    const getChildren = (itemType, itemId, itemName) => {
        let url = '';
        if(itemType === 1){
            let isParentProject = originalItems.some(item => item.parentProjectId && item.parentProjectId == itemId);
            if(isParentProject){
                url = `${props.path}/edit/${props.folderId}/selection/projects/${itemId}/projects`;
            }else{
                url = `${props.path}/edit/${props.folderId}/selection/projects/${itemId}/workbooks`;
            }
            setCurrentProject(itemName);
        }
        else if(itemType === 2){
            url = `${props.path}/edit/${props.folderId}/selection/workbooks/${itemId}/views`;
            setCurrentWorkbook(itemName);
        }
        else if(itemType === 3){
            url = `${props.path}/edit/${props.folderId}/views/${itemId}`;
            props.setViews(currentWorkbook, items, itemName, itemId)
        }
        props.addNavItem(itemName, url);

        history.push(url);
    }

    const onItemSelection = (itemType, itemId, checked) => {
        if(itemType == 3){
            if(checked){
                var item = items.find(x => x.id == itemId);
                setSelectedItems([
                    ...selectedItems,
                    item
                ]);
            }else{
                setSelectedItems(selectedItems.slice().filter(x => x.id !== itemId))
            }
        }
    }

    const removeSelectedItem = (itemId) => {
        setSelectedItems(selectedItems.slice().filter(x => x.id !== itemId))
    }

    const goToWorkbook = (workbookId) => {
        history.push(`${props.path}/edit/${props.folderId}/selection/workbooks/${workbookId}/views`)
    }

    const saveSelectedItems = (folderName) => {
        (async() => {
            let siteId = localStorage.getItem('TableauSiteId');
            let siteContentUrl = localStorage.getItem('TableauSite');
            let siteName = localStorage.getItem('TableauSite');
            let serverUrl = localStorage.getItem('TableauServerUrl');
            let userName = localStorage.getItem('TableauUsername');
            let sitePass = localStorage.getItem('TalbeauPassword');
            let site = (await DashboardDataService.createSite(folderName,siteContentUrl,userName,systemId,sitePass,serverUrl,siteId,azureToken)).data;
            console.log(site);
            if(!site || !site.Site_Id) return;
            let workbooks = await DashboardDataService.getWorkbooks(site.Site_Id,azureToken);
            for(let item of selectedItems){
                let workbook = workbooks.find(x => {
                    return x.Internal_Id == item.workbookId;
                });
                if(!workbook){
                    let tableauWorkbook = (await TableauDataService.getWorkbookDetails(serverUrl,siteId,item.workbookId)).workbook;
                    workbook = (await DashboardDataService.createWorkbook(tableauWorkbook.name,tableauWorkbook.contentUrl,tableauWorkbook.id,site.Site_Id,azureToken)).data;
                    workbooks = await DashboardDataService.getWorkbooks(site.Site_Id,azureToken);
                }
                console.log(workbook);
                let views = await DashboardDataService.getViews(workbook.Workbook_Id,azureToken);
                let view = views.find(x => {
                    return x.Internal_Id == item.id;
                });
                if(!view){
                    view = await DashboardDataService.createView(item.name,item.contentUrl,item.id,workbook.Workbook_Id,azureToken);
                }
                console.log(view);
            }

            setShowInputModal(false);
            history.push(`${props.path}`)
        })();
    }

    const saveSelectedItemsEdited = () => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authRes = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });

                let siteFolder = (await DashboardDataService.getSite(props.folderId, authRes.accessToken))[0];
                let serverUrl = siteFolder.Server_Url;
                let siteUrl = siteFolder.Site_Url;
                let siteId = siteFolder.Internal_Id;
                let folderName = siteFolder.Site_Name;
                let username = siteFolder.Site_User;
                let password = siteFolder.Site_Pass;

                try{
                    await TableauDataService.signin(serverUrl, username, password, siteUrl);
                }catch(e){
                    // TODO: Notify the user
                    return;
                }
                

                let deleteFolder = await DashboardDataService.deleteSite(props.folderId, authRes.accessToken);
                if(deleteFolder.data != 1) return;

                let site = (await DashboardDataService.createSite(folderName, siteUrl, username, systemId, password, serverUrl, siteId, azureToken)).data;
                
                if (!site || !site.Site_Id) return;
                let workbooks = await DashboardDataService.getWorkbooks(site.Site_Id, azureToken);
                
                for (let item of selectedItems) {
                    console.log(item);
                    let workbook = workbooks.find(x => {
                        return (item.workbookId ? x.Internal_Id == item.workbookId : x.Internal_Id == item.workbook.id)
                    });
                    if (!workbook) {
                        let tableauWorkbook = (await TableauDataService.getWorkbookDetails(serverUrl, siteId, item.workbook.id)).workbook;
                        workbook = (await DashboardDataService.createWorkbook(tableauWorkbook.name, tableauWorkbook.contentUrl, tableauWorkbook.id, site.Site_Id, azureToken)).data;
                        workbooks = await DashboardDataService.getWorkbooks(site.Site_Id, azureToken);
                    }
                    let views = await DashboardDataService.getViews(workbook.Workbook_Id, azureToken);
                    let view = views.find(x => {
                        return x.Internal_Id == item.id;
                    });
                    if (!view) {
                        view = await DashboardDataService.createView(item.name, item.contentUrl, item.id, workbook.Workbook_Id, azureToken);
                    }
                }

                setShowInputModal(false);
                history.push(`${props.path}`)
            }
        })();
    }

    const updateSelectedItems = () => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authRes = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });

                // Obtener el sitio que se editará.
                let siteFolder = (await DashboardDataService.getSite(props.folderId, authRes.accessToken))[0];
                let serverUrl = siteFolder.Server_Url;
                let siteUrl = siteFolder.Site_Url;
                let siteId = siteFolder.Internal_Id;
                let folderName = siteFolder.Site_Name;
                let username = siteFolder.Site_User;
                let password = siteFolder.Site_Pass;

                //Solicitar token de autenticación a tableau para obtener datos de libros o vistas en caso de que se deban
                //crear nuevos registros.
                try{
                    await TableauDataService.signin(serverUrl, username, password, siteUrl);
                }catch(e){
                    // TODO: Notify the user
                    return;
                }                
                
                //Si no se encuentra el sitio, regresar.
                if (!siteFolder) return;
                //Obtener los libros dentro del sitio (para poder obtener las vistas de cada uno)
                let workbooks = await DashboardDataService.getWorkbooks(siteFolder.Site_Id, azureToken);
                let prevSelection = [];
                for(let workbook of workbooks){
                    let views = await DashboardDataService.getViews(workbook.Workbook_Id, azureToken);
                    prevSelection = prevSelection.concat(views);
                }

                //Para cada vista en la selección original verificar si existe en la nueva selección, sino, borrarla.
                for(let prev of prevSelection){
                    let foundIndex = selectedItems.findIndex(x => x.id == prev.Internal_Id);
                    if(foundIndex === -1){
                        await DashboardDataService.deleteView(prev.View_Id, azureToken);
                    }
                }

                //Para cada vista en la nueva selección verificar si existe en la nueva selección, sino, crearla.
                for(let item of selectedItems){
                    let foundIndex = prevSelection.findIndex(x => x.Internal_Id == item.id);
                    if(foundIndex === -1){
                        //Antes de crear la nueva vista verificar si ya existe el registro del workbook al que pertenece, sino crearlo.
                        let workbook = workbooks.find(x => {
                            return (item.workbookId ? x.Internal_Id == item.workbookId : x.Internal_Id == item.workbook.id)
                        });
                        if (!workbook) {
                            let tableauWorkbook = (await TableauDataService.getWorkbookDetails(serverUrl, siteId, item.workbookId ?? item.workbook.id)).workbook;
                            workbook = (await DashboardDataService.createWorkbook(tableauWorkbook.name, tableauWorkbook.contentUrl, tableauWorkbook.id, siteFolder.Site_Id, azureToken)).data;
                            workbooks = await DashboardDataService.getWorkbooks(siteFolder.Site_Id, azureToken);
                        }
                        let view = await DashboardDataService.createView(item.name, item.contentUrl, item.id, workbook.Workbook_Id, azureToken);
                    }
                }

                //Recorrer nuevamente los workbooks obteniendo sus vistas. Si alguno se quedó sin vistas, eliminar el registro.
                for(let workbook of workbooks){
                    let views = await DashboardDataService.getViews(workbook.Workbook_Id, azureToken);
                    console.log(views.length);
                    if(views.length === 0){
                        await DashboardDataService.deleteWorkbook(workbook.Workbook_Id, azureToken);
                    }
                }

                setShowInputModal(false);
                history.push(`${props.path}`)
            }
        })();
    }

    const handleCreateFolder = () => {
        setShowConfirmModal(true);
    }

    const handleConfirmCreation = () => {
        updateSelectedItems();
        setShowConfirmModal(false);
    }

    const handleDenyCreation = () => {
        setShowConfirmModal(false);
    }

    return(
        <div className="h-100 d-flex flex-grow-1 flex-column">
            
            <div className="container-fluid d-flex justify-content-center flex-grow-1">
                <PreviewGroup
                    items={items}
                    itemsType={itemsType}
                    getChildren={getChildren}
                    selectItem={onItemSelection}
                    selectedItems={selectedItems}
                />
            </div>
            <SelectedItemsPanel 
                    items={selectedItems}
                    removeSelectedItem={removeSelectedItem}
                    path={props.path}
                    goToWorkbook={goToWorkbook}
                    onSaveClick={handleCreateFolder}
            />
            <InputModal show={showInputModal} onHide={() => setShowInputModal(false)} onCloseBtnClick={() => setShowInputModal(false)} onCreateBtnClick={saveSelectedItems}/>
            <ConfirmationModal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                onAcceptBtnClick={handleConfirmCreation}
                onCancelBtnClick={handleDenyCreation}
                onCloseBtnClick={() => setShowConfirmModal(false)}
                title={"Confirm selection"}
                text={"The new selection will be saved under the folder you selected to edit. This operation can not be reversed."}
            />
        </div>
        
    )
}

export default EditSelection