// packages
import PropTypes from 'prop-types';

// styles
import './BlueButton.css';

// graphic components
import Button from 'react-bootstrap/Button';

function BlueButton({ title, onClick, disabled, block, large }) {
    return (
        <Button
            className='blueButton'
            onClick={onClick}
            disabled={disabled}
            block={block}
            size={large ? 'lg' : 'sm'}
        >
            {title}
        </Button>
    );
}

BlueButton.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    large: PropTypes.bool
}

export default BlueButton;

