import { useState } from "react";
import BlueButton from "../../../../Reusable/BlueButton/BlueButton";
import MoveButton from "../../../../Reusable/MoveButton/MoveButton";
import ChecklistCard from "../../../../Reusable/ChecklistCard/ChecklistCard";
import Select from "react-select";

import "./ParameterCardsPanel.css"
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import LoaderSpinner from "../../../../Reusable/LoaderSpinner/LoaderSpinner";

function ParameterCardsPanel(props) {
    return(
        <div className="ParametersCardsPanel d-flex flex-column flex-grow-1">
            <p className="mt-4 mb-3 mx-4 text-muted">{props.description}</p>
            <hr className="w-100"></hr>
            <div className="box-grow-scroll">
                <div className="filters-list d-flex justify-content-around flex-wrap">
                    {
                        props.parametersList.map((item, index) => {
                            return (
                                <ChecklistCard
                                    Id={item.Parameter_Id}
                                    name={item.Name}
                                    title={item.Description}
                                    values={item.options}
                                    className={`my-4 bg-light dp-params-card ${item.Value_Missing ? 'border border-danger' : ''}`}
                                    key={index}
                                    onValueSelection={props.handleValueSelection}
                                />
                            );
                        })
                    }
                </div>
            </div>
            <div className="actions-row d-flex justify-content-between m-4">
                {/* <MoveButton onClick={props.handleBackButtonClick} /> */}
                <button className="btn btn-lg btn-outline-dark" onClick={props.handleCancelButtonClick}>Cancel</button>
                <div className="d-flex">
                    <button className="btn btn-lg btn-link mr-4">Learn more</button>
                    <button className="btn btn-lg btn-outline-dark ml-4 mr-3" onClick={props.handleBackButtonClick}>Previous</button>
                    <BlueButton
                        title="Next"
                        onClick={props.handleNextButtonClick}
                        large
                    />
                </div>
            </div>
        </div>
    )
}

export default ParameterCardsPanel;