import { useState, useEffect } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//services
import * as TableauDataService from "../../../../../../../Services/HttpService/TableauDataService"
import * as DashboardDataService from "../../../../../../../Services/HttpService/DashboardDataService"
import * as EncryptionService from "../../../../../../../Services/HttpService/EncryptionService"

//graphic components
import LinksBreadcrumb from "../../../../../../Reusable/LinksBreadcrumb/LinksBreadcrumb";
import PreviewGroup from "./Children/PreviewGroup";
import SelectedItemsPanel from "./Children/SelectedItemsPanel";
import { useMsal } from "@azure/msal-react";
import InputModal from "../../../../../../Reusable/CustomModal/InputModal/InputModal";
import ConfirmationModal from "../../../../../../Reusable/CustomModal/ConfirmationModal/ConfirmationModal";
import { apiLoginRequest } from "../../../../../../../authConfig";

function ViewsSelectionStage(props){
    const history = useHistory();
    const { fatherType, id, childType } = useParams();
    const [items, setItems] = useState([]);
    const [originalItems, setOriginalItems] = useState([]);
    const [itemsType, setItemsType] = useState(1);
    const [helpText, setHelpText] = useState();
    const location = useLocation();
    const [ currentProject, setCurrentProject ] = useState();
    const [ currentWorkbook, setCurrentWorkbook ] = useState();
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ azureToken, setAzureToken ] = useState('');
    const [ showInputModal, setShowInputModal ] = useState(false);
    const [ showCredsModal, setShowCredsModal ] = useState(false);

    const systemId = useSelector(state => state.systems.selectedSystem);
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        getItems();
    }, [])

    useEffect(() => {
        getItems();
    }, [location]);

    useEffect(() => {
        if (inProgress === 'none' && accounts.length > 0) {
            instance.acquireTokenSilent({
                account: accounts[0],
                ...apiLoginRequest
            }).then(res => {
                console.log(res);
                setAzureToken(res.accessToken);
            })
        }
    }, [ instance, accounts, inProgress ]);

    const getItems = () => {
        (async () => {
            let serverUrl = localStorage.getItem("TableauServerUrl");
            let siteId = localStorage.getItem("TableauSiteId");
            let data = [];
            // if (inProgress === 'none' && accounts.length > 0) {
            //     let authRes = await instance.acquireTokenSilent({
            //         account: accounts[0]
            //     });
            //     let encryptionTest = await EncryptionService.encryptMessage(authRes.accessToken, "Password");
            //     console.log(encryptionTest);
            //     let decryptionTest = await EncryptionService.decryptMessage(authRes.accessToken, encryptionTest);
            //     console.log(decryptionTest);
            // }
            if (fatherType && fatherType === 'projects') {
                if (childType && childType === 'workbooks' && id) {
                    setItemsType(2);
                    data = await TableauDataService.getWorkbooksForProject(serverUrl, siteId, id);
                    setItems(data);
                    setHelpText("Select a workbook to view its reports/dashboards.");
                } else if(childType && childType === 'projects' && id){
                    setItemsType(1);
                    data = await TableauDataService.getProjectsForSite(serverUrl, siteId);
                    setOriginalItems(data.projects.project);
                    setItems(data.projects.project.filter(x => x.parentProjectId === id));
                    setHelpText("Select a project/folder to view its workbooks.");
                } else {
                    setItemsType(1);
                    data = await TableauDataService.getProjectsForSite(serverUrl, siteId);
                    setOriginalItems(data.projects.project);
                    setItems(data.projects.project.filter(x => x.parentProjectId === undefined));
                    setHelpText("Select a project/folder to view its workbooks.");
                }
            }
            else if (fatherType && fatherType === 'workbooks') {
                if (childType && childType === 'views' && id) {
                    setItemsType(3);
                    data = await TableauDataService.getViewsForWorkbook(serverUrl, siteId, id);
                    data = data.views.view.map(item => {
                        return {
                            ...item,
                            workbookId: id,
                        }
                    });
                    setItems(data);
                    setHelpText("Select a report/dashboard to view its content.");
                }
            }
        })();
    }

    const getChildren = (itemType, itemId, itemName) => {
        let url = '';
        if(itemType === 1){
            let isParentProject = originalItems.some(item => item.parentProjectId && item.parentProjectId == itemId);
            if(isParentProject){
                url = `${props.path}/add/selection/projects/${itemId}/projects`;
            }else{
                url = `${props.path}/add/selection/projects/${itemId}/workbooks`;
            }
            setCurrentProject(itemName);
        }
        else if(itemType === 2){
            url = `${props.path}/add/selection/workbooks/${itemId}/views`;
            setCurrentWorkbook(itemName);
        }
        else if(itemType === 3){
            url = `${props.path}/add/views/${itemId}`;
            props.setViews(currentWorkbook, items, itemName, itemId)
        }
        props.addNavItem(itemName, url);

        history.push(url);
    }

    const onItemSelection = (itemType, itemId, checked) => {
        if(itemType == 3){
            if(checked){
                var item = items.find(x => x.id == itemId);
                setSelectedItems([
                    ...selectedItems,
                    item
                ]);
            }else{
                setSelectedItems(selectedItems.slice().filter(x => x.id !== itemId))
            }
        }
    }

    const removeSelectedItem = (itemId) => {
        setSelectedItems(selectedItems.slice().filter(x => x.id !== itemId))
    }

    const goToWorkbook = (workbookId) => {
        history.push(`${props.path}/add/selection/workbooks/${workbookId}/views`)
    }

    const saveSelectedItems = (folderName) => {
        (async() => {
            let siteId = localStorage.getItem('TableauSiteId');
            let siteContentUrl = localStorage.getItem('TableauSite');
            let siteName = localStorage.getItem('TableauSite');
            let serverUrl = localStorage.getItem('TableauServerUrl');
            let userName = localStorage.getItem('TableauUsername');
            let sitePass = localStorage.getItem('TalbeauPassword');
            let site = (await DashboardDataService.createSite(folderName,siteContentUrl,userName,systemId,sitePass,serverUrl,siteId, accounts[0].username.replace('.com', ''), azureToken)).data;
            console.log(site);
            if(!site || !site.Site_Id) return;
            let workbooks = await DashboardDataService.getWorkbooks(site.Site_Id,azureToken);
            for(let item of selectedItems){
                let workbook = workbooks.find(x => {
                    return x.Internal_Id == item.workbookId;
                });
                if(!workbook){
                    let tableauWorkbook = (await TableauDataService.getWorkbookDetails(serverUrl,siteId,item.workbookId)).workbook;
                    workbook = (await DashboardDataService.createWorkbook(tableauWorkbook.name,tableauWorkbook.contentUrl,tableauWorkbook.id,site.Site_Id,azureToken)).data;
                    workbooks = await DashboardDataService.getWorkbooks(site.Site_Id,azureToken);
                }
                let views = await DashboardDataService.getViews(workbook.Workbook_Id,azureToken);
                let view = views.find(x => {
                    return x.Internal_Id == item.id;
                });
                if(!view){
                    view = await DashboardDataService.createView(item.name,item.contentUrl,item.id,workbook.Workbook_Id,azureToken);
                }
            }
            // let createRole = await DashboardDataService.createRoleSite(role.Role_Id, parseInt(id), authResult.accessToken);

            setShowInputModal(false);
            history.push(`${props.path}`)
        })();
    }

    const handleCreateFolder = () => {
        setShowInputModal(true);
    }

    const handleAcceptCredentials = (folderName) => {

    }

    const handleDenyCredentials = (folderName) => {

    }

    const handleCredentials = (folderName) => {

    }

    return(
        <div className="h-100 d-flex flex-grow-1 flex-column">
            <h5 className="text-muted text-center mx-5 mt-3">{helpText}</h5>
            <div className="container-fluid d-flex justify-content-center flex-grow-1">
                <PreviewGroup
                    items={items}
                    itemsType={itemsType}
                    getChildren={getChildren}
                    selectItem={onItemSelection}
                    selectedItems={selectedItems}
                />
            </div>
            <SelectedItemsPanel 
                    items={selectedItems}
                    removeSelectedItem={removeSelectedItem}
                    path={props.path}
                    goToWorkbook={goToWorkbook}
                    onSaveClick={handleCreateFolder}
            />
            <InputModal show={showInputModal} onHide={() => setShowInputModal(false)} onCloseBtnClick={() => setShowInputModal(false)} onCreateBtnClick={saveSelectedItems}/>
            <ConfirmationModal
                show={showCredsModal}
                onHide={() => setShowCredsModal(false)}
                onAcceptBtnClick={handleAcceptCredentials}
                onCancelBtnClick={handleDenyCredentials}
                onCloseBtnClick={() => setShowCredsModal(false)}
            />
        </div>
        
    )
}

export default ViewsSelectionStage