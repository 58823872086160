// Packages
import { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';

// Styles
import './MainFrame.css';

// Graphic Components
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../Icon/Icon";
import UserSideBar from "../UserSideBar/UserSideBar";

function MainFrameAlt({ content, title, topStyle, frameContext, displayedRoute }) {
    const [ userMenuVisible, setUserMenuVisible ] = useState(false);
    const history = useHistory();

    const handleClick = () => {
        setUserMenuVisible(true);
    };

    const closeClick = () => {
        setUserMenuVisible(false);
    }

    const goHome = () => {
        history.push('/home');
    }

    if (frameContext === 'menu') {
        return (
            <div className='d-flex flex-column mainframeDiv'>
                <div className={`container-fluid d-flex px-4 pt-2 mainframeMenuTop justify-content-between`}>
                    <div className="">
                        <div className='d-flex mainframeMenuBar mr-2'>
                            <FontAwesomeIcon icon={faBars} size='lg' onClick={handleClick} />
                        </div>
                        <div className='d-flex mainframeMenuBar mr-2'>
                            <FontAwesomeIcon icon='home' size='lg' onClick={goHome} />
                        </div>
                    </div>
                    <div className='d-flex py-2'>
                        <Icon specs='gensa-white-sm' handleClick={goHome} />
                    </div>
                </div>
                <div className='flex-grow-1 d-flex flex-column'>
                    { content ? content : null }
                </div>
                <UserSideBar onCloseClick={closeClick} show={userMenuVisible} />
            </div>
        );
    } else {
        return (
            <div className='d-flex flex-column mainframeDiv'>
                <div className={`container-fluid d-flex px-4 py-2 ${topStyle} mainframeTop`}>
                    <div className="d-flex w-25">
                        <div className='d-flex mainframeMenuBar mr-2'>
                            <FontAwesomeIcon icon={faBars} size='lg' onClick={handleClick} />
                        </div>
                        <div className='d-flex mainframeMenuBar mr-2'>
                            <FontAwesomeIcon icon='home' size='lg' onClick={goHome} />
                        </div>
                    </div>
                    { title ?  <h4 className='w-50 text-center mainFrameTitle'><strong>{title} </strong></h4>  : null}
                    <div className='w-25 d-flex flex-row-reverse mainFrameHide py-1'>
                        <Icon specs='gensa-white-sm' handleClick={goHome} />
                    </div>
                </div>
                <div className='flex-grow-1 d-flex flex-column'>
                    { content ? content : null }
                </div>
                <UserSideBar onCloseClick={closeClick} show={userMenuVisible} />
            </div>
        );
    }

}

MainFrameAlt.propTypes = {
    content: PropTypes.any,
    title: PropTypes.string,
    topStyle: PropTypes.string,
    displayedRoute: PropTypes.string,
    frameContext: PropTypes.string
}

export default MainFrameAlt;
