import React from "react";
import { useMsal } from "@azure/msal-react";
import { apiLoginRequest } from "../../../authConfig";
import Button from "react-bootstrap/Button";

function handleLogin(instance) {
    instance.loginRedirect(apiLoginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
function SignInButton({styleClasses = '', text}) {
    const { instance } = useMsal();

    return (
        <Button variant="outline-info" className={styleClasses} onClick={() => handleLogin(instance)}>{text}</Button>
    );
}

export default SignInButton;