// packages
import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

// styles
import './FileResourceSelector.css';

// services
import filesService from "../../../../../../Services/HttpService/FilesService";

// graphic components
import BlueButton from "../../../../../Reusable/BlueButton/BlueButton";
import ResourceSelector from "../../../../../Reusable/ResourceSelector/ResourceSelector";
import { useMsal } from '@azure/msal-react';
import { apiLoginRequest } from '../../../../../../authConfig';

function FileResourceSelector(props) {
    const [ currentContext, setCurrentContext ] = useState([]);
    const [ parentId, setParentId ] = useState(null);
    const [ ancestors, setAncestors ] = useState([]);
    const systemId = useSelector(state => state.systems.selectedSystem);
    const history = useHistory();
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let nodes = await filesService.getNodes(systemId, parentId, authResponse.accessToken);
                if (parentId || parentId === 0)
                    nodes.unshift( { ID: ancestors[ancestors.length-1], Name: '...', Item_Type: 'Folder' });
                setCurrentContext(nodes);
                }
        })();
    }, [parentId, systemId]);

    const filterSelection = (resourceId, resourceType, resourceName) => {
        if (resourceType === 'File')
            props.onSelectResource(resourceId);
        else {
            if (resourceName === '...') {
                let localAncestors = [ ...ancestors ];
                localAncestors.pop();
                setAncestors(localAncestors);
                setParentId(resourceId);
            } else {
                let localAncestors = [ ...ancestors ];
                localAncestors.push(parentId);
                setAncestors(localAncestors);
                setParentId(resourceId);
            }
        }
    }

    const handleNextButtonClick = () => {
        if (props.selectedResource) {
            history.push(`${props.path}/layout`);
        }
    }

    const handleResourceDoubleClick = (resourceId, resourceType, resourceName) => {
        if (resourceType === 'File') {
            props.onSelectResource(resourceId);
            history.push(`${props.path}/layout`);
        } else {
            if (resourceName === '...') {
                let localAncestors = [ ...ancestors ];
                localAncestors.pop();
                setAncestors(localAncestors);
                setParentId(resourceId);
            } else {
                let localAncestors = [ ...ancestors ];
                localAncestors.push(parentId);
                setAncestors(localAncestors);
                setParentId(resourceId);
            }
        }
    }

    return(
        <div className='col-sm-6 px-4 py-1 d-flex flex-column align-items-center fileResourceSelectorBorder'>
            <h5 className="mb-3 fileResourceSelectorTitle">
                <strong>EXISTING FILES</strong>
            </h5>
            <div className="h-75 w-100 mb-4">
                <ResourceSelector
                    onDoubleClick={handleResourceDoubleClick}
                    context={currentContext}
                    onClick={filterSelection}
                    selectedIndex={props.selectedResource}
                />
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3 w-100">
                <div className="mr-1">
                    <BlueButton
                        onClick={handleNextButtonClick}
                        title='Next'
                        large={true}
                        disabled={props.selectedResource < 0}
                    />
                </div>
                <div className="ml-1">
                    <BlueButton
                        onClick={() => console.log('preview still on development')}
                        title='Preview'
                        large={true}
                        disabled={props.selectedResource < 0}
                    />
                </div>
            </div>
        </div>
    );
}

FileResourceSelector.propTypes = {
    onSelectResource: PropTypes.func,
    selectedResource: PropTypes.any,
    path: PropTypes.string
}

export default FileResourceSelector;