import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { React } from 'react'
import { Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'
import MainFrameAlt from '../../Reusable/MainFrameAlt/MainFrameAlt'
import LineChart from "./Children/LineChart";

import './QuickOverview.css';
import { useHistory } from "react-router-dom";

function QuickOverviewContent() {
    const history = useHistory();
    const generalInfoList = [
        { desc: "Number of Categories", value: 3 },{ desc: "Number of Products", value: 581 },{ desc: "Number of Distributors", value: 54 },
        { desc: "Clients Served", value: 2768 },{ desc: "Number of Countries", value: 29 },
    ]
    const sellInKPIs = [
        { desc: "Number of Categories", value: 3 },{ desc: "Number of Products", value: 581 },{ desc: "Number of Distributors", value: 54 },
        { desc: "Number of Countries", value: 29 },
    ]
    const sellOutKPIs = [
        { desc: "Number of Categories", value: 3 },{ desc: "Number of Products", value: 119 },{ desc: "Number of Distributors", value: 1 },
        { desc: "Number of Countries", value: 1 },
    ]
    // const summaryInfoList = [
    //     { desc: "Data available from", value: "1/01/2020" },{ desc: "To", value: "31/12/2020" },{ desc: "Active Users", value: 2 },
    //     { desc: "Databases", value: 1 },{ desc: "Number of Documents", value: 40 },
    // ]
    const sellIn = [
        {
          "Date": "Jan 2020",
          "Sales": 4565544.67
        },
        {
          "Date": "Feb 2020",
          "Sales": 4823817.67
        },
        {
          "Date": "Mar 2020",
          "Sales": 5235227.15
        },
        {
          "Date": "Apr 2020",
          "Sales": 5441700.5
        },
        {
          "Date": "May 2020",
          "Sales": 4504483.84
        },
        {
          "Date": "Jun 2020",
          "Sales": 4551550.39
        },
        {
          "Date": "Jul 2020",
          "Sales": 4741606.19
        },
        {
          "Date": "Aug 2020",
          "Sales": 3971812.02
        },
        {
          "Date": "Sep 2020",
          "Sales": 4836307.92
        },
        {
          "Date": "Oct 2020",
          "Sales": 4893994.07
        },
        {
          "Date": "Nov 2020",
          "Sales": 4847496.59
        },
        {
          "Date": "Dec 2020",
          "Sales": 4681759.49
        },
        {
          "Date": "Jan 2021",
          "Sales": 4856081.18
        },
        {
          "Date": "Feb 2021",
          "Sales": 4451316.85
        },
        {
          "Date": "Mar 2021",
          "Sales": 5186164.53
        },
        {
          "Date": "Apr 2021",
          "Sales": 4418059.27
        },
        {
          "Date": "May 2021",
          "Sales": 5456587.16
        },
        {
          "Date": "Jun 2021",
          "Sales": 5985271.25
        },
        {
          "Date": "Jul 2021",
          "Sales": 4942160.85
        },
        {
          "Date": "Aug 2021",
          "Sales": 4295667.44
        },
        {
          "Date": "Sep 2021",
          "Sales": 5311245.42
        },
        {
          "Date": "Oct 2021",
          "Sales": 5601908.19
        },
        {
          "Date": "Nov 2021",
          "Sales": 4782522.43
        },
        {
          "Date": "Dec 2021",
          "Sales": 3849366.44
        },
        {
          "Date": "Jan 2022",
          "Sales": 4238567.18
        },
        {
          "Date": "Feb 2022",
          "Sales": 5195797.16
        },
        {
          "Date": "Mar 2022",
          "Sales": 4698440.9
        },
        {
          "Date": "Apr 2022",
          "Sales": 5798983.03
        },
        {
          "Date": "May 2022",
          "Sales": 5878666.64
        },
        {
          "Date": "Jun 2022",
          "Sales": 5712570.32
        },
        {
          "Date": "Jul 2022",
          "Sales": 5983588.18
        },
        {
          "Date": "Aug 2022",
          "Sales": 4912291.89
        }
      ]

    const sellOut = [
        {
          "Date": "Jan 2020",
          "Sales": 1465079.36
        },
        {
          "Date": "Feb 2020",
          "Sales": 1257461.75
        },
        {
          "Date": "Mar 2020",
          "Sales": 1551850.17
        },
        {
          "Date": "Apr 2020",
          "Sales": 1468431.2
        },
        {
          "Date": "May 2020",
          "Sales": 1224942.09
        },
        {
          "Date": "Jun 2020",
          "Sales": 1098713.91
        },
        {
          "Date": "Jul 2020",
          "Sales": 1145676
        },
        {
          "Date": "Aug 2020",
          "Sales": 1473308.19
        },
        {
          "Date": "Sep 2020",
          "Sales": 906562.84
        },
        {
          "Date": "Oct 2020",
          "Sales": 1218246
        },
        {
          "Date": "Nov 2020",
          "Sales": 1051342.34
        },
        {
          "Date": "Dec 2020",
          "Sales": 1158982.93
        }
      ]

    const SODataSet = {
        labels: sellOut.map(x => x.Date),
        data: sellOut.map(x => x.Sales)
    }

    const SIDataSet = {
        labels: sellIn.map(x => x.Date),
        data: sellIn.map(x => x.Sales)
    }

    const handleAnalyzeClick = () => {
        history.push('/dashboards')
    }

    return (
        <div className="mx-4 mt-4">
            <h4 className='general-info-title text-center text-underline'>General Information</h4>
            <div className='overview-container mt-4'>
                <div className="w-50 br-1">
                    <div className='overview-general-info w-100'>
                        <h4 className='general-info-title text-center text-underline'>Sell In</h4>
                        <div className='gen-info-cards-container d-flex flex-wrap justify-content-around'>
                            {sellInKPIs.map((item, j) => {
                                return (
                                    <div className='gen-info-card' key={j}>
                                        <div className='gen-info-card-content'>{item.value.toLocaleString()}</div>
                                        <div className='gen-info-card-title'>{item.desc}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    
                </div>
                <div className="w-50">
                    <div className='overview-general-info w-100'>
                        <h4 className='general-info-title text-center text-underline'>Sell Out</h4>
                        <div className='gen-info-cards-container d-flex flex-wrap justify-content-around'>
                            {sellOutKPIs.map((item, j) => {
                                return (
                                    <div className='gen-info-card' key={j}>
                                        <div className='gen-info-card-content'>{item.value.toLocaleString()}</div>
                                        <div className='gen-info-card-title'>{item.desc}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    
                </div>
            </div>
            <h4 className='overview-visualizations-title text-center text-underline mt-3'>Sales Trends</h4>
            <div className='overview-container mt-2'>
                <div className="w-50">
                <div className="overview-visualizations-container w-100">
                        <div className="overview-visualizations d-flex flex-wrap justify-content-around flex-grow-1">
                            <div className="overview-viz">
                                <LineChart title="Sell In" labels={SIDataSet.labels} dataset={SIDataSet.data} datasetLabel="Sales ($)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-50">
                <div className="overview-visualizations-container w-100">
                        <div className="overview-visualizations d-flex flex-wrap justify-content-around flex-grow-1">
                            <div className="overview-viz">
                                <LineChart title="Sell Out" labels={SODataSet.labels} dataset={SODataSet.data} datasetLabel="Sales ($)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end w-100">
                <button type="button" className="btn btn-link m-3" onClick={handleAnalyzeClick}>Start Analyzing</button>
            </div>
        </div>
    )
}

function QuickOverviewPepsico() {
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);
    const authRequest = {
        scopes: ["api://48bcf903-2105-4076-be4d-b926a4240ad6/access_as_user"]
    };


    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
            {
                selectedSystem === -1 ?
                    <Redirect to='/' />
                    :
                    <MainFrameAlt
                        title="Quick Overview"
                        topStyle="align-items-center"
                        content={< QuickOverviewContent />}
                    />
            }
        </MsalAuthenticationTemplate>
    );
}

export default QuickOverviewPepsico;