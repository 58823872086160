import React, { useState } from 'react';
import { models, Report, Embed, service, Page } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import './PowerBIReport.css';
import axios from "axios"
import { useMsal } from "@azure/msal-react";

// Root Component to demonstrate usage of wrapper component
function PowerBIReport () {
    const workspaceId = '0917fa3d-1c56-464f-97f5-091deea9c6a1';
    const reportId = 'bbd17154-d078-478c-9860-07e5c215fcf5';

    const { instance, accounts, inProgress } = useMsal();

	// PowerBI Report object (to be received via callback)
	const [report, setReport] = useState();

	// Report config useState hook
	// Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
	const [sampleReportConfig, setReportConfig] = useState({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Aad,
		permissions: models.Permissions.All,
		accessToken: undefined,
		settings: undefined,
	});

	// Map of event handlers to be applied to the embedding report
	const eventHandlersMap = new Map([
		['loaded', function () {
			console.log('Report has loaded');
		}],
		['rendered', function () {
			console.log('Report has rendered');
			
			// Update display message
			setMessage('The report is rendered')
		}],
		['error', function (event) { 
			if (event) {
				console.error(event.detail);
			}
		}]
	]);

    function authenticate() {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                try{
                    let authResponse = await instance.acquireTokenSilent({
                        account: accounts[0],
                        scopes: ["https://analysis.windows.net/powerbi/api/Workspace.Read.All"]
                    });
                    let embedUrl = await getEmbeddedUrl(authResponse.accessToken);
                    // let embedToken = await getEmbeddedToken(authResponse.accessToken);
                    // Update display message
                    setMessage('The access token is successfully set. Loading the Power BI report')

                    // Set the fetched embedUrl and embedToken in the report config
                    setReportConfig({
                        ...sampleReportConfig,
                        embedUrl: embedUrl,
                        accessToken: authResponse.accessToken
                    });
                }catch(error){
                    if (error.name === "InteractionRequiredAuthError") {
                        instance.acquireTokenRedirect({
                            account: accounts[0],
                            scopes: ["https://analysis.windows.net/powerbi/api/Workspace.Read.All"]
                        });
                    }
                    else {
                        // setError([error.toString()])
                    }
                }
                
            }
            // else{
            //     let response = await instance.loginPopup();
            // }
        })();
    }

    async function getEmbeddedUrl(token) {
        try{
            let url = "https://api.powerbi.com/v1.0/myorg/groups/" + workspaceId + "/reports/" + reportId
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            const response = await axios.get(url, config);
            const data = response.data;
			console.log(data);
            return data["embedUrl"];
        }
        catch(e){
            console.log(e);
            return null;
        }
    }

    async function getEmbeddedToken(token) {
        try{
            let url = "https://api.powerbi.com/v1.0/myorg/groups/"+ workspaceId + "/reports/"+ reportId +"/GenerateToken";
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let data = {
                accessLevel: 'View'
            }
            const response = await axios.post(url, data, config);
            const responseData = response.data;
            console.log(responseData);
            return responseData;
        }
        catch(e){
            console.log(e);
            return null;
        }
    }

	const changeSettings = () => {

		// Update the state "sampleReportConfig" and re-render DemoApp component
		setReportConfig({
			...sampleReportConfig,
			settings: {
				panes: {
					filters: {
						expanded: false,
						visible: false
					}
				}
			}
		});
	};

	// Delete the first visual using powerbi-report-authoring library
	// const deleteVisual = async () => {

	// 	if (!report) {
	// 		console.log('Report not available');
	// 		return;
	// 	}

	// 	const activePage = await getActivePage(report);

	// 	if (!activePage) {
	// 		console.log('No active page');
	// 		return;
	// 	}

	// 	// Get all visuals in the active page
	// 	const visuals = await activePage.getVisuals();

	// 	if (visuals.length === 0) {
	// 		console.log('No visual left');
	// 		return;
	// 	}

	// 	// Get first visible visual
	// 	const visual = visuals.find((v) => {
	// 		return v.layout.displayState?.mode === models.VisualContainerDisplayMode.Visible;
	// 	});

	// 	// No visible visual found
	// 	if (!visual) {
	// 		console.log('No visible visual available to delete');
	// 		return;
	// 	}

	// 	try {
			
	// 		// Documentation link: https://github.com/microsoft/powerbi-report-authoring/wiki/Visualization
	// 		// Delete the visual 
	// 		await activePage.deleteVisual(visual.name);

	// 		console.log('Visual was deleted');
	// 	}
	// 	catch (error) {
	// 		console.error(error);
	// 	}
	// };

	async function getActivePage(powerbiReport) {
		const pages = await powerbiReport.getPages();
	
		// Get the active page
		const activePage = pages.filter(function (page) {
			return page.isActive
		})[0];

		return activePage;
	}

	const [displayMessage, setMessage] = useState(`The report is bootstrapped. Click the Embed Report button to set the access token`);

	const controlButtons = 
		<div className = "report-controls">
			<button className='report-button' onClick = { authenticate }>
				Embed Report</button>

			<button className='report-button' onClick = { changeSettings }>
				Hide filter pane</button>
		</div>;

	const header = 
		<div className = "report-header">
			<div className = "title">Power BI React component demo</div>
		</div>;

	const footer = 
		<div className = "report-footer">
			<div className = "footer-text">
				GitHub: &nbsp;
				<a href="https://github.com/microsoft/PowerBI-client-react">https://github.com/microsoft/PowerBI-client-react</a>
			</div>
		</div>;
	
	return (
		<div>
			{ header }
			
			<PowerBIEmbed
				embedConfig = { sampleReportConfig }
				eventHandlers = { eventHandlersMap }
				cssClassName = { "report-style-class" }
				getEmbeddedComponent = { (embedObject) => {
					console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
					setReport(embedObject);
				} }
			/>

			<div className = "hr"></div>

			<div className = "report-displayMessage">
				{ displayMessage }
			</div>

			{ controlButtons }

			{/* { footer } */}
		</div>
	);
}

export default PowerBIReport;