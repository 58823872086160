import TableauView from "./TableauView"

//graphic components
import { useState, useEffect } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen";

//services
import * as TableauDataService from "../../../../../../../../Services/HttpService/TableauDataService"
import * as DashboardDataService from "../../../../../../../../Services/HttpService/DashboardDataService"

//styles
import styles from "./TableauView.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { apiLoginRequest } from "../../../../../../../../authConfig";

const { tableau } = window;
var viz;

function TableauViewContainer(props) {
    const [currentView, setCurrentView] = useState(props.viewName);
    const [currentViewId, setCurrentViewId] = useState(props.viewId);
    const [currentWorkbook, setCurrentWorkbook] = useState(props.workbookName);
    const [embeddedUrl, setEmbeddedUrl] = useState('')
    const [isLastView, setIsLastView] = useState(false);
    const [isFirstView, setIsFirstView] = useState(false);
    const fullscreenHande = useFullScreenHandle();
    const { id } = useParams();
    const { instance , accounts, inProgress } = useMsal();

    useEffect(() => {
        if(props.viewName && props.workbookName){
            getEmbbededViewUrl(props.workbookName, props.viewName);
            verifyIfFirstOrLast(props.viewId);
        }else{
            setCurrentViewByUrlParams();
        }
        
    }, [])

    const setCurrentViewByUrlParams = () => {
        (async () => {
            let serverUrl = localStorage.getItem("TableauServerUrl");
            let siteId = localStorage.getItem("TableauSiteId");
            let viewResult = await TableauDataService.getViewDetails(serverUrl, siteId, id);
            let workbookResult = await TableauDataService.getWorkbookDetails(serverUrl, siteId, viewResult.view.workbook.id);
            setCurrentView(viewResult.view.name);
            setCurrentWorkbook(workbookResult.workbook.contentUrl);
            setCurrentViewId(viewResult.view.id);
            getEmbbededViewUrl(workbookResult.workbook.contentUrl, viewResult.view.name);
            verifyIfFirstOrLast(viewResult.view.id);
        })();
        // getEmbbededViewUrlNoTicket(currentView, currentWorkbook);
        // verifyIfFirstOrLast(currentViewId);
    }

    function initViz(url) {
        // const urlParameters = '?:showShareOptions=false&:dataDetails=no&:alerts=no&:customViews=no';
        const urlParameters = '';
        var containerDiv = document.getElementById("vizContainer"),
            // url = 'https://views.g3panel.com/t/G3Panel/views/DemoTableauembedded/ResumenProductos',
            url = url+urlParameters,
            options = {
                hideTabs: true,
                hideToolbar: true,
                height: '100%',
                width: '100%',
                device: 'default',
            };

        if(viz) viz.dispose();
        viz = new tableau.Viz(containerDiv, url, options);
        // Create a viz object and embed it in the container div.
    }

    const setCurrentViewById = (viewId) => {
        var result = props.viewsList.find(item => {
            return item.id == viewId;
        });
        if (result) {
            setCurrentView(result.name);
            setCurrentViewId(viewId);
            verifyIfFirstOrLast(viewId);
            getEmbbededViewUrl(props.workbookName, result.name);
        }
    }

    const moveCurrentView = (moveType) => {
        var viewItem = {}
        if (moveType === -2) {
            viewItem = props.viewsList[0];
        }
        else if (moveType === -1 || moveType === 1) {
            var index = props.viewsList.findIndex(x => x.id === currentViewId);
            var newIndex = index + moveType;
            viewItem = props.viewsList[newIndex];
        }
        else if (moveType === 2) {
            viewItem = props.viewsList[props.viewsList.length - 1];
        }
        setCurrentView(viewItem.name);
        setCurrentViewId(viewItem.id);
        verifyIfFirstOrLast(viewItem.id);
        getEmbbededViewUrl(props.workbookName, viewItem.name);
    }

    const verifyIfFirstOrLast = (currentId) => {
        var index = props.viewsList.findIndex(x => x.id === currentId);

        if(index == 0) setIsFirstView(true);
        else setIsFirstView(false);

        if(index == props.viewsList.length-1) setIsLastView(true);
        else setIsLastView(false);
    }

    const getEmbbededViewUrl = (workbookName, viewName) => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let serverUrl = localStorage.getItem("TableauServerUrl");
                let siteName = localStorage.getItem("TableauSite");
                let username = localStorage.getItem("TableauUsername");
                let trustedTicket = (await DashboardDataService.getTrustedTicket(serverUrl, username, siteName, authResult.accessToken)).data;
                const url = constructEmbeddedViewUrl(serverUrl, trustedTicket, siteName, workbookName.replace(/\s/g, '').replace(/[\u00C0-\u00FF]/g, ''), viewName.replace(/\s/g, '').replace(/[\u00C0-\u00FF]/g, ''));
                if(viz) viz.dispose();
                setEmbeddedUrl(url);
                initViz(url);
            }
        })();
    }

    const getEmbbededViewUrlNoTicket = (workbookName, viewName) => {
        let serverUrl = localStorage.getItem("TableauServerUrl");
        let siteName = localStorage.getItem("TableauSite");
        const url = contructEmbeddedUrlNoTicket(serverUrl, siteName, workbookName.replace(/\s/g, '').replace(/[\u00C0-\u00FF]/g, ''), viewName.replace(/\s/g, '').replace(/[\u00C0-\u00FF]/g, ''));
        if(viz) viz.dispose();
        setEmbeddedUrl(url);
        initViz(url);
    }

    const constructEmbeddedViewUrl = (serverUrl, trustedTicket, siteName, workbookName, viewName) => {
        return `https://${serverUrl}/trusted/${trustedTicket}/t/${siteName}/views/${workbookName}/${viewName}?:embed=yes&:toolbar=no`
    }

    const contructEmbeddedUrlNoTicket = (serverUrl, siteName, workbookName, viewName) => {
        return `https://${serverUrl}/t/${siteName}/views/${workbookName}/${viewName}`
    }

    const handleSelectChange = (e) => {
        setCurrentViewById(e.target.value);
    }

    const enableFullScreen = () => {
        
    }

    const exportToPdf = () => {
        viz.showExportPDFDialog();
    }

    const showDownloadDialog = () => {
        console.log(viz);
        viz.showExportCrossTabDialog();
    }

    const refreshData = () => {
        viz.refreshDataAsync();
    }

    return (
        <div className="d-flex flex-grow-1 flex-column">
            <div className="view-controls d-flex justify-content-between my-2">
                <div className="navigation w-50 d-flex justify-content-start">
                    <button disabled={isFirstView} className="btn btn-sm btn-light mx-2 blue-navigation" onClick={() => moveCurrentView(-2)}><FontAwesomeIcon icon="fast-backward" size="sm"/> First</button>
                    <button disabled={isFirstView} className="btn btn-sm btn-light mx-2 blue-navigation" onClick={() => moveCurrentView(-1)}><FontAwesomeIcon icon="step-backward" size="sm"/> Prev</button>
                    <select className="form-control-sm blue-navigation" value={currentViewId} onChange={handleSelectChange}>
                        {props.viewsList.map((viewItem,index) => {
                            return (<option key={index} value={viewItem.id} >{viewItem.name}</option>)
                        })}
                    </select>
                    <button disabled={isLastView} className="btn btn-sm btn-light mx-2 blue-navigation" onClick={() => moveCurrentView(1)}>Next <FontAwesomeIcon icon="step-forward" size="sm"/></button>
                    <button disabled={isLastView} className="btn btn-sm btn-light mx-2 blue-navigation" onClick={() => moveCurrentView(2)}>Last <FontAwesomeIcon icon="fast-forward" size="sm"/></button>
                </div>
                <div className="actions w-50 d-flex justify-content-end">
                    <button className="btn btn-sm btn-light mx-2 blue-navigation" onClick={() => exportToPdf()}>PDF <FontAwesomeIcon icon="file-pdf" size="sm"/></button>
                    <button className="btn btn-sm btn-light mx-2 blue-navigation" onClick={() => refreshData()}>Refresh <FontAwesomeIcon icon="sync" size="sm"/></button>
                    <button className="btn btn-sm btn-light mx-2 blue-navigation" onClick={fullscreenHande.enter}>Fullscreen <FontAwesomeIcon icon="expand" size="sm"/></button>
                    {/* <button className="btn btn-sm btn-outline-dark mx-2" onClick={() => enableFullScreen()}>Fullscreen </button> */}
                </div>
            </div>
            <div className="view-container w-100">
                {/* <TableauView
                    viewUrl={embeddedUrl}
                /> */}
                <FullScreen handle={fullscreenHande}>
                <div id="vizContainer" className=""></div>
                </FullScreen>
            </div>
        </div>
    )
}

export default TableauViewContainer