// Packages
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { useEffect, useState, useRef } from 'react';
import { filterName } from "../../../Services/Helpers/userHelpers";
import { setSelectedSystem } from "../../../Redux/Reducers/systemsSlice";
import { useSelector, useDispatch } from "react-redux";
import useOutsideAlerter  from "../../../Hooks/useOutsideClick";

// Styles
import './UserSideBar.css'

// Services
import userService from "../../../Services/HttpService/UserService";

// Graphic Components
import CloseButton from "../CloseButton/CloseButton";
import LinkThing from "../LinkThing/LinkThing";
import Avatar from "../Avatar/Avatar";
import SelectComponent from "../SelectComponent/SelectComponent";
import { graphLoginRequest } from '../../../authConfig';
import AboutInfoModal from '../CustomModal/AboutInfoModal/AboutInfoModal';

function UserSideBar({ onCloseClick, show }) {
    const history = useHistory();
    const { instance, accounts, inProgress } = useMsal();
    const [ userName, setUserName ] = useState('');
    const [ userPhotoUrl, setUserPhotoUrl ] = useState('');
    const [ systems, setSystems ] = useState([]);
    const [ organizationName, setOrganizationName ] = useState('');
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);
    const userSystems = useSelector((state) => state.systems.systemsList);
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, onCloseClick);

    const [ showAboutModal, setShowAboutModal ] = useState(false);

    useEffect(() => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                try{
                    let authResult = await instance.acquireTokenSilent({
                        account: accounts[0],
                        ...graphLoginRequest
                    });
                    const name = accounts[0].name;
                    const localName = filterName(name);
                    setUserName(localName);
        
                    const org = accounts[0].username.split('@')[1];
                    setOrganizationName(org);
        
                    const photo = await userService.getSignedUserPhoto(authResult.accessToken);
                    const url = window.URL || window.webkitURL;
                    const blobUrl = url.createObjectURL(photo);
                    setUserPhotoUrl(blobUrl);
                }catch(error){
                    if (error.name === "InteractionRequiredAuthError") {
                        instance.acquireTokenRedirect({
                            account: accounts[0],
                            ...graphLoginRequest
                        });
                    }
                    else {
                        // setError([error.toString()])
                    }
                }
            }
        })();
    }, [ accounts, inProgress ])

    useEffect(() => {
        let localSystems = [];
        for (let i = 0; i < userSystems.length; i++) {
            if (userSystems[i].System_Id === selectedSystem) {
                localSystems.unshift({
                    value: userSystems[i]['System_Id'],
                    dsc: userSystems[i]['System_Dsc']
                });
            }
            else {
                localSystems.push({
                    value: userSystems[i]['System_Id'],
                    dsc: userSystems[i]['System_Dsc']
                });
            }
        }

        setSystems(localSystems);
    }, [])

    const handleHistoricalTasks = () => {
        history.push('/processes');
    }

    const handleHomeClick = () => {
        history.push('/home');
    }

    const handleAboutClick = () => {
        setShowAboutModal(true);
    }

    const handleHelpClick = () => {
        // history.push('/processes');
    }

    const handleSettingsClick = () => {
        // history.push('/processes');
    }

    const handleLogoutClick = () => {
        handleLogout(instance);
    }

    function handleLogout(instance) {
        const logoutRequest = {
            mainWindowRedirectUri: window.location.protocol + '//' + window.location.host,
            postLogoutRedirectUri: window.location.protocol + '//' + window.location.host
        }
        instance.logoutPopup(logoutRequest).catch(e => {
            console.error(e);
        })
    }

    const handleChange = (evt) => {
        dispatch(setSelectedSystem(parseInt(evt.target.value)));
    }

    return (
        <div className={`userSideBarFrame ${show ? 'd-flex flex-column align-items-center' : 'd-none'}`} ref={wrapperRef}>
            <div className='d-flex flex-column align-items-center w-100'>
                <div className='d-flex w-100 flex-row-reverse px-3 mt-3'>
                    <CloseButton onClick={onCloseClick} />
                </div>
                <div className='d-flex w-100 justify-content-center'>
                    <Avatar name={userName} organization={organizationName} url={userPhotoUrl} />
                    {/* <Avatar name={'John Smith'} organization={'Your Domain'} url={userPhotoUrl} /> */}
                </div>
            </div>

            <div className='d-flex w-100 flex-column px-4 mt-3'>
                <div className='mb-2'>
                    <SelectComponent
                        optionColor="selectComponentColorBlue"
                        options={systems}
                        handler={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <LinkThing onClick={handleHomeClick} title="Home" icon="home" />
                </div>
                <div className='mb-3'>
                    <LinkThing onClick={handleAboutClick} title='About' icon='about' />
                </div>
                <div className="mb-3">
                    <LinkThing onClick={handleHelpClick} title='Help' icon='help' />
                </div>
                <div className="mb-3">
                    <LinkThing onClick={handleHistoricalTasks} title='Historical Tasks' icon='historical' />
                </div>
            </div>

            <div className='flex-grow-1 w-100 d-flex flex-column justify-content-end px-4 mt-3'>
                <div className='mb-3'>
                    <LinkThing onClick={handleSettingsClick} title='Settings' icon='settings' />
                </div>
                <div className='mb-3'>
                    <LinkThing onClick={handleLogoutClick} title='Log out' icon='logout' />
                </div>
            </div>
            <AboutInfoModal show={showAboutModal} onHide={() => setShowAboutModal(false)} />
        </div>
    );
}

UserSideBar.propTypes = {
      onCloseClick: PropTypes.func.isRequired
};

export default UserSideBar;