import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { OverlayTrigger, Pagination, Popover, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ConfirmationModal from '../../Reusable/CustomModal/ConfirmationModal/ConfirmationModal';
import MainFrame from '../../Reusable/MainFrame/MainFrame';
import CatalogEditorModal from './CatalogEditorModal';
import ColumnFilter from './ColumnFilter/ColumnFilter'

//Styles
import './CatalogMaster.css';
import CatalogMasterPagination from './CatalogMasterPagination';
import CustomInputModal from '../../Reusable/CustomModal/CustomInputModal/CustomInputModal';
import InformationModal from '../../Reusable/CustomModal/InformationModal/InformationModal';
import LoaderSpinner from '../../Reusable/LoaderSpinner/LoaderSpinner';

function CatalogMaster({columns, rows, columnsFilterValues, columnFiltersSelectedValues, title, totalPages, activePage, pageSize, totalRows, viewItem, onPageChange, getColumnFilterValues, addFilter, removeFilter, clearFilters, exportSelection, exportTable, importData, deleteSelection, goToProcesses}) {

    // Initial states
    const [showEditorModal, setShowEditorModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [showStartedModal, setShowStartedModal] = useState(false);
    const [ selectedValues, setSelectedValues ] = useState([]);

    // Function For adding new row object
    const handleAdd = () => {
        setShowEditorModal(true);
    };

    const handleImport = () => {
        importData();
    }

    const handleExport = (fileName) => {
        setShowExportModal(false);

        if(selectedValues.length > 0) exportSelection(selectedValues, fileName);
        else exportTable(fileName);
        
        setShowStartedModal(true);
    }

    const handleDelete = () => {
        setShowDeleteModal(true);
    }

    const handleConfirmDelete = () => {
        setShowDeleteModal(false);
        deleteSelection(selectedValues);
    }

    const handleClear = () => {
        setSelectedValues([]);
    }

    const handleCheckboxChange = (itemId, checked) => {
        if(checked){
            let newSelectedValues = [];
            newSelectedValues = [...selectedValues, itemId];
            setSelectedValues(newSelectedValues);
        }else{
            let newSelectedValues = []
            newSelectedValues = selectedValues.slice().filter(x => x !== itemId);
            setSelectedValues(newSelectedValues);
        }
    }

    const changePage = (i) => {
        onPageChange(i)
    }

    const handleFilterClick = (status, columnId) => {
        if(status){
            getColumnFilterValues(columnId);
        }
    }

    const getFilterValues = (columnId) => {
        let column = columnsFilterValues.find(x => x.columnId === columnId);
        if(column) return column.values;
        else return [];
    }

    const getFilterSelectedValues = (columnName) => {
        let filter = columnFiltersSelectedValues.find(x => x.filterName === columnName);
        if(filter) return filter.values;
        else return [];
    }

    const handleAddFilter = (columnId, columnName, values) => {
        addFilter(columnId, columnName, values);
    }

    const handleRemoveFilter = (columnId, columnName) => {
        removeFilter(columnId, columnName);
    }

    const handleClearFilters = () => {
        clearFilters();
    }

    const handleGoToProcesses = () => {
        goToProcesses();
    }

    return (
        <div className='catalog-master-container box-grow-no-scroll'>
            <div className='d-flex justify-content-center m-2'>
                <h3 className='blue-title'>{title}</h3>
            </div>
            <div className='catalog-master-actions d-flex justify-content-between mx-4 my-2'>
                <div className='catalog-master-buttons-left'>
                    <button type='button' className='btn btn-sm btn-outline-dark mr-4' onClick={handleDelete} disabled={!selectedValues.length}>
                        <FontAwesomeIcon icon='trash' className='mr-3' />
                        Delete
                    </button>
                    <button type='button' className='btn btn-sm btn-outline-dark mr-4' onClick={handleClear} disabled={!selectedValues.length}>
                        <FontAwesomeIcon icon='times' className='mr-3' />
                        Clear selection
                    </button>
                    <button type='button' className='btn btn-sm btn-outline-dark mr-4' onClick={handleClearFilters} >
                        Clear filters
                    </button>
                </div>
                <div className='catalog-master-buttons-right'>
                    <button type='button' className='btn btn-sm btn-outline-dark mr-4' onClick={handleImport}>
                        <FontAwesomeIcon icon='file-import' className='mr-3' />
                        Import
                    </button>
                    <button type='button' className='btn btn-sm btn-outline-dark' onClick={() => setShowExportModal(true)}>
                        <FontAwesomeIcon icon='file-export' className='mr-3' />
                        {selectedValues.length > 0 ? 'Export selection' : 'Export table'}
                    </button>
                    <OverlayTrigger
                        placement="left"
                        delay={{ show: 150, hide: 150 }}
                        overlay={
                        <Tooltip id="processes-tooltip">
                            Go to previous processes
                        </Tooltip>}
                    >
                        <button type='button' className='btn btn-sm btn-outline-dark ml-4' onClick={goToProcesses}>
                            <FontAwesomeIcon icon='list' className='' />
                        </button>
                    </OverlayTrigger>
                </div>
            </div>
            <div className='catalog-master-table mx-4 mt-2'>
                <table className='table table-sm table-hover table-bordered'>
                    <thead className='userProcessGray userProcessTblTitle text-center'>
                        <tr>
                            <th></th>
                            {/* <th className='row-options-header'>Actions</th> */}
                            {columns.map((item, index) => {
                                return (
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 250 }}
                                        overlay={
                                            <div>
                                                <ColumnFilter
                                                    title={item.Column_Desc}
                                                    name={item.Column_Name}
                                                    Id={item.Column_Id}
                                                    values={getFilterValues(item.Column_Id)}
                                                    defaultSelectedValues={getFilterSelectedValues(item.Column_Name)}
                                                    attributeType={item.Type}
                                                    filterType={item.Filter} 
                                                    onValueSelection={handleAddFilter}
                                                    onRemoveSelection={handleRemoveFilter}
                                                />
                                            </div>
                                        }
                                        trigger='click'
                                        onToggle={(e) => handleFilterClick(e, item.Column_Id)}
                                        rootClose
                                        key={index}
                                    >
                                        {({ ref, ...triggerHandler }) => (
                                            <th key={index} ref={ref}>
                                                <div className='d-flex justify-content-between align-item-center'>
                                                    <span className='pr-4 mr-2'>&nbsp;</span>
                                                    <span>
                                                        {item.Column_Desc}
                                                        {columnFiltersSelectedValues.some(x => x.filterName === item.Column_Name && x.values.length) ? <FontAwesomeIcon icon='filter' className='ml-2 text-muted' /> : null}
                                                    </span>
                                                    <button type='button' className='btn btn-xs btn-blue py-0 ml-2' {...triggerHandler}>
                                                        <FontAwesomeIcon icon='chevron-down' className='' />
                                                    </button>
                                                </div>
                                            </th>
                                        )}

                                    </OverlayTrigger>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {rows.map((row, i) => {
                            return (
                                <tr key={i}>
                                    <td className='fit'>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id={`rowCheckBox ${i}`} checked={selectedValues.includes(Object.values(row)[0])} onChange={(e) => handleCheckboxChange(Object.values(row)[0], e.target.checked)} />
                                            <label className="custom-control-label" htmlFor={`rowCheckBox ${i}`} ></label>
                                        </div>
                                    </td>
                                    {/* <td className='fit row-options'>
                                        <button type='button' className='btn btn-xs btn-blue ml-2' onClick={viewItem}>
                                            <FontAwesomeIcon icon='eye' className='' />
                                        </button>
                                        <button type='button' className='btn btn-xs btn-blue mx-2'>
                                            <FontAwesomeIcon icon='edit' className='' onClick={handleAdd} />
                                        </button>
                                    </td> */}
                                    {columns.map((col, index) => {
                                        let value = row[col.Column_Name];
                                        if(col.Type === 'number'){
                                            value = value.toLocaleString(
                                                undefined,
                                                { minimumFractionDigits: 2 }
                                              );
                                        }
                                        else if(col.Type === 'boolean'){
                                            value = value ? 'Yes' : 'No';
                                        }
                                        value = String(value);
                                        return <td key={index}>{value}</td>
                                    })}
                                    {/* {Object.keys(row).map((att, j) =>{
                                        return <td key={j}>{String(row[att])}</td>
                                    })} */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className='box-grow-no-scroll h-100 d-flex flex-column justify-content-center'>
                    <LoaderSpinner color="#0077CC" area="rows-area"/>
                    {totalRows === 0 && (
                        <h5 className='text-muted text-center'>There are no records to show. Try changing the values for the filters or add new records using the import option above.</h5>
                    )}
                </div>
            </div>
            <div className='d-flex justify-content-between'>
                <div className='catalog-master-pagination mx-4 my-2'>
                    <CatalogMasterPagination 
                        pagesNumber={totalPages}
                        activePage={activePage}
                        movePage={changePage}
                    />
                </div>
                <div className='catalog-master-count mx-4 my-2'>
                    <span>Showing records {((activePage-1) * pageSize + 1).toLocaleString()} to {((activePage-1) * pageSize + pageSize).toLocaleString()} out of {totalRows.toLocaleString()}</span>
                </div>
            </div>
            <CatalogEditorModal 
                show={showEditorModal}
                onHide={() => setShowEditorModal(false)}
                onCancelBtnClick={() => setShowEditorModal(false)}
                columns={columns}
                title='New Product'
            />
            <ConfirmationModal 
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onCancelBtnClick={() => setShowDeleteModal(false)}
                onAcceptBtnClick={() => handleConfirmDelete()}
                title='Delete confirmation'
                text='Are you sure that you want to delete the selected elements? This operation can not be reversed'
            />
            <CustomInputModal
                show={showExportModal}
                onHide={() => setShowExportModal(false)}
                title="File Name"
                primaryText="Enter the name that will be used for the generated file."
                secondaryText="Note: Current date and time will be added automatically to the end of the provided name."
                cancelBtnText="Cancel"
                acceptBtnText="Start"
                onCancelBtnClick={() => setShowExportModal(false)}
                onAcceptBtnClick={handleExport}
            />
            {/* <InformationModal
                show={showStartedModal}
                onHide={() => setShowStartedModal(false)}
                onAcceptBtnClick={() => setShowStartedModal(false)}
                title="Process started"
                text="The process has been started and will be completed in the background. You can check the status of the process and download the file in the 'Processes' page."
            /> */}
            <ConfirmationModal 
                show={showStartedModal}
                onHide={() => setShowStartedModal(false)}
                onAcceptBtnClick={handleGoToProcesses}
                onCancelBtnClick={() => setShowStartedModal(false)}
                acceptBtnText='Go to processes page'
                cancelBtnText='Cancel'
                title="Process started"
                text="The process has been started and will be completed in the background. You can check the status of the process and download the file in the 'Processes' page."
            />
        </div>
    )
}

export default CatalogMaster;