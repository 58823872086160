// packages
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { AbortController } from "@azure/abort-controller";

// styles
import '../../../TransformationProcess.css';

// services
import filesService from "../../../../../../Services/HttpService/FilesService";
import { uploadFile } from "../../../../../../Services/Other/BlobStorageService";
import { newFile } from "../../../../../../Services/Helpers/filesHelper";

// graphic components
import FileUploadModal from "../../../../../Reusable/CustomModal/FileUploadModal/FileUploadModal";
import BlueButton from "../../../../../Reusable/BlueButton/BlueButton";

function TransformFileUpload(props) {
    const [ showModal, setShowModal ] = useState(false);
    const [ loadPercentage, setLoadPercentage ] = useState(0);
    const fileInputRef = useRef(null);
    const systemId = useSelector(state => state.systems.selectedSystem);
    const systemsList = useSelector(state => state.systems.systemsList);
    const menu = useSelector(state => state.menu.selectedMenu);
    const history = useHistory();

    const clickFileInput = () => {
        fileInputRef.current.click();
    };

    const handleProgress = progress => setLoadPercentage(progress);

    const handleFileUpload = async (event) => {
        setShowModal(true);
        const localSystemsList = [ ...systemsList ]
        const localSystem = localSystemsList.filter(sys => sys["System_Id"] === systemId)
        const container = localSystem[0]["Container"];

        const localName = event.target.files[0].name;
        const localType = event.target.files[0].type;
        const fileInfo = newFile(localName, container, localType, null, systemId, menu.Menu_Id);

        let fileId = await filesService.createNewFileRef(fileInfo);
        const ext = localName.split(".")[1];

        await (async () => {
            // abort upload signal definition
            const controller = new AbortController();
            const abortSignal = controller.signal;

            let response = await uploadFile(event, container, `${fileId}.${ext}`, abortSignal, handleProgress);
            setShowModal(false);
            if (response === -1) {
                filesService.deleteFileRef(fileId);
                // show error
            } else {
                props.onSelectFile(fileId);
                history.push(`${props.path}/layout`);
            }
        })();
    };

    return(
        <div
            className="col-sm-6 p-4 d-flex flex-column align-items-center"
        >
            <h5 className="processInstructionsTitle mb-5">
                <strong>UPLOAD FILE</strong>
            </h5>
            <p className="fileChooseStageText w-75 text-center">
                Drag your files here or select the button "upload" to search your file.
            </p>
            <div className='mt-5'>
                <BlueButton onClick={clickFileInput} title={'Upload'} large={true}/>
            </div>
            <input type="file" name="file" hidden ref={fileInputRef} onChange={handleFileUpload} />
            <FileUploadModal
                show={showModal}
                onHide={() => setShowModal(false)}
                progress={loadPercentage}
            />
        </div>
    );
}

TransformFileUpload.propTypes = {
    nextClick: PropTypes.func,
    onSelectFile: PropTypes.func,
    path: PropTypes.string.isRequired
}

export default TransformFileUpload;