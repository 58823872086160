import { useState, useEffect } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";

//services
import * as TableauDataService from "../../../../Services/HttpService/TableauDataService"
import * as DashboardDataService from "../../../../Services/HttpService/DashboardDataService"

//graphic components
import PreviewGroup from "../../../../Components/Reusable/PreviewGroup/PreviewGroup";
import SearchBar from "../../../Reusable/SearchBar/SearchBar";
import { apiLoginRequest } from "../../../../authConfig";

function ViewNavContainer(props){
    const history = useHistory();
    const { id } = useParams();
    const [itemsDefault, setItemsDefault] = useState([]);
    const [items, setItems] = useState([]);
    const [tree, setTree] = useState([]);
    const [defaultTree, setDefaultTree] = useState([]);
    const [itemsType, setItemsType] = useState(props.itemsType);
    const [ helpText, setHelpText ] = useState();
    const location = useLocation();
    const [ currentSite, setCurrentSite ] = useState();
    const [ currentWorkbook, setCurrentWorkbook ] = useState();
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [input, setInput] = useState('');
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);
    const { instance, accounts, inProgress } = useMsal();


    useEffect(() => {
        getItems();
        getLookupTree();
    }, [])

    useEffect(() => {
        getItems();
        getLookupTree();
    }, [location, selectedSystem, inProgress, accounts]);

    const getLookupTree = () => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const userId = accounts[0].username.replace('.com', '');

                let tree = await DashboardDataService.getLookUpTree(selectedSystem, userId, 0, null, authResponse.accessToken);
                let flatTree = [];
                flatTree.push(tree.sites.map((item) => {
                    return {
                        id: item.Site_Id,
                        internalId: item.Internal_Id,
                        name: item.Site_Name,
                        description: "",
                        server: item.Server_Url,
                        siteId: item.Internal_Id,
                        type: 1
                    }
                }));
                flatTree.push(tree.workbooks.map((item) => {
                    //Pedir que los elementos ya traigan la información de los niveles superiores.
                    // let site = (await DashboardDataService.getSite(item.Site_Id,authResult.accessToken))[0];
                    // return {
                    //     id: item.Workbook_Id,
                    //     internalId: item.Internal_Id,
                    //     name: item.Workbook_Dsc,
                    //     description: "",
                    //     workbookId: item.Internal_Id,
                    //     server: site.Server_Url,
                    //     siteId: site.Internal_Id,
                    //     type: 2
                    // }
                }));
                flatTree.push(tree.views.map((item) => {
                    //Pedir que los elementos ya traigan la información de los niveles superiores.
                    // return {
                    //     id: item.View_Id,
                    //     internalId: item.Internal_Id,
                    //     name: item.View_Dsc,
                    //     description: "",
                    //     workbookId: workbook.Internal_Id,
                    //     server: site.Server_Url,
                    //     siteId: site.Internal_Id,
                    //     type: 3
                    // }
                }));
                setTree(flatTree);
                setDefaultTree(flatTree);
            }
        })();
    }

    const getItems = () => {
        (async () => {
            let data = [];
            if (inProgress === 'none' && accounts.length > 0) {
                let authResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const userId = accounts[0].username.replace('.com', '');
                if(props.itemsType === 1){
                    data = await DashboardDataService.getSites(selectedSystem, userId, authResult.accessToken);
                    data = data.map( (item) => {
                        return {
                            id: item.Site_Id,
                            internalId: item.Internal_Id,
                            name: item.Site_Name,
                            description: item.Site_Name,
                            server: item.Server_Url,
                            siteId: item.Internal_Id,
                            type: 1
                        }
                    });
                    setItems(data);
                    setItemsDefault(data);
                    setHelpText("Select a folder to view its workbooks.");
                }
                else if(props.itemsType === 2){
                    data = await DashboardDataService.getWorkbooks(id,authResult.accessToken);
                    let site = (await DashboardDataService.getSite(id,authResult.accessToken))[0];
                    await TableauDataService.signin(site.Server_Url,site.Site_User, site.Site_Pass,site.Site_Url, authResult.accessToken);
                    data = data.map( (item) => {
                        return {
                            id: item.Workbook_Id,
                            internalId: item.Internal_Id,
                            name: item.Workbook_Path,
                            description: item.Workbook_Dsc,
                            workbookId: item.Internal_Id,
                            server: site.Server_Url,
                            siteId: site.Internal_Id,
                            type: 2
                        }
                    });
                    setItems(data);
                    setItemsDefault(data);
                    setHelpText("Select a workbook to view its reports/dashboards.");
                }
                else if(props.itemsType === 3){
                    data = await DashboardDataService.getViews(id,authResult.accessToken);
                    let workbook = (await DashboardDataService.getWorkbook(id,authResult.accessToken))[0];
                    setCurrentWorkbook(workbook.Workbook_Path);
                    let site = (await DashboardDataService.getSite(workbook.Site_Id,authResult.accessToken))[0];
                    data = data.map(item => {
                        return {
                            id: item.View_Id,
                            internalId: item.Internal_Id,
                            name: item.View_Path.split('/').pop(),
                            description: item.View_Dsc,
                            workbookId: workbook.Internal_Id,
                            server: site.Server_Url,
                            siteId: site.Internal_Id,
                            type: 3
                        }
                    });
                    console.log(data);
                    setItems(data);
                    setItemsDefault(data);
                    setHelpText("Select a report/dashboard to view its content.");
                }
            }
        })();
    }

    const getChildren = (itemType, itemId, itemName, itemDsc) => {
        let url = '';
        if(itemType === 1){
            url = `${props.path}/folders/${itemId}`;
            setCurrentSite(itemName);
        }
        if(itemType === 2){
            url = `${props.path}/workbooks/${itemId}`;
            setCurrentWorkbook(itemName);
        }
        else if(itemType === 3){
            url = `${props.path}/views/${itemId}`;
            props.setViews(currentSite, currentWorkbook, items, itemName, itemId)
        }
        props.addNavItem(itemDsc, url);

        history.push(url);
    }

    const updateInput = async (input) => {
        const filtered = itemsDefault.filter(item => {
         return item.name.toLowerCase().includes(input.toLowerCase())
        })
        setInput(input);
        setItems(filtered);
     }

    return(
        <div className="h-100 d-flex flex-grow-1 flex-column">
            <div className="d-flex justify-content-end pr-3">
                <div className="w-25 mr-4 my-2">
                <SearchBar
                    keyword={input} 
                    setKeyword={updateInput}
                />
                </div>
            </div>
            <h5 className="text-muted text-center mx-5">{helpText}</h5>
            <div className="container-fluid d-flex justify-content-center flex-grow-1">
                <PreviewGroup
                    items={items}
                    getChildren={getChildren}
                    selectedItems={selectedItems}
                />
            </div>
        </div>
    )
}

export default ViewNavContainer