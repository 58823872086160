// packages
import PropTypes from 'prop-types';

// styles
import './FileUploadModal.css';

// graphic components
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';

function FileUploadModal({ show, onHide, progress }) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop='static'
            keyboard='false'
            centered={true}
        >
            <Modal.Header>
                Uploading File
            </Modal.Header>
            <Modal.Body>
                <ProgressBar
                    now={progress}
                    className='progress-bar-blueGensa'
                />
            </Modal.Body>
        </Modal>
    );
}

FileUploadModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    progress: PropTypes.number.isRequired
}

export default FileUploadModal;