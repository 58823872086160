// Packages
import { createSlice } from "@reduxjs/toolkit";

// Slice Definition
export const systemsSlice = createSlice({

    name: 'systems',

    initialState: {
        systemsList: [],
        selectedSystem: -1
    },

    reducers: {
        setSystemsList: (state, action) => {
            state.systemsList = action.payload;
        },

        setSelectedSystem: (state, action) => {
            state.selectedSystem = action.payload;
        }
    }

});

// Final exports
export const { setSystemsList, setSelectedSystem } = systemsSlice.actions;
export default systemsSlice.reducer;