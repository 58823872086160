import { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'

// Graphic Components
import folderIcon from '../../../Utils/svg_icons/folder.svg';

//services
import * as TableauDataService from "../../../Services/HttpService/TableauDataService"

//styles
import "./PreviewCard.css"

function PreviewCard(props){
    const [image, setImage] = useState(null)
    const [imgClass, setImgClass] = useState("");
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
         getImage()
    }, [])

    const getImage = () => {
        (async () => {
            let serverUrl = props.server;
            let siteId = props.site;
            let imageArrayBuffer = null;
            if(props.type===1){
                setImage(folderIcon);
                setImgClass("icon-img")
                return;
            }
            else if(props.type===2){
                imageArrayBuffer = await TableauDataService.getWorkbookPreview(serverUrl, siteId, props.internalId)
            }
            else if(props.type===3){
                imageArrayBuffer = await TableauDataService.getViewPreview(serverUrl, siteId, props.workbook, props.internalId)
            }
            const unitArray = new Uint8Array(imageArrayBuffer).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
            );
            const buf = Buffer.from(unitArray, 'latin1');
            const base64 = buf.toString('base64');

            setImage("data:;base64," + base64)
            setImgClass("centered-img")
        })();
    }

    const handleClick = () => {
        if(!clicked) props.getChildren(props.type, props.id, props.name, props.description)
        setClicked(true);
    }

    return(
        <Card onClick={handleClick} className="preview-card">
            {/* <div className="d-flex w-100 mb-2 cb-row">
                {
                    (props.type == 3) && 
                    <input checked={props.isSelected} type="checkbox" className="top-cb" onChange={(e) => props.selectItem(props.type, props.id, e.target.checked)} onClick={(e) => e.stopPropagation()}></input>
                }
            </div> */}
            <img className={`card-img card-img-top ${imgClass}`} src={image}></img>
            <Card.Body>
                <Card.Title>
                    {props.description}
                </Card.Title>
                <Card.Text>
                    {/* {props.description} */}
                </Card.Text>
            </Card.Body>
            <div className="transparent-overlay"></div>
        </Card>
    )
}

export default PreviewCard