import { blobStorageSAS, blobStorageUrl } from "../../apiConfig";
import { BlobServiceClient } from "@azure/storage-blob";
const sas = blobStorageSAS;

export const uploadFile = async (event, container, fileName, abortSignal = null, callback = null) => {
    try {
        const blobServiceClient = new BlobServiceClient(`${blobStorageUrl}/${sas}`);
        console.log(blobServiceClient);
        console.log(container);
        const containerClient = blobServiceClient.getContainerClient('java-test-system-3');

        const size = event.target.files[0].size;
        const type = event.target.files[0].type;

        const blockBlobClient = containerClient.getBlockBlobClient(fileName);
        await blockBlobClient.uploadData(event.target.files[0],
            {
                blobHTTPHeaders: { blobContentType: type },
                onProgress: progress => {
                    let percentage = Math.round((100 * progress.loadedBytes) / size);

                    if (callback)
                        callback(percentage);
                },
                abortSignal: abortSignal
            }
        );

        return 1;
    }
    catch (err) {
        // TODO: log error to server
        console.log(err);
        return -1;
    }
}

export const downloadFile = async (containerName, fileName) => {
    try{
        // Create the BlobServiceClient object which will be used to create a container client
        const blobServiceClient = new BlobServiceClient(`${blobStorageUrl}/${sas}`);
        // Get a reference to a container
        const containerClient = blobServiceClient.getContainerClient(containerName);
        // Get a block blob client
        const blockBlobClient = containerClient.getBlockBlobClient(fileName);
        return blockBlobClient.download(0);
    }
    catch(err) {
        console.log(err);
        return null;
    }
 }

 export const getFileUrl = async (containerName, fileName) => {
    try{
        // Create the BlobServiceClient object which will be used to create a container client
        const blobServiceClient = new BlobServiceClient(`${blobStorageUrl}/${sas}`);
        // Get a reference to a container
        const containerClient = blobServiceClient.getContainerClient(containerName);
        // Get a block blob client
        const blockBlobClient = containerClient.getBlockBlobClient(fileName);
        return blockBlobClient.url;
    }
    catch(err) {
        console.log(err);
        return null;
    }
 }

 export const getContainerUsage  = async (containerName) => {
    try{
        // Create the BlobServiceClient object which will be used to create a container client
        const blobServiceClient = new BlobServiceClient(`${blobStorageUrl}/${sas}`);
        // Get a reference to a container
        const containerClient = blobServiceClient.getContainerClient(containerName);
        let usage = 0;
        // for await (const blob of containerClient.listBlobsFlat()) {
        //     console.log(blob);
        //     usage = usage + (blob.properties.contentLength / 1024);
        // }
        
        for await (const item of containerClient.listBlobsByHierarchy("/")) {
            if (item.kind === "blob") {
              usage = usage + (item.properties.contentLength / 1024);
            }
        }
        console.log(usage);
    }
    catch(err) {
        console.log(err);
        return null;
    }
 }

 export const getContainerStatus = async (containerName) => {
     try{
         // Create the BlobServiceClient object which will be used to create a container client
         const blobServiceClient = new BlobServiceClient(`${blobStorageUrl}/${sas}`);
        // Get a reference to a container
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const properties = await containerClient.getProperties();
        if(properties) return 1;
     }
     catch(err) {
         return -1;
     }
 }

export const getToken = async () => {
    return '?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacuptfx&se=2021-08-13T23:24:39Z&st=2021-08-10T15:24:39Z&spr=https,http&sig=nq08%2B%2FHUA4VFJrBauKWp6YFTUIb8BAAPqR19N9tqMuc%3D';
}