import { useState } from "react";
import { CardGroup } from "react-bootstrap";
import PreviewCard from "../PreviewCard/PreviewCard";

function PreviewGroup(props){

    return (
        <div className="d-flex flex-wrap justify-content-around flex-grow-1 h-100">
            {props.items.map((item, index) => {
                return(
                    <PreviewCard 
                        image={item.image}
                        name={item.name}
                        contentUrl={item.contentUrl}
                        description={item.description}
                        getChildren={props.getChildren}
                        id={item.id}
                        internalId={item.internalId}
                        type={item.type}
                        workbook={item.workbookId}
                        key={item.type === 1 ? item.id : item.internalId}
                        site={item.siteId}
                        server={item.server}
                    />
                )
            })}
            <div className="filling-empty-space-card"></div>
            <div className="filling-empty-space-card"></div>
            <div className="filling-empty-space-card"></div>
        </div>
    )
}

export default PreviewGroup