import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import BlueButton from "../../Reusable/BlueButton/BlueButton";
import MainFrame from "../../Reusable/MainFrame/MainFrame";
import { InteractionType } from "@azure/msal-browser";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

// styles
import './ConnectionTest.css';

// Services
import userService from "../../../Services/HttpService/UserService";
import * as BlobStorageService from '../../../Services/Other/BlobStorageService';
import * as TableauDataService from "../../../Services/HttpService/TableauDataService"
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainFrameAlt from "../../Reusable/MainFrameAlt/MainFrameAlt";
import { apiLoginRequest } from "../../../authConfig";

function CustomToggle({ children, eventKey, callback }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        callback && callback(eventKey),
    );

    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            className="mx-4 btn btn-sm"
        >
            {children}
        </button>
    );
}

function ConnectionTestContent(props) {
    const [servicesStatus, setServicesStatus] = useState({
        blob: 0,
        tableau: 0,
        backend: 0
    });
    const [ collapsed, setCollapsed ] = useState(true);
    const [ inputText, setInputText ] = useState({
        server: ''
    });
    const { instance, accounts, inProgress } = useMsal();
    const systemId = useSelector(state => state.systems.selectedSystem);
    const systemsList = useSelector(state => state.systems.systemsList);
    
    const collapsableChange = (eventKey) => {
        console.log(props.items)
        setCollapsed(!collapsed)
    }

    const getStatusClass = (status) => {
        if (status === -1) return "disconnected";
        else if (status === 0) return "loading";
        else if (status === 1) return "connected";
    }

    const getSymbolClass = (status) => {
        if (status === 1) return "success-checkmark";
        else if (status === -1) return "fail-mark";
    }

    const testConnection = () => {
        setServicesStatus({
            blob: 0,
            tableau: 0,
            backend: 0
        });
        (async () => {
            await testBackendAPIConnection();
            await testTableauServerConnection();
            await testBlobStorageConnection();
        })();
    }

    const testBackendAPIConnection = async () => {
        if (inProgress === 'none' && accounts.length > 0) {
            let authResponse = await instance.acquireTokenSilent({
                account: accounts[0],
                ...apiLoginRequest
            });
            const userId = accounts[0].username.replace('.com', '');
            let currentStatus = 0;
            let response = await userService.getAvailableSystems(userId, authResponse.accessToken);
            if (response) currentStatus = 1;
            else currentStatus = -1;
            setServicesStatus((prevState) => (
                {
                    ...prevState,
                    backend: currentStatus
                }
            ));
        }
    }

    const testTableauServerConnection = async () => {
        let currentStatus = 0;
        const serverUrl = inputText.server.replace(/^https?:\/\//,'');
        let serverStatus = await TableauDataService.getServerInfo(serverUrl);
        if (serverStatus === 1) currentStatus = 1;
        else currentStatus = -1;
        setServicesStatus((prevState) => (
            {
                ...prevState,
                tableau: currentStatus
            }
        ));
    }

    const testBlobStorageConnection = async () => {
        const localSystemsList = [...systemsList];
        const localSystem = localSystemsList.filter(sys => sys["System_Id"] === systemId);
        const container = localSystem[0]["Container"];
        let currentStatus = 0;
        let containerStatus = await BlobStorageService.getContainerStatus(container);
        if (containerStatus === 1) currentStatus = 1;
        else currentStatus = -1;
        setServicesStatus((prevState) => (
            {
                ...prevState,
                blob: currentStatus
            }
        ));
    }

    const onInputChange = e => {
        setInputText({
            ...inputText,
            [e.target.name]: e.target.value
        });
    }

    return (
        <div className="flex-grow-1 container-fluid d-flex flex-column w-75 align-items-center">
            <h5 className="text-muted text-center mx-5 my-4">Use this screen to verify that the app can connect to all of the services that it uses.</h5>
            <div className="services-list card d-flex flex-column align-items-center">
                <div className="service-item">
                    <p className="service-item-text">Backend API</p>
                    <div className={`service-item-status dot ${getStatusClass(servicesStatus.backend)}`}>
                        <div className={`${getSymbolClass(servicesStatus.backend)}`}></div>
                    </div>
                </div>
                <div className="w-100">
                    <Accordion>
                        <div className="service-item mb-2">
                            <div className="d-flex align-content-baseline">
                                <div className="service-item-text">Tableau Server</div>
                                <CustomToggle eventKey="0" callback={collapsableChange}>
                                    {collapsed ? <FontAwesomeIcon icon="chevron-down" /> : <FontAwesomeIcon icon="chevron-up" />}
                                </CustomToggle>
                            </div>
                            <div className={`service-item-status dot ${getStatusClass(servicesStatus.tableau)}`}>
                                <div className={`${getSymbolClass(servicesStatus.tableau)}`}></div>
                            </div>
                        </div>
                        <Accordion.Collapse eventKey="0">
                            <div className="service-item-panel mb-3">
                                <input
                                    type="text"
                                    className="input-text form-control"
                                    placeholder="Talbeau Server URL"
                                    value={inputText.server}
                                    name="server"
                                    onChange={onInputChange}
                                />
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                </div>
                <div className="service-item">
                    <p className="service-item-text">Blob Storage</p>
                    <div className={`service-item-status dot ${getStatusClass(servicesStatus.blob)}`}>
                        <div className={`${getSymbolClass(servicesStatus.blob)}`}></div>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-center mt-4">
                    <BlueButton onClick={testConnection} title='Run test' />
                </div>
            </div>
        </div>
    )
}

function ConnectionTest() {
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {
                selectedSystem === -1 ?
                    <Redirect to='/' />
                    :
                    <MainFrameAlt
                        title='CONNECTION TEST'
                        content={<ConnectionTestContent />}
                        topStyle="align-items-center"
                    />
            }
        </MsalAuthenticationTemplate>
    );
}

export default ConnectionTest;