import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Accordion, Modal, useAccordionToggle } from "react-bootstrap";

import './DataPullerTaskInfoModal.css';
import { ReactComponent as ArrowForward } from '../../../../Utils/svg_icons/arrow-forward.svg';
import ItemThingHorizontal from "../../ItemThingHorizontal/ItemThingHorizontal";

function CustomToggle({ children, eventKey, callback }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        callback && callback(eventKey),
    );

    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            className="mx-4 btn btn-sm"
        >
            {children}
        </button>
    );
}

function DataPullerTaskInfoModal({ processInfo, show, onHide, onRefresh }) {
    const [ collapsedParameters, setCollapsedParameters ] = useState(true);

    const collapsableChange = (eventKey) => {
        setCollapsedParameters(!collapsedParameters)
    }

    const handleRefresh = () => {
        onRefresh(processInfo.Process_Id)
    }

    const getItemClass = (status) => {
        if(status === 'Failed') return 'failed';
        else if(status === 'User Interaction') return 'userInteraction';
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop='static'
            keyboard='false'
            centered={true}
            className="task-info-modal"
            size="xl"
        >
            <Modal.Header closeButton>
                Data Puller Task Information
            </Modal.Header>
            <Modal.Body>
                <div className="task-info-container d-flex flex-column">
                    <div className="task-info-icons d-flex justify-content-center align-content-center my-1">
                        <div className="text-center mx-4">
                            <FontAwesomeIcon icon="database" size="4x" color="#004c84"/>
                            <p className="mt-4 mb-0">Database</p>
                        </div>
                        <div className="d-flex flex-column justify-content-center mx-4">
                            <div className="arrow mx-4">
                                <div className="arrow-body"></div>
                                <div className="arrow-head"></div>
                            </div>
                            {/* <ArrowForward /> */}
                            <p className="mt-4 mb-0 text-white"></p>
                        </div>
                        <div className="text-center mx-4">
                            <FontAwesomeIcon icon="file" size="4x" color="#004c84" />
                            <p className="mt-4 mb-0">CSV File</p>
                        </div>
                    </div>
                    <div className="task-info-actions d-flex">
                        <div className="card px-3 mr-4 my-2">
                            <ItemThingHorizontal
                                title="Refresh status"
                                type="refresh"
                                policy="itemThingGrayHover"
                                titleSpace="mt-1"
                                measures={{ width: "1.2em" }}
                                handleClick={handleRefresh}
                            />
                        </div>
                    </div>
                    <div className="task-info-progress mt-3">
                        <div className="stepper-wrapper">
                            {processInfo.logs && processInfo.logs.map((item, index) => {
                                return(
                                    <div key={index} class={`stepper-item ${index === processInfo.logs.lenght-1 ? 'active ' : 'completed '} ${getItemClass(item.Status)}`}>
                                        <div className="step-counter">{index+1}</div>
                                        <div className="step-name text-center">{item.Description}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="task-info-parameters">
                        <Accordion defaultActiveKey="0">
                            <div>
                                <span>Parameters and values used</span>
                                <CustomToggle eventKey="0" callback={collapsableChange}>
                                    {collapsedParameters ? <FontAwesomeIcon icon="chevron-down" /> : <FontAwesomeIcon icon="chevron-up" />}
                                </CustomToggle>
                            </div>
                            <Accordion.Collapse eventKey="0">
                                <div className="d-flex flex-wrap parameters-list">
                                    {processInfo.queries && processInfo.queries.map((item, index) => {
                                        let values = item.Values.split(',');
                                        return (
                                            <table key={index} className="table table-bordered table-sm parameter-table">
                                                <thead className='thead-light userProcessTblTitle'>
                                                    <tr>
                                                        <th>{item.Parameters}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {values.map((x, i) => {
                                                    return(
                                                        <tr key={i}>
                                                            <td>{x}</td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        )
                                    })}
                                </div>
                            </Accordion.Collapse>
                        </Accordion>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DataPullerTaskInfoModal;