// Packages
import PropTypes from 'prop-types';

// Styles
import './InformationModal.css';

// Graphic Components
import BlueButton from "../../BlueButton/BlueButton";
import Modal from "react-bootstrap/Modal";

function InformationModal({show, onHide, onAcceptBtnClick, title, text}){

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="informationModal"
        >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h3 className="msg-title">
                        {title}
                    </h3>
                    <p className="msg-body my-4 text-dark">
                        {text}
                    </p>
                    <BlueButton
                        onClick={onAcceptBtnClick}
                        title={'Ok'}
                        large={true}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

InformationModal.propTypes = {
    onAcceptBtnClick: PropTypes.func,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default InformationModal