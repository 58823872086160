// packages
import PropTypes from 'prop-types';

// styles
import './Step.css';

function Step({ title, active, margin }) {
    let styles = active ? 'py-4 stepContent stepContentActive' : 'py-4 stepContent';
    return (
        <div className={`${styles} ${margin}`}>
            <strong>{title}</strong>
        </div>
    );
}

Step.propTypes = {
    title: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    margin: PropTypes.string.isRequired
}

export default Step;