// Packages
import PropTypes from "prop-types";

// Styles
import './SwitchComponent.css';

// Graphic Components
import Switch from 'react-switch';

function SwitchComponent({ title, onColor, checked, onChange, switchDisabled }) {
    return (
        <div className='mb-3'>
            <label className='switchComponentTitle'>{title}</label>
            <div>
                <Switch
                    onColor={onColor}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={checked}
                    onChange={onChange}
                    disabled={switchDisabled}
                />
            </div>
        </div>
    );
}

SwitchComponent.propTypes = {
    title: PropTypes.string.isRequired,
    onColor: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    switchDisabled: PropTypes.bool,
}

export default SwitchComponent;