// packages
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// styles
// services
// graphic components
import MainFrame from "../../Reusable/MainFrame/MainFrame";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import CatalogMaster from "./CatalogMaster";
import ProductsCatalog from "./ProductsCatalog";
import PowerBIReport from "../../Reusable/PowerBIReport/PowerBIReport";
import MainFrameAlt from "../../Reusable/MainFrameAlt/MainFrameAlt";
import ProcessesHistory from "../../Reusable/ProcessesHistory/ProcessesHistory";

function CatalogsContent(){
    const { path } = useRouteMatch();

    return (
        <div className="flex-grow-1 d-flex flex-column">
            <Switch>
                <Route path={`${path}/products`}>
                    <ProductsCatalog prevPath={path} />
                </Route>
            </Switch>
        </div>
    );
}

function Catalogs() {
    const systemId = useSelector(state => state.systems.selectedSystem);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {
                systemId === -1 ?
                    <Redirect to='/' />
                    :
                    <MainFrameAlt
                        title="Catalogs"
                        topStyle="align-items-center"
                        content={ < CatalogsContent /> }
                    />
            }
        </MsalAuthenticationTemplate>
    );
}

export default Catalogs;