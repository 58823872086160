// external libraries

// components
import SelectComponent from "../../Reusable/SelectComponent/SelectComponent";
import TextInputComponent from "../../Reusable/TextInputComponent/TextInputComponent";
import SwitchComponent from "../../Reusable/SwitchComponent/SwitchComponent";
import LayoutColumn from "../../Reusable/LayoutColumn/LayoutColumn";

import PropTypes from "prop-types";
import MoveButton from "../../Reusable/MoveButton/MoveButton";

function CreateLayoutContentPageOne(props) {

    return(
        <div className = "container-fluid d-flex flex-column py-2 pl-5">
            <div className = "row mb-3 mt-4 d-flex justify-content-center">
                <div className = "col-lg-3 mb-3 mr-3">
                    <h3 className = "my-3 createLayoutTitle">Layout Details</h3>
                    <SelectComponent
                        title = "Default Layout"
                        options = { props.defaultOptionsControls.options }
                        value = { props.defaultOptionsControls.value }
                        name = { props.defaultOptionsControls.name }
                        handler = { props.defaultOptionsControls.handler }
                    />
                    <TextInputComponent
                        title = "Layout Name"
                        inputType= "text"
                        inputName = { props.layoutNameControls.name }
                        inputValue = { props.layoutNameControls.value }
                        onChange = { props.layoutNameControls.handler }
                    />
                    <TextInputComponent
                        title = "Short Name"
                        inputType= "text"
                        inputName = { props.layoutShortNameControls.name }
                        inputValue = { props.layoutShortNameControls.value }
                        onChange = { props.layoutShortNameControls.handler }
                    />
                    <TextInputComponent
                        title = "Description"
                        inputType= "text"
                        inputName = { props.layoutDscControls.name }
                        inputValue = { props.layoutDscControls.value }
                        onChange = { props.layoutDscControls.handler }
                    />
                    <TextInputComponent
                        title = "Rows To Remove"
                        inputType= "number"
                        inputName = { props.rowsToRemoveControls.name }
                        inputValue = { props.rowsToRemoveControls.value }
                        onChange = { props.rowsToRemoveControls.handler }
                    />
                    <div className="d-flex">
                        <div className="w-50">
                            <SwitchComponent
                                onColor="#004c84"
                                title="Header"
                                checked = { props.hasHeadersControls.checked }
                                onChange = { props.hasHeadersControls.handler }
                            />
                        </div>
                        <div className="w-50">
                            <SwitchComponent
                                onColor="#004c84"
                                title="Pivot"
                                checked = { props.pivotControls.checked }
                                onChange = { props.pivotControls.handler }
                            />
                        </div>
                    </div>
                    <SelectComponent
                        title = "File Type"
                        options = {props.fileTypesControls.options}
                        value = { props.fileTypesControls.value }
                        name = { props.fileTypesControls.name }
                        handler = { props.fileTypesControls.handler }
                    />
                </div>
                <div className="col-lg-8 mb-3">
                    <h3 className="my-3 createLayoutTitle">Columns</h3>
                    <div className="createLayoutColumnsWrapper">
                        { props.columnControls.columns.map((col, index) => (
                            < LayoutColumn
                                columns = { col.columns }
                                onColumnChange = { (e) => props.columnControls.matchHandler(e.target.value, index) }
                                inputValue = { col.value }
                                onInputValueChange = { (e) => props.columnControls.valueHandler(e.target.value, index) }
                                ignoreChecked = { col.isIgnored }
                                onChangeIgnore = { (e) => props.columnControls.ignoreHandler(e, index) }
                                pivotChecked = { col.isPivot }
                                onChangePivot = { (e) => props.columnControls.pivotHandler(e, index) }
                                key = {index}
                                ignoreDisabled={ col.match === -1 }
                            />
                        )) }
                    </div>
                </div>
            </div>
            <div className="d-flex w-100 mb-3 justify-content-between">
                <MoveButton onClick={null} />
                <MoveButton onClick={() => props.onHandlePageChange(2)} forward={true} />
            </div>
        </div>
    );

}

CreateLayoutContentPageOne.propTypes = {
    defaultOptionsControls: PropTypes.any,
    layoutNameControls: PropTypes.any,
    layoutShortNameControls: PropTypes.any,
    layoutDscControls: PropTypes.any,
    rowsToRemoveControls: PropTypes.any,
    hasHeadersControls: PropTypes.any,
    pivotControls: PropTypes.any,
    fileTypesControls: PropTypes.any,
    columnControls: PropTypes.any,
    onHandlePageChange: PropTypes.func
}

export default CreateLayoutContentPageOne;