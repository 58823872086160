import { useEffect, useState } from "react"

//styles
import styles from "./TableauView.css"

function TableauView(props){
    
    return(
        <iframe className="w-100" src={props.viewUrl}></iframe>
    )
}

export default TableauView