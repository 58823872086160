import './CreateLayout.css'

import PropTypes from "prop-types";
import ColumnFormat from "../../Reusable/ColumnFormat/ColumnFormat";

function CreateLayoutContentPageTwo(props) {

    return (
        <div className="container-fluid d-flex flex-column p-5">
            <div>
                <h4 className="createLayoutPageTwoTitle"> <strong>YOU ARE FORMATING THIS LAYOUT: </strong> </h4>
            </div>
            <div className="row d-flex justify-content-center mt-5">
                {
                    props.columns
                        .filter(col => !col.isIgnored)
                        .map(col => (
                        <div className="col-lg-3 mb-3">
                            <ColumnFormat name={col.value} fields={col.formattingOptions} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

CreateLayoutContentPageTwo.propTypes = {
    columns: PropTypes.array
}

export default CreateLayoutContentPageTwo;