import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";

import "./ChecklistCard.css";

function ChecklistCard({title, description, values, className='', onValueSelection, Id, name}) {
    const [ lookUpInput, setLookUpInput ] = useState('');
    const [ valuesList, setValuesList ] = useState(values);
    const [ defaultValuesList, setDefaultValuesList ] = useState(values);
    const [ selectedValues, setSelectedValues ] = useState([]);

    useEffect(() => {
        verifySelectedValues();
    }, [values]);

    let handleChangeLookUpInput = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setLookUpInput(lowerCase);
    };

    const filteredData = values.filter((el) => {
        //if no input the return the original
        if (lookUpInput === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.value.toLowerCase().includes(lookUpInput)
        }
    });

    const verifySelectedValues = () => {
        const found = selectedValues.some(r=> values.map(v => v.id).includes(r))
        if (found) {
            let newSelectedValues = selectedValues.splice().filter(x => values.map(v => v.id).includes(x));
            setSelectedValues(newSelectedValues);
            onValueSelection(Id, name, newSelectedValues);
        }
    }

    const handleCheckboxChange = (itemId, checked) => {
        if(checked){
            let newSelectedValues = [];
            if(itemId === '-1'){ 
                newSelectedValues = values.map(x => x.id); 
            }
            else{
                newSelectedValues = [...selectedValues, itemId];
            }
            onValueSelection(Id, name, newSelectedValues);
            setSelectedValues(newSelectedValues);
        }else{
            let newSelectedValues = []
            if(itemId !== '-1'){
                newSelectedValues = selectedValues.slice().filter(x => x !== itemId && x !== '-1');
            }
            setSelectedValues(newSelectedValues);
            onValueSelection(Id, name, newSelectedValues);
        }
    }

    return (
        <Card className={`checklist-card mx-4 ${className}`}>
            <Card.Body>
                <Card.Title className="checklist-title">{title}</Card.Title>
                {/* <Card.Text>
                    {description}
                </Card.Text> */}
                <div className="d-flex searchBar">
                    <input
                        type="text"
                        className="searchBarInput"
                        value={lookUpInput}
                        onChange={handleChangeLookUpInput}
                    />
                    <div className="d-flex justify-content-center align-items-center searchBarIcon">
                        <FontAwesomeIcon icon="search" />
                    </div>
                </div>
                <div className="options-list-container mt-2 box-grow">
                    <div className="options-list">
                        {filteredData.map((item, index) => {
                            return (
                                <div className="form-check" key={index}>
                                    <input className="form-check-input" type="checkbox" checked={selectedValues.includes(item.id)} id={item.id} onChange={(e) => handleCheckboxChange(item.id, e.target.checked)} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {item.value}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ChecklistCard;