// Packages
import PropTypes from "prop-types";

// Styles
import './ItemThingHorizontal.css'

// Graphic Components
import view from '../../../Utils/svg_icons/preview.svg'
import edit from '../../../Utils/svg_icons/edit.svg'
import test from '../../../Utils/svg_icons/test.svg'
import deleteI from '../../../Utils/svg_icons/delete.svg'
import folder from '../../../Utils/svg_icons/folder.svg'
import my_files from '../../../Utils/svg_icons/my-files.svg';
import upload from '../../../Utils/svg_icons/upload.svg';
import refresh from '../../../Utils/svg_icons/refresh.svg';
import file from '../../../Utils/svg_icons/file.svg';
import preparation from '../../../Utils/svg_icons/data-preparation.svg';
import layouts from '../../../Utils/svg_icons/layout.svg';
import settings from '../../../Utils/svg_icons/settings.svg';
import overview from '../../../Utils/svg_icons/quick-overview.svg';
import forecast from '../../../Utils/svg_icons/forecast.svg';
import puller from '../../../Utils/svg_icons/data-puller.svg';
import pricing from '../../../Utils/svg_icons/pricing.svg';
import dashboard from '../../../Utils/svg_icons/dashboards.svg';
import camera from '../../../Utils/svg_icons/camera.svg';
import chatbot from '../../../Utils/svg_icons/chatbot.svg';
import connection_test from '../../../Utils/svg_icons/connectiontest.svg';
import cube_configuration from '../../../Utils/svg_icons/cubeconfiguration.svg';
import db_settings from '../../../Utils/svg_icons/DBsettings.svg';
import model_maintenance from '../../../Utils/svg_icons/model-maintenance.svg';
import transformation from '../../../Utils/svg_icons/transform.svg';

function chooseSpecifications(spec) {
    switch(spec) {
        case 'view':
            return { src: view };
        case 'edit':
            return { src: edit };
        case 'test':
            return { src: test };
        case 'delete':
            return { src: deleteI };
        case 'folder':
        case 'layout':
            return { src: folder };
        case 'upload':
            return { src: upload };
        case 'file':
            return { src: file };
        case 'refresh':
            return { src: refresh };
        case 'My Files':
            return { src: my_files };
        case 'Data Preparation':
            return { src: preparation };
        case 'Layouts':
            return { src: layouts };
        case 'File Transformation':
            return { src: settings };
        case 'Quick Overview':
            return { src: overview };
        case 'Forecast':
            return { src: forecast };
        case 'Data Puller':
            return { src: puller };
        case 'Dashboards':
            return { src: dashboard };
        case 'Pricing':
            return { src: pricing };
        case 'Chatbot':
            return { src: chatbot };
        case 'Photo' :
            return { src: camera };
        case 'Connection Test':
            return { src: connection_test };
        case 'Cube Configuration':
            return { src: cube_configuration };
        case 'DB Settings':
            return { src: db_settings };
        case 'Dashboards Maintenance':
            return { src: model_maintenance };
        default:
            return { src: settings };
    }
}

function ItemThingHorizontal(props) {
    let specs = chooseSpecifications(props.type);

    return (
        <div 
            className={`itemThingHorizontal d-flex justify-content-around align-items-center ${props.itemStyleClasses}`}
            onClick={props.disabled ? null : props.handleClick}
        >
            <div>
                <label className={'itemThingLabel pr-4 ' + props.titleSpace}>
                    {/* {
                        props.title.length > 12 ? props.title.slice(0, 12) + "..." : props.title
                    } */}
                    {props.title}
                </label>
            </div>
            <img
                src={specs.src}
                className={`${props.disabled ? 'itemThingGray' : `${props.policy}` }`}
                style={props.measures}
                tabIndex={0}
                onDoubleClick={props.handleDoubleClick}
                alt={`${props.type} icon`}
                onContextMenu={props.handleContextMenu}
                ref={props.ref}
            />
        </div>
    );
}

ItemThingHorizontal.propTypes = {
    type: PropTypes.string.isRequired,
    policy: PropTypes.string,
    measures: PropTypes.any,
    handleClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    titleSpace: PropTypes.string,
    handleDoubleClick: PropTypes.func,
    disabled: PropTypes.bool,
    handleContextMenu: PropTypes.func,
    ref: PropTypes.any
}

export default ItemThingHorizontal;