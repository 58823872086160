// packages
import PropTypes from 'prop-types';

// styles
import './StepsMap.css';

// graphic components
import Step from '../Step/Step';

function StepsMap({ steps, active}) {
    return (
        <div className='container-fluid d-flex p-0'>
            {
                steps ?
                    steps.map((step, index) => {
                        let margin = '';
                        if (index < steps.length - 1) {
                            margin = 'mr-1';
                        }

                        return (
                            <Step
                                key={step + index}
                                active={active === index}
                                title={step}
                                margin={margin}
                            />
                        );
                    })
                    : null
            }
        </div>
    );
}

StepsMap.propTypes = {
    steps: PropTypes.array.isRequired,
    active: PropTypes.number.isRequired
}

export default StepsMap;