// packages
import { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// styles
// services
import * as DatapullerService from "../../../Services/HttpService/DataPullerDataService";
// graphic components
import MainFrame from "../../Reusable/MainFrame/MainFrame";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import ExecutionTabs from "./Stages/ExecutionTabs/ExecutionTabs";
import MainFrameAlt from "../../Reusable/MainFrameAlt/MainFrameAlt";
import ProcessesHistory from "../../Reusable/ProcessesHistory/ProcessesHistory";
import { SelectInputModal } from "../../Reusable/CustomModal/SelectInputModal/SelectInputModal";
import { apiLoginRequest } from "../../../authConfig";

function DataPullerContent() {
    const { path } = useRouteMatch();
    const menu = useSelector(state => state.menu.selectedMenu);
    const systemId = useSelector(state => state.systems.selectedSystem);
    const [ pipelines, setPipelines ] = useState([]);
    const [ selectedPipeline, setSelectedPipeline ] = useState(null);
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        getPipelines();
    }, [])

    const getPipelines = () => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let pipelineList = await DatapullerService.getPipelinesBySystem(systemId, menu.Menu_Id, authResponse.accessToken);
                pipelineList = pipelineList.map(item => {
                    return { id: item.Pipeline_Id, value: item.Pipeline_Dsc }
                })
                setPipelines(pipelineList);
            }
        })();
    }

    const handleInputSelection = (input) => {
        setSelectedPipeline(input);
    }

    return (
        <div className="flex-grow-1 d-flex flex-column">
            <Switch>
                <Route exact path={`${path}`}>
                    <ProcessesHistory path={path} newProcessEnabled={true} MenuId={menu.Menu_Id} hasProcessOptions={true} processOptions={pipelines} onOptionSelection={handleInputSelection} />
                </Route>
                <Route exact path={`${path}/execute`}>
                    {/* <StepsMap steps={['Product', 'Store/Time', 'Columns']} active={0} /> */}
                    <ExecutionTabs path={path} PipelineId={selectedPipeline} />
                </Route>
                {/* <Route path={`${path}/2`}>
                    <StepsMap steps={['Product', 'Store/Time', 'Columns']} active={1} />
                    <StoreFilters path={path} />
                </Route> */}
            </Switch>
        </div>
    );
}

function DataPuller() {
    const systemId = useSelector(state => state.systems.selectedSystem);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {
                systemId === -1 ?
                    <Redirect to='/' />
                    :
                    <MainFrameAlt
                        title="Data Puller"
                        topStyle="align-items-center"
                        content={ < DataPullerContent /> }
                    />
            }
        </MsalAuthenticationTemplate>
    );
}

export default DataPuller;