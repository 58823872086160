import axios from "axios"
import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/Package';

export async function getPackages(SystemId, MenuId, token) {
    try{
        let url = `${mainUrl}/PackageLayout/`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: {
                "SystemId": SystemId,
                "MenuId": MenuId
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getPackageParameters(PackageId, token) {
    try{
        let url = `${mainUrl}/PackageParameters/`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: {
                "PLayoutId": PackageId
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getPackageDescription(SystemId, MenuId, token) {
    try{
        let url = `${mainUrl}/PackageDesc/`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: {
                "SystemId": SystemId,
                "MenuId": MenuId
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return null;
    }
}

export async function executePackage(LayoutId, UserId, FileName, SystemId, MenuId, ContainerName, ParametersValues, token){
    try{
        //https://apig3.azurewebsites.net/api/Package/PackageExec/?LayoutId=2&UserId=MarioAlar&FileName=FileTest&SystemId=20&MenuId=30&BlobContainer=java-test-system&BlobDirectory
        let url = `${apiMainUrl}/api/Package/PackageExec/?LayoutId=${LayoutId}&UserId=${UserId}&FileName=${FileName}&SystemId=${SystemId}&MenuId=${MenuId}&BlobContainer=${ContainerName}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = ParametersValues;
        const response = await axios.post(url, data, config);
        return response.data;
    }
    catch(e){
        console.log(e);
        return null;
    }
}