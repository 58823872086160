// packages
import { useState } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// graphic components
import ViewsListContainer from "./Children/ViewsList/ViewsListContainer";
import AddViewsContainer from "./Children/AddViews/AddViewsContainer";
import MainFrame from "../../Reusable/MainFrame/MainFrame";
import MainFrameAlt from "../../Reusable/MainFrameAlt/MainFrameAlt";
import EditViewsContainer from "./Children/EditViews/EditViewsContainer";
import EditPermissionsContainer from "./Children/EditPermissions/EditPermissionsContainer";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

function DashboardAdminContent(){
    const { path } = useRouteMatch();

    return (
        <div className="flex-grow-1 d-flex flex-column h-100">
            <Switch>
                <Route exact path={path}>
                <MainFrameAlt
                    title="DASHBOARD CONFIGURATIONS"
                    topStyle="align-items-center"
                    content={ <ViewsListContainer /> }
                />
                </Route>
                <Route path={`${path}/add`}>
                    <AddViewsContainer 
                        path={path}
                    />
                </Route>
                <Route path={`${path}/edit/:id`}>
                    <EditViewsContainer
                        path={path}
                    />
                </Route>
                <Route path={`${path}/permissions/:id`}>
                    <MainFrameAlt
                        title="DASHBOARD CONFIGURATIONS"
                        topStyle="align-items-center"
                        content={ <EditPermissionsContainer path={path} /> }
                    />
                </Route>
            </Switch>
        </div>
    )
}

function DashboardAdmin(){
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);
    return (
        // <MainFrame
        //     title="Dashboard Configurations"
        //     topStyle="align-items-center"
        //     content={ < DashboardAdminContent /> }
        // />
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {
                selectedSystem === -1 ?
                    <Redirect to='/' />
                    :
                    <DashboardAdminContent/>
            }
        </MsalAuthenticationTemplate>
    )
}

export default DashboardAdmin