// Packages
import { createSlice } from "@reduxjs/toolkit";

// Slice Definition
export const menuSlice = createSlice({

    name: 'menu',

    initialState: {
        selectedMenu: null
    },

    reducers: {
        setSelectedMenu: (state, action) => {
            state.selectedMenu = action.payload;
        }
    }

});

// Final exports
export const { setSelectedMenu } = menuSlice.actions;
export default menuSlice.reducer;