import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { setSystemsList, setSelectedSystem} from "../../../Redux/Reducers/systemsSlice";
// Graphic Components
import './LandingPage.css';
import bg_logo from "../../../Utils/svg_icons/logogensa.svg";
import logo from "../../../Utils/svg_icons/Gensa-logo.svg";
import SelectComponent from '../../Reusable/SelectComponent/SelectComponent';
import MsalSignInButton from '../../Reusable/MsalSignInButton/MsalSiginInButton';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import LoaderSpinner from "../../Reusable/LoaderSpinner/LoaderSpinner";
import { trackPromise } from "react-promise-tracker";
// Styles
import './SystemSelect.css';

// Services
import userService from "../../../Services/HttpService/UserService";
import { apiLoginRequest } from '../../../authConfig';

function Landing() {
    const [ emailSignIn, setEmailSignIn ] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [ localSystemsList, setLocalSystemsList ] = useState([]);
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        getSystems();
    }, [location, instance, accounts, inProgress ]);

    function getSystems(){
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const userId = accounts[0].username.replace('.com', '');
                console.log(authResult);
                
                let systems = await trackPromise( userService.getAvailableSystems(userId, authResult.accessToken), 'systems-area' )
                dispatch(setSystemsList(systems));
                const mappedResponse = systems.map(system => {
                    return {
                        value: system.System_Id,
                        dsc: system.System_Dsc
                    }
                });
                mappedResponse.unshift({ value: -1, dsc: 'System' });
                setLocalSystemsList(mappedResponse);
            }
        })();
    }

    const handleChange = (evt) => {
        dispatch(setSelectedSystem(parseInt(evt.target.value)));
        history.push("/home")
    }

    return (
        <div className='landing-container'>
            <div className='left-container cool-background'>
                <div className='background'>
                    <img src={bg_logo} alt='GENSA' className='bg-logo' />
                    <div className='hero-text branding'>
                        <p className='upercase text-light m-0'>POWERED BY GENSA GROUP</p>
                    </div>
                </div>
            </div>
            <div className='right-container'>
                <div className='logo-container'>
                    <img className='logo' src={logo} alt='Cool background' />
                </div>
                <AuthenticatedTemplate>
                    <div className="d-flex flex-column align-items-center login-container">
                        <div className="systemSelectLabel mt-5">
                            <label><strong>SELECT YOUR CATEGORY</strong></label>
                        </div>
                        {/* <button type='button' className='btn btn-sm btn-outline-dark mt-3' onClick={() => getSystems()}>Get available systems</button> */}
                        <div className="col systemSelectLabel">
                            <SelectComponent
                                optionColor="selectComponentColorBlue"
                                options={localSystemsList}
                                handler={handleChange} />
                            <LoaderSpinner color="#0077CC" area="systems-area" />
                        </div>
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    {emailSignIn ?
                        <form className='login-container'>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div class="form-group">
                                <label htmlFor="exampleInputPassword1">Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" />
                            </div>
                            <button type="submit" className="btn btn-sm btn-outline-info login-btn mt-3">Log In</button>
                            <button type='button' className='btn btn-sm btn-outline-dark login-btn mt-3' onClick={() => setEmailSignIn(false)}>Back</button>
                        </form>
                        :
                        <div className='actions-container'>
                            <MsalSignInButton styleClasses='btn btn-sm btn-outline-info login-btn mt-3' text='Sign in with your Microsoft account' />
                            {/* <button type='button' className='btn btn-sm btn-outline-dark login-btn mt-3' onClick={() => setEmailSignIn(true)}>Sign in with email</button> */}
                        </div>
                    }
                </UnauthenticatedTemplate>
            </div>
        </div>
    );
}

export default Landing;