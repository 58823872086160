import axios from "axios"
import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/Catalogue';

export async function getCatalogColumns(SystemId, token){
    try{
        let url = `${mainUrl}/Columns/${SystemId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getCatalogColumnFilterValues(ColumnId, filters, token){
    try{
        let url = `${mainUrl}/Column/Value/${ColumnId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        let data = {
            filter: filters
        }
        const response = await axios.post(url, data, config);
        if(response.status === 204) return [];
        return response.data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getCatalogRows(SystemId, token){
    try{
        let url = `${mainUrl}/Columns/${SystemId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        // const response = await axios.get(url, config);
        // const data = response.data;
        return exampleRows;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getProductRows(SystemId, filters, pageNumber, token){
    try{
        let url = `${mainUrl}/Products/${SystemId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            filter: filters,
            PageNumber: pageNumber
        }
        const response = await axios.post(url, data, config);
        return response.data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function exportRowSelection(processInfo, token){
    try{
        let url = `${mainUrl}/ExportSelection`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.post(url, processInfo, config);
        return response.data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function deleteRowSelection(SystemId, processInfo, token){
    try{
        let url = `${mainUrl}/Register/${SystemId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: processInfo
        }
        const response = await axios.delete(url, config);
        return response.data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function exportTable(processInfo, token){
    try{
        let url = `${mainUrl}/ExportTable`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.post(url, processInfo, config);
        return response.data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

const exampleRows = [];