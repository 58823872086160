import axios from "axios"
import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/Process';

export async function getUserProcesses(UserId, token) {
    try{
        let url = `${mainUrl}/User/${UserId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getSystemProcesses(SystemId, UserId, MenuId, token, StartDate=null, EndDate=null, TaskName=null, TaskStatus=null) {
    try{
        let url = `${mainUrl}/System/${SystemId}/${UserId}?MenuId=${MenuId??''}&StartDate=${StartDate??''}&EndDate=${EndDate??''}&TaskName=${TaskName??''}&Status=${TaskStatus??''}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getProcessInfo(ProcessId, token) {
    try{
        let url = `${mainUrl}/${ProcessId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return {};
    }
}

export async function getLogInfo(LogId, token) {
    try{
        let url = `${mainUrl}/Log/${LogId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return {};
    }
}

export async function getProcessLogs(ProcessId, token) {
    try{
        let url = `${mainUrl}/Logs/${ProcessId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getProcessQueries(ProcessId, token) {
    try{
        let url = `${mainUrl}/Queries/${ProcessId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getProcessQueryInfo(QueryId, token) {
    try{
        let url = `${mainUrl}/Query/${QueryId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return {};
    }
}

export async function executeDataPullerProcess(System_Id, Menu_Id, UserId, FileName, ParametersValues, token) {
    try{
        let url = `${apiMainUrl}/api/PipelineRun/${System_Id}/${Menu_Id}?UserId=${UserId}&FileName=${FileName}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = ParametersValues;
        const response = await axios.post(url, data, config);
        console.log(response);
        return response.data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function executeDataPullerPipeline(PipelineId, UserId, FileName, ParametersValues, token) {
    try{
        let url = `${apiMainUrl}/api/PipelineRun/${PipelineId}?UserId=${UserId}&FileName=${FileName}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = ParametersValues;
        const response = await axios.post(url, data, config);
        console.log(response);
        return response.data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}