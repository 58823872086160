// packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import {useSelector} from "react-redux";

// styles
// services
import layoutsService from "../../../../../Services/HttpService/LayoutService";
import dataService from "../../../../../Services/HttpService/DataService";
import filesService from "../../../../../Services/HttpService/FilesService";

// graphic components
import LayoutGoParams from './Children/LayoutGoParams';
import MoveButton from '../../../../Reusable/MoveButton/MoveButton';
import LayoutResourceSelector from './Children/LayoutResourceSelector';
import TransformationModal from '../../../../Reusable/CustomModal/TransformationModal/TransformationModal';
import LayoutParamsModal from '../../../../Reusable/CustomModal/LayoutParamsModal/LayoutParamsModal';

function LayoutChooseStage(props) {
    const [ fields, setFields ] = useState([]);
    const [ goInput, setGoInput ] = useState(null);
    const [ inputValidated, setInputValidated ] = useState(false);
    const [ showGeneralParams, setShowGeneralParams ] = useState(false);
    const [ transformTriggered, setTransformTriggered ] = useState(false);
    const [ processLink, setProcessLink ] = useState(null);
    const history = useHistory();
    const { accounts } = useMsal();
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);
    const systemsList = useSelector(state => state.systems.systemsList);

    useEffect(() => {
        (async () => {
            if (props.selectedLayout) {
                setInputValidated(false);
                setGoInput(null);
                let res = await layoutsService.getLayoutGoInput(props.selectedLayout);
                setFields(res);
                const localGoInput = {};
                for (let i = 0; i < res.length; i++) {
                    const id = String(res[i]["Column_Id"]);
                    switch (res[i]['Column_Dsc']) {
                        case 'Moneda':
                            localGoInput[id] = '';
                            break;
                        default:
                            localGoInput[id] = '';
                            break;
                    }
                }
                setGoInput(localGoInput);
            }
        })();
    }, [ props.selectedLayout ]);

    /*useEffect(() => {
        props.onSelectLayout(0);
    }, [])*/

    const handleFillGeneralParams = () => {
        setShowGeneralParams(true);
    }

    const handleTransformationTrigger = ( removeRows, removeColumns, hasHeader ) => {
        const modifiedGoInput = [];
        for (const property in goInput) {
            let aux = {}
            aux['Column_Id'] = Number.parseInt(property);
            aux['Value'] = goInput[property];
            modifiedGoInput.push(aux);
        }

        const username = accounts[0].username.replace('.com', '');

        const localSystemsList = [ ...systemsList ];
        const localSystem = localSystemsList.filter(sys => sys["System_Id"] === selectedSystem);
        const container = localSystem[0]["Container"];

        (async () => {
            let res = await filesService.getFileInfo(props.selectedFile);
            const ext = res['File_Name'].split('.')[1];

            const req = {
                FileName: props.selectedFile,
                Container: container,
                SheetIndex: 0,
                Path: '',
                Extension: ext,
                Layout: props.selectedLayout,
                UserName: username,
                Input: JSON.stringify(modifiedGoInput),
                RemoveRows: removeRows,
                HasHeader: hasHeader ? 1 : 0,
                RemoveColumns: removeColumns
            };

            console.log(req);

            setShowGeneralParams(false);
            setTransformTriggered(true);
            let resLink = await dataService.transformWithLayout(req);
            setProcessLink(resLink);
        })();
    }

    const handleGoInputChange = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;

        let localGoInput = { ...goInput };
        localGoInput[name] = value;
        setGoInput(localGoInput);

        let flag = true;
        for (const property in localGoInput) {
            if (!localGoInput[property])
                flag = false;
        }
        setInputValidated(flag);
    }

    const handleBackButtonClick = () => {
        navigateBack();
    }

    const navigateBack = () => {
        history.goBack();
    }

    const transformAnotherFile = () => {
        props.onSelectFile(-1);
        props.onSelectLayout(-1);
    }

    return (
        <div className='flex-grow-1 d-flex flex-column container-fluid my-4'>
            <div className='flex-grow-1 row mt-4'>
                <LayoutResourceSelector
                    selectedResource={props.selectedLayout}
                    onSelectResource={props.onSelectLayout}
                    inputValidated={inputValidated}
                    onTriggerTransformation={handleFillGeneralParams}
                />
                <LayoutGoParams
                    layout={props.selectedLayout}
                    onGoInputChange={handleGoInputChange}
                    fields={fields}
                    goInput={goInput}
                />
            </div>
            <div className='d-flex justify-content-start mt-3'>
                <MoveButton onClick={handleBackButtonClick} />
            </div>
            <TransformationModal
                show={transformTriggered}
                onHide={() => setTransformTriggered(false)}
                link={processLink}
                onAnotherClick={transformAnotherFile}
            />
            <LayoutParamsModal
                show={showGeneralParams}
                onHide={() => setShowGeneralParams(false)}
                onTrigger={handleTransformationTrigger}
                layoutId={props.selectedLayout}
            />
        </div>
    );
}

LayoutChooseStage.propTypes = {
    selectedLayout: PropTypes.number,
    onSelectLayout: PropTypes.func,
    selectedFile: PropTypes.number,
    onSelectFile: PropTypes.func
}

export default LayoutChooseStage;