import { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import * as DatapullerService from "../../../../../Services/HttpService/DataPullerDataService";
import * as ProcessesService from "../../../../../Services/HttpService/ProcessesDataService";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";

//Styles
import "./ExecutionTabs.css";
import ParameterCardsPanel from "../ParameterCardsPanel/ParameterCardsPanel";
import CustomInputModal from "../../../../Reusable/CustomModal/CustomInputModal/CustomInputModal";
import InformationModal from "../../../../Reusable/CustomModal/InformationModal/InformationModal";
import BlueButton from "../../../../Reusable/BlueButton/BlueButton";
import MoveButton from "../../../../Reusable/MoveButton/MoveButton";
import { useHistory } from "react-router-dom";
import LoaderSpinner from "../../../../Reusable/LoaderSpinner/LoaderSpinner";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { apiLoginRequest } from "../../../../../authConfig";

function ExecutionTabs(props){
    const [ key, setKey ] = useState(0);
    const [ groups, setGroups ] = useState([]);
    const [ selectedParametersValues, setSelectedParametersValues ] = useState([]);
    const [ showInputModal, setShowInputModal ] = useState(false);
    const [ showMissingParameterModal, setShowMissingParameterModal ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);

    const menu = useSelector(state => state.menu.selectedMenu);
    const systemId = useSelector(state => state.systems.selectedSystem);
    const systemsList = useSelector(state => state.systems.systemsList);
    const { instance, accounts, inProgress } = useMsal();

    const history = useHistory();

    useEffect(() => {
        getGroups(props.PipelineId);
    }, [])

    const getGroups = (pipelineId) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let groups = await trackPromise( DatapullerService.getParametersGroupsByPipeline(pipelineId, authResponse.accessToken), 'groups-area');
                console.log(groups);
                for(let group of groups){
                    group.TblParameters = await trackPromise( DatapullerService.getParametersByGroup(group.Parameter_Group_Id, authResponse.accessToken))
                    group.TblParameters = await trackPromise(Promise.all(
                        group.TblParameters.map(async (x) => {
                            let values = await DatapullerService.getParameterValues(x.Parameter_Id, systemId, [], authResponse.accessToken);
                            if(values.length > 0) values.unshift({id: '-1', value: "All"});
                            return{
                                ...x,
                                options: values
                            }
                        })
                    ), 'groups-area');
                }
                setGroups(groups.sort((a,b) => a.Order - b.Order));
                setIsLoading(false);
            }
        }
        )();
    }

    const setPreviousTab = (index) => {
        if(index == 0) return;
        setKey(index - 1);
    }

    const setNextTab = (index) => {
        if(!verifySelectedValues(index)){
            setShowMissingParameterModal(true);
            return;
        }
        if(index == groups.length-1){
            setShowInputModal(true);
            return;
        }
        setKey(index + 1);
    }

    const handleValueSelection = (groupId, groupName, parameterId, parameterName, selectedValues) => {
        let newSelectedParameters = selectedParametersValues.slice();
        let selectedParameter = newSelectedParameters.find(x => x.gropuId = groupId && x.parameterId == parameterId);
        if(!selectedParameter){
            selectedParameter = {
                groupId, groupName, parameterId, parameterName, valuesUsed: selectedValues
            }
            newSelectedParameters.push(selectedParameter);
        }
        else{
            selectedParameter.valuesUsed = selectedValues
        }
        setSelectedParametersValues(newSelectedParameters);
        getDependetParametersValues(groupId, parameterName, newSelectedParameters);
    }

    const executeProcess = (fileName) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const owner = accounts[0].username.replace('.com', '');
                const selectedValues = selectedParametersValues.slice().map(x => {
                    let filteredValues = x.valuesUsed.filter(y=> y !== '-1');
                    let valuesDescriptions = [];
                    if(x.valuesUsed.includes('-1')){
                        valuesDescriptions = ['All'];
                        if(x.parameterName.includes('Atributos')){
                            return {
                                parameterId: x.parameterId, 
                                parameterName: x.parameterName, 
                                valuesUsed: filteredValues.map(y => y.toString()),
                                valuesDescriptions: valuesDescriptions
                            }
                        }else{
                            return {
                                parameterId: x.parameterId, 
                                parameterName: x.parameterName, 
                                valuesUsed: ['-1'],
                                valuesDescriptions: valuesDescriptions
                            }
                        }
                    }else {
                        let newGroups = groups.slice();
                        let group = newGroups.find(y => y.Parameter_Group_Id == x.groupId);
                        let parameter = group.TblParameters.find(y => y.Parameter_Id == x.parameterId);
                        valuesDescriptions = parameter.options.filter(y => filteredValues.includes(y.id)).map(y => y.value);
                        return {
                            parameterId: x.parameterId, 
                            parameterName: x.parameterName, 
                            valuesUsed: filteredValues.map(y => y.toString()),
                            valuesDescriptions: valuesDescriptions
                        }
                    }
                    
                });
                setShowInputModal(false);
                history.push(props.path);
                ProcessesService.executeDataPullerPipeline(props.PipelineId, owner, fileName, selectedValues, authResponse.accessToken).then(res => {
                    console.log(res);
                });
            }
        })();
    }

    const getDependetParametersValues = (groupId, parameterName, newSelectedParameters) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let newGroups = groups.slice();
                let group = newGroups.find(x => x.Parameter_Group_Id == groupId);
                group.TblParameters = await trackPromise(Promise.all(
                    group.TblParameters.map(async (param) => {
                        if (param.Related_Parameters_Names == null) return param;
                        if (!param.Related_Parameters_Names.includes(parameterName)) return param;
                        let relatedParametersValues = newSelectedParameters.filter(x => x.groupId == param.Parameter_Group_Id && param.Related_Parameters_Names.includes(x.parameterName));
                        relatedParametersValues = relatedParametersValues.map(x => {
                            return { parameterId: x.parameterId, parameterName: x.parameterName, valuesUsed: x.valuesUsed }
                        });
                        let values = await DatapullerService.getParameterValues(param.Parameter_Id, systemId, relatedParametersValues, authResponse.accessToken);
                        if(values.length > 0) values.unshift({id: '-1', value: "All"});
                        return {
                            ...param,
                            options: values
                        }
                    })
                ));
                setGroups(newGroups);
            }
        }
        )();
    }

    const verifySelectedValues = (index) => {
        const newGroups = groups.slice();
        const group = newGroups[index];
        let selectedParameters = selectedParametersValues.slice();
        for(let parameter of group.TblParameters){
            let selectedParameter = selectedParameters.find(x => x.parameterId === parameter.Parameter_Id && x.valuesUsed.length > 0);
            if(!selectedParameter){
                // parameter.Value_Missing = true;
                // setGroups(newGroups);
                return false
            }
        }
        return true;
    }

    const handleTabSelection = (index) => {
        if(index > key && !verifySelectedValues(key)){
            setShowMissingParameterModal(true);
            return;
        }
        setKey(index);
    }

    return(
        <div className="ExecutionTabsContainer">
            <div className="position-relative">
                {isLoading && (
                    <div className='box-grow-no-scroll h-100 d-flex flex-column justify-content-center'>
                        <h5 className="text-muted text-center">Loading parameters</h5>
                    </div>
                )}
                <LoaderSpinner color="#0077CC" area="groups-area" />
                <div className='box-grow-no-scroll h-100 d-flex flex-column justify-content-center'>
                    {!isLoading && !groups.length && (
                        <h5 className='text-muted text-center'>The parameters set-up is missing. Contact your system administrator to extract data.</h5>
                    )}
                </div>
                <Tab.Container id="parameters-tab" activeKey={key} onSelect={handleTabSelection}>
                    <Nav justify variant="pills">
                        {groups.map((item, index) => {
                            return (
                                <Nav.Item key={index} className={`executionTab ${index == key ? 'executionTabActive' : ''}`}>
                                    <Nav.Link eventKey={index}>{item.Name}</Nav.Link>
                                </Nav.Item>
                            )
                        })}
                    </Nav>
                    <Tab.Content>
                        {groups.map((item, index) => {
                            return (
                                <Tab.Pane eventKey={`${index}`} key={index}>
                                    <ParameterCardsPanel
                                        description={item.Description}
                                        parametersList={item.TblParameters ?? []}
                                        handleBackButtonClick={() => setPreviousTab(index)}
                                        handleNextButtonClick={() => setNextTab(index)}
                                        handleCancelButtonClick={() => history.push(props.path)}
                                        handleValueSelection={(paramId, paramName, selectedValues) => handleValueSelection(item.Parameter_Group_Id, item.Name, paramId, paramName, selectedValues)}
                                    />
                                </Tab.Pane>
                            )
                        })}
                    </Tab.Content>
                </Tab.Container>
            </div>
            <CustomInputModal
                show={showInputModal}
                onHide={() => setShowInputModal(false)}
                title="File Name"
                primaryText="Enter the name that will be used for the generated data file."
                secondaryText="Note: Current date and time will be added automatically to the end of the provided name."
                cancelBtnText="Cancel"
                acceptBtnText="Start"
                onCancelBtnClick={() => setShowInputModal(false)}
                onAcceptBtnClick={executeProcess}
            />
            <InformationModal
                show={showMissingParameterModal}
                onHide={() => setShowMissingParameterModal(false)}
                onAcceptBtnClick={() => setShowMissingParameterModal(false)}
                title="Missing parameters values"
                text="You have not selected values for all the parameters in the current tab. Please complete the selection before going to the next tab."
            />
        </div>
    );
}

export default ExecutionTabs;