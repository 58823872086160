// Packages
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSystemsList, setSelectedSystem} from "../../../Redux/Reducers/systemsSlice";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { trackPromise } from 'react-promise-tracker';

// Styles
import './SystemSelect.css';

// Services
import userService from "../../../Services/HttpService/UserService";
import { apiLoginRequest } from '../../../authConfig';

// Graphic Components
import Icon from "../../Reusable/Icon/Icon";
import SelectComponent from "../../Reusable/SelectComponent/SelectComponent";
import LoaderSpinner from "../../Reusable/LoaderSpinner/LoaderSpinner"

function SystemSelect() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ localSystemsList, setLocalSystemsList ] = useState([]);
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        if (inProgress === 'none' && accounts.length > 0) {
            instance.acquireTokenSilent({
                account: accounts[0],
                ...apiLoginRequest
            }).then(res => {
                console.log(res);
                const userId = accounts[0].username.replace('.com', '');
                trackPromise(
                    userService.getAvailableSystems(userId, res.accessToken)
                    .then(systems => {
                        dispatch(setSystemsList(systems));
                        // const mappedResponse = systems.map(system => ({ value: system['SystemId'], dsc: system['SystemDsc']}));
                        const mappedResponse = systems.map(system => {
                            return {
                                value: system.System_Id,
                                dsc: system.System_Dsc
                            }
                        });
                        mappedResponse.unshift({ value: -1, dsc: 'System' });
                        setLocalSystemsList(mappedResponse);
                    })
                );
            })
        }
    }, [ instance, accounts, inProgress ]);

    const handleChange = (evt) => {
        dispatch(setSelectedSystem(parseInt(evt.target.value)));
        history.push("/home")
    }

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={apiLoginRequest}>
            <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column align-items-center">
                    <Icon specs="gensa-blue" />
                    <div className="systemSelectLabel mt-5">
                        <label><strong>SELECT SYSTEM</strong></label>
                    </div>
                    <div className="col systemSelectLabel">
                        <SelectComponent
                            optionColor="selectComponentColorBlue"
                            options={localSystemsList}
                            handler={handleChange} />
                        <LoaderSpinner color="#0077CC" />
                    </div>
                </div>
            </div>
        </MsalAuthenticationTemplate>
    );
}

export default SystemSelect;