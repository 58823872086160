import axios from "axios"
import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/Dashboard';

export async function getLookUpTree(SystemId, UserId, level, ID=null, token){
    try{
        let url = `${mainUrl}/Tree/`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: {
                SystemId,
                UserId,
                level,
                ID
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getSites(SystemId, UserId, token){
    try{
        let url = `${mainUrl}/Sites/${SystemId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: {
                UserId
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getSite(SiteId, token){
    try{
        let url = `${mainUrl}/Site/${SiteId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return null;
    }
}

export async function createSite(SiteName, SiteUrl, SiteUser, SystemId, SitePass, ServerUrl, InternalId, UserId, token){
    try{
        let url = `${mainUrl}/Site/${UserId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            Site_Name: SiteName,
            Site_Url: SiteUrl,
            Site_User: SiteUser,
            System_Id: SystemId,
            Site_Pass: SitePass,
            Server_Url: ServerUrl,
            Internal_Id: InternalId
        }
        const response = await axios.post(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function updateSite(SiteId, SiteName, SiteUrl, SiteUser, SystemId, token){
    try{
        let url = `${mainUrl}/Site/${SiteId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            SiteName,
            SiteUrl,
            SiteUser,
            SystemId
        }
        const response = await axios.put(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function deleteSite(SiteId, token){
    try{
        let url = `${mainUrl}/Site/${SiteId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.delete(url, config);
        return response;
    }
    catch(e){
        console.log(e);
        return null;
    }
}

export async function getWorkbooks(SiteId, token){
    try{
        let url = `${mainUrl}/Workbooks/${SiteId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getWorkbook(WorkbookId, token){
    try{
        let url = `${mainUrl}/Workbook/${WorkbookId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return null;
    }
}

export async function createWorkbook(WorkbookDsc, WokrbookPath, InternalId, SiteId, token){
    try{
        let url = `${mainUrl}/Workbook`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            Workbook_Dsc: WorkbookDsc,
            Workbook_Path: WokrbookPath,
            Internal_Id: InternalId,
            Site_Id: SiteId
        }
        const response = await axios.post(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function updateWorkbook(WorkbookId, WorkbookDsc, WokrbookPath, InternalId, SiteId, token){
    try{
        let url = `${mainUrl}/Workbook/${WorkbookId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            WorkbookDsc,
            WokrbookPath,
            InternalId,
            SiteId
        }
        const response = await axios.put(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function deleteWorkbook(WorkbookId, token){
    try{
        let url = `${mainUrl}/Workbook/${WorkbookId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.delete(url, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function getViews(WorkbookId, token){
    try{
        let url = `${mainUrl}/Views/${WorkbookId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getView(ViewId, token){
    try{
        let url = `${mainUrl}/View/${ViewId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return null;
    }
}

export async function createView(ViewDsc, ViewPath, InternalId, WorkbookId, token){
    try{
        let url = `${mainUrl}/View`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            View_Dsc: ViewDsc,
            View_Path: ViewPath,
            Internal_Id: InternalId,
            Workbook_Id: WorkbookId
        }
        const response = await axios.post(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function updateView(ViewId, ViewDsc, ViewPath, InternalId, WorkbookId, token){
    try{
        let url = `${mainUrl}/View/${ViewId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            ViewDsc,
            ViewPath,
            InternalId,
            WorkbookId
        }
        const response = await axios.put(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function deleteView(ViewId, token){
    try{
        let url = `${mainUrl}/View/${ViewId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.delete(url, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function getRoleSite(RoleId, token){
    try{
        let url = `${mainUrl}/RoleSite/${RoleId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return null;
    }
}

export async function createRoleSite(Role_Id, Site_Id, token){
    try{
        let url = `${mainUrl}/RoleSite`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            Role_Id,
            Site_Id
        }
        const response = await axios.post(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function updateRoleSite(Role_Id, Site_Id, token){
    try{
        let url = `${mainUrl}/RoleSite/${Site_Id}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            Role_Id,
            Site_Id
        }
        const response = await axios.put(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function deleteRoleSite(Role_id, Site_Id, token){
    try{
        let url = `${mainUrl}/RoleSite`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: {
                Role_id,
                Site_Id
            }
        }
        const response = await axios.delete(url, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function getRoleWorkbook(RoleId, token){
    try{
        let url = `${mainUrl}/RoleWorkbook/${RoleId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return null;
    }
}

export async function createRoleWorkbook(RoleId, WorkbookId, token){
    try{
        let url = `${mainUrl}/RoleWorkbook`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            RoleId,
            WorkbookId
        }
        const response = await axios.post(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function UpdateRoleWorkbook(RoleId, WorkbookId, token){
    try{
        let url = `${mainUrl}/RoleWorkbook/${WorkbookId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            RoleId,
            WorkbookId
        }
        const response = await axios.put(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function deleteRoleWorkbook(RoleId, WorkbookId, token){
    try{
        let url = `${mainUrl}/RoleSite`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: {
                RoleId,
                WorkbookId
            }
        }
        const response = await axios.delete(url, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export async function getSystemTypes(token){
    try{
        let url = `${mainUrl}/SystemType`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getTrustedTicket(serverUrl, username, siteId, azureToken){
    try{
        let url = `${apiMainUrl}/api/Tableau/Token`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + azureToken
            },
            // params: {
            //     server: serverUrl,
            //     Username: username,
            //     site_id: siteId,
            // }
        }
        let data = {
            server: serverUrl,
            Username: username,
            site_id: siteId,
        }
        const response = await axios.post(url, data, config);
        return response;
    }
    catch(e){
        console.log(e);
    }
}