// Packages
import { PublicClientApplication } from '@azure/msal-browser';

// Styles
import './App.css';

// React Components
import { MsalProvider } from '@azure/msal-react';
import { configuration } from "../../authConfig";
import AsyncFunctionalities from "./AsyncFunctionalities/AsyncFunctionalities";

//FontAwsome Components
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFastBackward, faStepBackward, faFastForward, faStepForward, faChevronUp, faChevronDown, faDatabase, faFile, faSearch, faEdit, faTimes, faPlus, faPlusCircle, faSave, faFileImport, faFileExport, faTrash, faHome, faFolderMinus, faList } from '@fortawesome/free-solid-svg-icons'
library.add(fas, faFastBackward, faStepBackward, faFastForward, faStepForward, faChevronDown, faChevronUp, faDatabase, faFile, faSearch, faEdit, faTimes, faPlus, faPlusCircle, faSave, faFileImport, faFileExport, faTrash, faHome, faFolderMinus, faList)

const pca = new PublicClientApplication(configuration)

function App() {
    return (
        <MsalProvider instance={pca}>
            <AsyncFunctionalities />
        </MsalProvider>
    );
}

export default App;
