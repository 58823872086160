import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import BlueButton from '../../../../Reusable/BlueButton/BlueButton';

import * as DashboardDataService from "../../../../../Services/HttpService/DashboardDataService"
import userService from "../../../../../Services/HttpService/UserService"
import { useHistory, useParams } from "react-router";
import { Form } from "react-bootstrap";
import MoveButton from "../../../../Reusable/MoveButton/MoveButton";
import { apiLoginRequest } from "../../../../../authConfig";

function EditRolesSite(props){
    const history = useHistory();
    const { id } = useParams();
    const [ rolesList, setRolesList ] = useState([]);
    const [ selectedRoles, setSelectedRoles ] = useState([]);
    const [ currentFolder, setCurrentFolder ] = useState([]);
    const { instance, accounts, inProgress } = useMsal();
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);

    useEffect(() => {
        getSiteFolder();
        getRolesList();
    }, []);

    const navigateBack = () => {
        history.goBack();
    }

    const getSiteFolder = () => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let data = await DashboardDataService.getSite(id, authResult.accessToken);
                setCurrentFolder(data[0]);
            }
        })();
    }

    const getRolesList = () => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let roles = await userService.getRoles(selectedSystem, authResult.accessToken);
                roles = roles.filter(x => x.Active);
                let rolesToSelect = [];
                for(const x of roles)
                {
                    let availableSites = await DashboardDataService.getRoleSite(x.Role_Id, authResult.accessToken);
                    if(availableSites.findIndex(y => y.Site_Id == id) != -1){
                        rolesToSelect.push(x);
                    }
                }
                setRolesList(roles);
                setSelectedRoles(rolesToSelect);
            }
        })();
    }

    const handleSaveBtnClick = () => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                //Para cada role
                //Verificar si esta seleccionado
                //Si lo esta, consultar la bd para ver si existe el registro, si no existe, crearlo.
                //Si no lo esta, consultar la bd para ver si existe el registro, si existe, eliminarlo.
                for(const role of rolesList) {
                    let availableSites = await DashboardDataService.getRoleSite(role.Role_Id, authResult.accessToken);
                    if(selectedRoles.findIndex(x => x.Role_Id == role.Role_Id) !== -1){
                        if(availableSites.findIndex(y => y.Site_Id === parseInt(id)) === -1){
                            let res = await DashboardDataService.createRoleSite(role.Role_Id, parseInt(id), authResult.accessToken);
                            console.log(res);
                        }
                    }else{
                        if(availableSites.findIndex(y => y.Site_Id == parseInt(id)) !== -1){
                            let res = await DashboardDataService.deleteRoleSite(role.Role_Id, parseInt(id), authResult.accessToken);
                            console.log(res);
                        }
                    }
                }
                let url = `${props.path}`;
                history.push(url);
            }
        })();
    }
    
    const handleRoleSwitchChange = (role, checked) => {
        console.log(checked)
        let alreadySelectedIdx = selectedRoles.findIndex(x => x.Role_Id === role.Role_Id);
        if(alreadySelectedIdx != -1){
            setSelectedRoles(selectedRoles.filter(x => x.Role_Id !== role.Role_Id));
        }else{
            setSelectedRoles([...selectedRoles, role]);
        }
    }

    return (
        <div className="flex-grow-1 d-flex flex-column container-fluid my-4">
            <div className="flex-grow-1 d-flex flex-column align-items-center container-fluid my-4">
                <div className="roles-list-container w-50">
                    <div className="info-container">
                        <h3>{currentFolder.Site_Name || "Folder name"}</h3>
                        <h6 className="text-muted">Select the roles that will have access to this folder and its content by using the switches on the right and clicking save.</h6>
                    </div>
                    <div className='d-flex justify-content-end my-3'>
                        <BlueButton onClick={handleSaveBtnClick} title="Save" />
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th>
                                        Role
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {rolesList.map((item, index) => {
                                    return (
                                        <tr key={item.Role_Id}>
                                            <td>{item.Role_Dsc}</td>
                                            <td className='actions'>
                                                {/* <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" checked={selectedRoles.map(x => x.Role_Id).includes(item.Role_Id)} />
                                                <label className="custom-control-label"></label>
                                            </div> */}
                                                <Form.Check
                                                    type="switch"
                                                    id={'custom-check-' + index}
                                                    label=""
                                                    checked={selectedRoles.findIndex(x => x.Role_Id === item.Role_Id) != -1}
                                                    onChange={(e) => handleRoleSwitchChange(item, e.target.checked)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-start mt-3 mx-3">
                <MoveButton onClick={navigateBack} />
            </div>
        </div>
    )
}

export default EditRolesSite;