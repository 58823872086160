// packages
import PropTypes from 'prop-types';

// styles
import './InstructionsBox.css';

// graphic components
import BlueButton from '../BlueButton/BlueButton';

function InstructionsBox({ title, instructions, buttonTitle, buttonClick }) {
    return(
        <div className='instructionsBoxFrame d-flex flex-column align-items-center px-3 pt-5 my-3 mx-3'>
            <h5 className='instructionsBoxTitle'>
                <strong>{title}</strong>
            </h5>
            <p className='instructionsBoxContent'>
                {instructions}
            </p>
            {
                buttonTitle ?
                    <div className='container my-3 justify-content-center'>
                        <BlueButton
                            onClick={buttonClick}
                            title={buttonTitle}
                            block={true}
                            large={true}
                        />
                    </div>
                    : null
            }
        </div>
    );
}

InstructionsBox.propTypes = {
    buttonTitle: PropTypes.string,
    buttonClick: PropTypes.func,
    instructions: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}

export default InstructionsBox;