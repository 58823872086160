// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';

// Styles
import './CustomInputModal.css';

// Graphic Components
import Modal from "react-bootstrap/Modal";
import { useEffect } from 'react';

export function SelectInputModal({show, onHide, title, primaryText, optionsLabel, optionsList, acceptBtnText, cancelBtnText, onAcceptBtnClick, onCancelBtnClick}){
    const [ input, setInput ] = useState('');

    useEffect(() => {
        if(optionsList && optionsList.length > 0) setInput(optionsList[0].id)
    }, [optionsList]); 

    const handleChange = (event) => {
        setInput(event.target.value);
    };

    const handleAcceptButtonClick = () => {
        setInput('');
        onAcceptBtnClick(input);
    }

    const handleCancelButtonClick = () => {
        setInput('');
        onCancelBtnClick();
    }

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="inputModal"
        >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h3 className="msg-title">
                        {title}
                    </h3>
                    <p className="msg-body my-2 text-dark">
                        {primaryText}
                    </p>
                    <div className="form-group">
                        {/* <label className="text-sm">{optionsLabel}</label> */}
                        <select className='form-control form-control-sm' name="input" value={input} onChange={handleChange} >
                            {optionsList.map((item, index) => {
                                return <option key={index} value={item.id}>{item.value}</option>
                            })}
                        </select>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-outline-dark mr-3' onClick={handleCancelButtonClick}>{cancelBtnText}</button>
                        <button type='button' className='btn btn-primary' onClick={handleAcceptButtonClick}>{acceptBtnText}</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

SelectInputModal.propTypes = {
    onCancelBtnClick: PropTypes.func.isRequired,
    onAcceptBtnClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string,
    primaryText: PropTypes.string,
    optionsLabel: PropTypes.string,
    optionsList: PropTypes.array.isRequired,
    acceptBtnText: PropTypes.string.isRequired,
    cancelBtnText: PropTypes.string
}