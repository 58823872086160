import axios from "axios"
import * as EncryptionService from "./EncryptionService";

const tableauDevSiteApi = 'views.g3panel.com';
const apiRoute = '/api/3.4/'

export async function getServerInfo(serverUrl = tableauDevSiteApi){
    try{
        const requestPath = `https://${serverUrl}${apiRoute}serverinfo`;

        let response = await axios.get(requestPath);
        if(response.status == 200) return 1;
        else return -1;
    }
    catch(err){
        return -1;
    }
    
}

export async function signin(serverUrl = tableauDevSiteApi, username, password, site, token){
    const requestPath = `https://${serverUrl}${apiRoute}auth/signin`;

    let response = await axios.post(requestPath, {
        credentials: {
            name: username,
            password: password,
            site: {
                contentUrl: site
            }
        }
    })

    let encryptedUsername = await EncryptionService.encryptMessageAES(username, token);
    let encryptedPassword = await EncryptionService.encryptMessageAES(password, token);

    localStorage.setItem('TableauToken',response.data.credentials.token)
    localStorage.setItem('TableauSiteId',response.data.credentials.site.id)
    localStorage.setItem('TableauServerUrl', serverUrl)
    localStorage.setItem('TableauUsername', username)
    localStorage.setItem('TableauSite', response.data.credentials.site.contentUrl)
    localStorage.setItem('TalbeauPassword',password);
    localStorage.setItem('TableauTokenDatetime',new Date());

    return response;
}

export async function switchSite(serverUrl = tableauDevSiteApi, siteUrl){
    const requestPath = `https://${serverUrl}${apiRoute}auth/switchSite`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        }
    }

    let data = {
        site: {
            contentUrl: siteUrl,
        }
    }

    let response = await axios.post(requestPath, data, config);
    localStorage.setItem('TableauToken',response.data.credentials.token)
    localStorage.setItem('TableauSiteId',response.data.credentials.site.id)
    localStorage.setItem('TableauSite', response.data.credentials.site.contentUrl)
    localStorage.setItem('TableauTokenDatetime',new Date());
}

export async function getSitesForServer(serverUrl = tableauDevSiteApi){
    const requestPath = `https://${serverUrl}${apiRoute}sites/`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        }
    }

    let response = axios.get(requestPath, config);
    return (await response).data;
}

export async function getProjectsForSite(serverUrl = tableauDevSiteApi, siteId, parentId=null){
    let requestPath = '';
    if(parentId == null){
        requestPath = `https://${serverUrl}${apiRoute}sites/${siteId}/projects`;
    }else{
        requestPath = `https://${serverUrl}${apiRoute}sites/${siteId}/projects?filter=parentProjectId:eq:${parentId}`;
    }

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        }
    }

    let response = axios.get(requestPath, config);
    return (await response).data;
}

export async function getWorkbooksForSite(serverUrl = tableauDevSiteApi, siteId){

    const requestPath = `${tableauDevSiteApi}sites/${siteId}/workbooks`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        }
    }

    let response = axios.get(requestPath, config);
    return (await response).data;
}

export async function getWorkbooksForProject(serverUrl = tableauDevSiteApi, siteId, projectId){
    const requestPath = `https://${serverUrl}${apiRoute}sites/${siteId}/workbooks`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        }
    }

    let response = axios.get(requestPath, config);
    let workbooksData = (await response).data;

    let filteredWorkbooks = workbooksData.workbooks.workbook.filter(item => item.project.id === projectId);

    return filteredWorkbooks;
}

export async function getWorkbookPreview(serverUrl = tableauDevSiteApi, siteId, workbookId) {
    const requestPath = `https://${serverUrl}${apiRoute}sites/${siteId}/workbooks/${workbookId}/previewImage`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        },
        responseType: 'arraybuffer',
    }

    let response = axios.get(requestPath, config)
    
    return (await response).data;
}

export async function getWorkbookDetails(serverUrl = tableauDevSiteApi, siteId, workbookId) {
    const requestPath = `https://${serverUrl}${apiRoute}sites/${siteId}/workbooks/${workbookId}`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        },
    }

    let response = axios.get(requestPath, config)
    
    return (await response).data;
}

export async function getViewsForWorkbook(serverUrl = tableauDevSiteApi, siteId, workbookId){
    const requestPath = `https://${serverUrl}${apiRoute}sites/${siteId}/workbooks/${workbookId}/views`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        }
    }

    let response = axios.get(requestPath, config)
    return (await response).data;
}

export async function getViewsForSite(serverUrl = tableauDevSiteApi, siteId) {
    const requestPath = `${tableauDevSiteApi}sites/${siteId}/views`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        }
    }

    let response = axios.get(requestPath, config)
    return (await response).data;
}

export async function getViewPreview(serverUrl = tableauDevSiteApi, siteId, workbookId, viewId) {
    const requestPath = `https://${serverUrl}${apiRoute}sites/${siteId}/workbooks/${workbookId}/views/${viewId}/previewImage`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        },
        responseType: 'arraybuffer',
    }

    let response = axios.get(requestPath, config)
    return (await response).data;
}

export async function getViewDetails(serverUrl = tableauDevSiteApi, siteId, viewId){
    const requestPath = `https://${serverUrl}${apiRoute}sites/${siteId}/views/${viewId}`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        },
    }

    let response = axios.get(requestPath, config);
    return (await response).data;
}

export async function getViewData(serverUrl = tableauDevSiteApi, siteId, viewId) {
    const requestPath = `https://${serverUrl}${apiRoute}sites/${siteId}/views/${viewId}/data`;

    let config = {
        headers: {
            'X-Tableau-Auth': localStorage.getItem("TableauToken"),
        },
    }

    let response = axios.get(requestPath, config);
    return (await response).data;
}

export async function getTrustedTicket(serverUrl = tableauDevSiteApi, siteName, username){
    const requestPath = `https://${serverUrl}/trusted`
    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        // params: {
        //     username: username,
        //     target_site: siteName,
        // }
    }

    let data = {
        username: username,
        target_site: siteName,
    }

    let response = axios.post(requestPath, data, config);
    return (await response).data;
}