// packages
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';

// styles
import './TransformationProcess.css';

// services
// graphic components
import TransformFileUpload from "./Children/TransformFileUpload";
import FileResourceSelector from "./Children/FileResourceSelector";
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

function CatalogUploadModal({ show, onHide, onSelectFile, selectedFile, onConfirmation}) {
    const { path } = useRouteMatch();

    const nextClick = () => {

    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="catalogEditorModal"
        >
            <Modal.Header closeButton>
                <h3 className="msg-title">
                    Choose or upload a file
                </h3>
            </Modal.Header>
            <Modal.Body>
                <div className="flex-grow-1 d-flex flex-column container-fluid my-4">
                    <div className="flex-grow-1 row mt-4 mx-3">
                        <FileResourceSelector
                            onSelectResource={onSelectFile}
                            selectedResource={selectedFile}
                            onConfirmation={onConfirmation}
                            path={path}
                        />

                        <TransformFileUpload
                            nextClick={nextClick}
                            onSelectFile={onSelectFile}
                            path={path}
                        />
                    </div>
                    <div className="d-flex justify-content-start mt-3 mx-3">
                        {/* <MoveButton onClick={handleBackButtonClick} /> */}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CatalogUploadModal;