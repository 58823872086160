import { useMsal } from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { Link, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import PowerBIReport from "../../Reusable/PowerBIReport/PowerBIReport";

//Services
import * as CatalogsDataService from "../../../Services/HttpService/CatalogsDataService.js";
import * as LegacyProcessingDataService from "../../../Services/HttpService/LegacyProcessingDataService.js";
import ProcessesHistory from '../../Reusable/ProcessesHistory/ProcessesHistory.js';
import CatalogMaster from './CatalogMaster';
import ProductOverviewModal from './ProductOverviewModal.js';
import { apiLoginRequest } from '../../../authConfig';
import CatalogUploadModal from './CatalogUploadModal/CatalogUploadModal';

function ProductsCatalog(props){
    const [ columns, setColumns ] = useState([]);
    const [ rows, setRows ] = useState([]);
    const [ rowsInfo, setRowsInfo ] = useState({
        rows: [],
        totalRows: 0,
        totalPages: 0
    });
    const [ columnsFiterValues, setColumnsFiterValues ] = useState([]);
    const [ filters, setFilters ] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ pagesNumber, setPagesNumber ] = useState(1);
    const [ showProductModal, setShowProductModal ] = useState(false);
    const [ showUploadModal, setShowUploadModal ] = useState(false);
    const [ processConfig, setProcessConfig ] = useState({
        processId: -1, processName: "", fileId: -1, fileName: "", fileExt: ""
    });

    const menu = useSelector(state => state.menu.selectedMenu);
    const systemId = useSelector(state => state.systems.selectedSystem);
    const systemsList = useSelector(state => state.systems.systemsList);
    const { instance, accounts, inProgress } = useMsal();
    const { path } = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        getColumns();
    }, [])

    useEffect(() => {
        getRows();
    }, [currentPage, filters])

    const getColumns = () => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let columns = await trackPromise( CatalogsDataService.getCatalogColumns(systemId, authResponse.accessToken), 'table-area');
                setColumns(columns);
            }
        }
        )();
    }

    const getColumnFilterValues = (columnId) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let values = await CatalogsDataService.getCatalogColumnFilterValues(columnId, filters, authResponse.accessToken);
                let newColumnsFiterValues = columnsFiterValues.slice();
                let existingColumnsFiterValues = newColumnsFiterValues.find(x => x.columnId === columnId);
                if(!existingColumnsFiterValues){
                    newColumnsFiterValues.push({columnId: columnId, values: values.map( x => {return {id: String(x.id), value: x.value}} )});
                }else{
                    existingColumnsFiterValues.values = values.map( x => {return {id: String(x.id), value: x.value}} );
                }
                setColumnsFiterValues(newColumnsFiterValues);
                
            }
        }
        )();
    }

    const getRows = () => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                setRowsInfo({...rowsInfo, rows: []});
                let rows = await trackPromise( CatalogsDataService.getProductRows(systemId, filters, currentPage, authResponse.accessToken), 'rows-area');
                setRowsInfo({
                    rows: rows.result,
                    totalRows: rows.quantity,
                    totalPages: rows.page_quantity,
                    pageSize: 20
                })
            }
        }
        )();
    }

    const addFilter = (columnId, columnName, values) => {
        let newFilters = filters.slice();
        let existingFilter = newFilters.find(x => x.filterName === columnName);
        if(!existingFilter){
            newFilters.push({filterName: columnName, values: values});
        }else{
            existingFilter.values = values;
        }
        setFilters(newFilters);
        setCurrentPage(1);
    }

    const removeFilter = (columnId, columnName) => {
        let newFilters = filters.slice();
        let existingFilter = newFilters.find(x => x.filterName === columnName);
        if(existingFilter){
            newFilters.splice(newFilters.indexOf(existingFilter), 1);
            setFilters(newFilters);
            setCurrentPage(1);
        }
    }

    const clearFilters = () => {
        setFilters([]);
        setCurrentPage(1);
    }

    const exportRowSelection = (rowIds, fileName) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                const localSystem = systemsList.filter(sys => sys["System_Id"] === systemId);
                const container = localSystem[0]["Container"];
                let processInfo = {
                    FolderPath: 'catalogs',
                    SystemId: systemId,
                    UserId: accounts[0].username.replace('.com', ''),
                    Container: container,
                    FileName: fileName,
                    ProcessId: 0,
                    DataPullerFolderId: 59,
                    DataPullerMenuId: menu.Menu_Id,
                    registers: rowIds
                }
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let processId = await CatalogsDataService.exportRowSelection(processInfo, authResponse.accessToken);
                console.log(processId);
            }
        }
        )();
    }

    const deleteRowSelection = (rowIds) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                const localSystem = systemsList.filter(sys => sys["System_Id"] === systemId);
                const container = localSystem[0]["Container"];
                let processInfo = {
                    FolderPath: 'catalogs',
                    SystemId: systemId,
                    UserId: accounts[0].username.replace('.com', ''),
                    Container: container,
                    FileName: 'DeleteTest',
                    ProcessId: 0,
                    DataPullerFolderId: 59,
                    DataPullerMenuId: menu.Menu_Id,
                    registers: rowIds
                }
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let processId = await CatalogsDataService.deleteRowSelection(systemId, processInfo, authResponse.accessToken);
                console.log(processId);
            }
        }
        )();
    }

    const exportTable = (fileName) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                const localSystem = systemsList.filter(sys => sys["System_Id"] === systemId);
                const container = localSystem[0]["Container"];
                let processInfo = {
                    FolderPath: 'catalogs',
                    SystemId: systemId,
                    UserId: accounts[0].username.replace('.com', ''),
                    Container: container,
                    FileName: fileName,
                    ProcessId: 0,
                    DataPullerFolderId: 59,
                    DataPullerMenuId: menu.Menu_Id,
                    filter: filters,
                }
                console.log(processInfo);
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let processId = await CatalogsDataService.exportTable(processInfo, authResponse.accessToken);
                console.log(processId);
            }
        }
        )();
    }

    const importData = () => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const userId = accounts[0].username.replace('.com', '');
                const localSystemsList = [ ...systemsList ];
                const localSystem = localSystemsList.filter(sys => sys["System_Id"] === systemId);
                const container = localSystem[0]["Container"];
                let packages = await LegacyProcessingDataService.getPackages(systemId, menu.Menu_Id, authResponse.accessToken);
                if(!packages){
                    console.log('No packages');
                    return;
                }
                let parameters = LegacyProcessingDataService.getPackageParameters(packages[0].PLayout_Id, authResponse.accessToken);
                parameters = parameters.map(x => { return { name: x.Name, value: packages[0].PLayout_Id, type: 'int'}})
                let execution = await LegacyProcessingDataService.executePackage(packages[0].PLayout_Id, userId, processConfig.fileName+'.'+processConfig.fileExt, systemId, menu.Menu_Id, container, parameters, authResponse.accessToken);
                if(execution == 1){
                    history.push(path);
                }
            }
        })();
    }

    const handleFileSelection2 = (fileName, fileId, fileExt) => {
        setProcessConfig({...processConfig, fileName, fileId, fileExt});
    }

    const handleFileConfirmation = () => {
        importData();
        setShowUploadModal(false);
    }

    const handleViewProductInfo = () => {
        setShowProductModal(true);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const handleImportData = () => {
        //setShowUploadModal(true)
    }

    const goToProcesses = () => {
        history.push(`${path}/processes`);
    }

    return (
        <Switch>
            <Route path={`${path}/tree`}>
                <PowerBIReport />
            </Route>
            <Route path={`${path}/processes`}>
                <ProcessesHistory newProcessEnabled={false} MenuId={menu.Menu_Id} />
            </Route>
            <Route path={`${path}`}>
                <CatalogMaster
                    title='Products'
                    columns={columns}
                    rows={rowsInfo.rows}
                    columnsFilterValues={columnsFiterValues}
                    columnFiltersSelectedValues={filters}
                    viewItem={handleViewProductInfo}
                    totalPages={rowsInfo.totalPages}
                    totalRows={rowsInfo.totalRows}
                    activePage={currentPage}
                    pageSize={rowsInfo.pageSize}
                    onPageChange={handlePageChange}
                    getColumnFilterValues={getColumnFilterValues}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    clearFilters={clearFilters}
                    exportSelection={exportRowSelection}
                    exportTable={exportTable}
                    importData={handleImportData}
                    deleteSelection={deleteRowSelection}
                    goToProcesses={goToProcesses}
                />
                <ProductOverviewModal
                    show={showProductModal}
                    onHide={() => setShowProductModal(false)}
                />
                <CatalogUploadModal
                    show={showUploadModal}
                    onHide={() => setShowUploadModal(false)}
                    onSelectFile={handleFileSelection2}
                    selectedFile={processConfig.fileId}
                    onConfirmation={handleFileConfirmation}
                    path={path}
                />
            </Route>
        </Switch>
        
    )
}

export default ProductsCatalog;