// Packages
import PropTypes from 'prop-types';

// Styles
import './LayoutColumn.css'

// Graphic Components
import SwitchComponent from "../SwitchComponent/SwitchComponent";
import SelectComponent from "../SelectComponent/SelectComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";

function LayoutColumn(props) {
    return (
        <div className="row mb-4 mx-3 pt-3 layoutColumnBorder" draggable="true">
            <div className="col-md-2">
                <SwitchComponent
                    onColor="#004c84"
                    title="Ignore"
                    checked={props.ignoreChecked}
                    onChange={props.onChangeIgnore}
                    switchDisabled={props.ignoreDisabled}
                />
            </div>
            <div className="col-md-4">
                <TextInputComponent inputType="text" title="Incoming column name" inputDisabled={props.ignoreChecked} onChange={props.onInputValueChange} />
            </div>
            <div className="col-md-4">
                <SelectComponent title="Layout column" options={props.columns} selectDisabled={props.ignoreChecked} handler={props.onColumnChange} />
            </div>
            <div className="col-md-2">
                <SwitchComponent
                    onColor="#004c84"
                    title="Pivot"
                    checked={props.pivotChecked}
                    onChange={props.onChangePivot}
                    switchDisabled={props.ignoreChecked}
                />
            </div>
        </div>
    );
}

LayoutColumn.propTypes = {
    columns: PropTypes.array,
    pivotChecked: PropTypes.bool,
    onChangePivot: PropTypes.func,
    columnValue: PropTypes.number,
    onColumnChange: PropTypes.func,
    inputValue: PropTypes.string,
    onInputValueChange: PropTypes.func,
    ignoreChecked: PropTypes.bool,
    onChangeIgnore: PropTypes.func,
    ignoreDisabled: PropTypes.bool
}

export default LayoutColumn;