// packages
import PropTypes from 'prop-types';
import {useHistory} from "react-router-dom";

// styles

// components
import BlueButton from "../../BlueButton/BlueButton";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'

function TransformationModal({ show, onHide, link, onAnotherClick }) {
    const history = useHistory();

    const handleClick = () => {
        onAnotherClick();
    }

    const goToProcess = () => {
        history.push(`/processes/${link}`);
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop='static'
            keyboard='false'
            centered={true}
        >
            <Modal.Header>
                File Transformation
            </Modal.Header>
            <Modal.Body>
                { 
                    link ?
                        <div className='d-flex flex-column align-items-start'>
                            <span className='text-dark'>
                                Process Id:
                            </span>
                            <button className='btn btn-link ml-2 h-100' onClick={goToProcess}>
                                {link}
                            </button>
                        </div>
                        :
                        <Spinner animation='border' />
                }
            </Modal.Body>
            <Modal.Footer>
                <BlueButton
                    onClick={handleClick}
                    title='Convert Another File'
                    large={false}
                    disabled={link === null || link === undefined}
                />
            </Modal.Footer>
        </Modal>
    );
}

TransformationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    link: PropTypes.string,
    onAnotherClick: PropTypes.func.isRequired
}

export default TransformationModal;