// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';

// Styles
import './NewFolderModal.css';

// Graphic Components
import TextInputComponent from "../../TextInputComponent/TextInputComponent";
import BlueButton from "../../BlueButton/BlueButton";
import Modal from "react-bootstrap/Modal";

function NewFolderModal({ onCloseBtnClick, onCreateBtnClick, show, onHide }) {
    const [ newFolderName, setNewFolderName ] = useState('');

    const handleChange = (event) => {
        setNewFolderName(event.target.value);
    };

    const handleCreateButtonClick = () => {
        onCreateBtnClick(newFolderName);
        setNewFolderName('');
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
        >
            <Modal.Header closeButton>
                New Folder
            </Modal.Header>
            <Modal.Body>
                <TextInputComponent
                    inputType='text'
                    title='New Folder Name'
                    onChange={handleChange}
                    inputName='newFolderName'
                    inputValue={newFolderName}
                />
            </Modal.Body>
            <Modal.Footer>
                <BlueButton
                    onClick={handleCreateButtonClick}
                    title={'Create'}
                    large={false}
                    disabled={newFolderName === ''}
                />
            </Modal.Footer>
        </Modal>
    );
}

NewFolderModal.propTypes = {
    onCloseBtnClick: PropTypes.func.isRequired,
    onCreateBtnClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}

export default NewFolderModal;