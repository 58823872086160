// packages
import PropTypes from 'prop-types';

// styles
import '../../../TransformationProcess.css';

// services
// graphic components
import TextInputComponent from '../../../../../Reusable/TextInputComponent/TextInputComponent';

function LayoutGoParams({ layout, fields, goInput, onGoInputChange }) {
    return (
        <div className='col-sm-6 p-4 d-flex flex-column align-items-center'>
            <h5 className='processInstructionsTitle mb-5'>
                <strong>LAYOUT PARAMETERS</strong>
            </h5>
            <div className='flex-grow-1 d-flex flex-column px-3 w-75 layoutGoParamsFrame'>
                {
                    goInput && fields.map((field, index) => {
                        switch(field['Format_Type_Dsc']) {
                            case 'Currency':
                                return null;
                            case 'Integer':
                                return null;
                            case 'Double':
                                return null;
                            case 'Date':
                                return (
                                    <TextInputComponent
                                        key={index}
                                        title={String(field['Column_Dsc'])}
                                        inputName={String(field['Column_Id'])}
                                        inputType='date'
                                        inputValue={goInput[String(field['Column_Id'])]}
                                        onChange={onGoInputChange}
                                    />
                                );
                            case 'Text':
                                return (
                                    <TextInputComponent
                                        key={index}
                                        title={String(field['Column_Dsc'])}
                                        inputName={String(field['Column_Id'])}
                                        inputType='text'
                                        inputValue={goInput[String(field['Column_Id'])]}
                                        onChange={onGoInputChange}
                                    />
                                );
                            default:
                                return null;
                        }
                    })
                }
            </div>
        </div>
    );
}

LayoutGoParams.propTypes = {
    layout: PropTypes.number.isRequired,
    onGoInputChange: PropTypes.func.isRequired
}

export default LayoutGoParams;