//Dev
// export const configuration = {
//     auth: {
//         clientId: 'ecfe981f-8cf5-40df-b37d-2e8300b2a3ed',
//         authority: 'https://login.microsoftonline.com/def90d73-f60e-490c-b62c-28e3f4a0293a',
//         postLogoutRedirectUri: window.location.protocol + '//' + window.location.host,
//         redirectUri: window.location.protocol + '//' + window.location.host,
//         // redirectStartPage: window.location.protocol + '//' + window.location.host + '/home',
//         // navigateToLoginRequestUrl: false,
//     },
//     cache: {
//         cacheLocation: "sessionStorage"
//     }
// };

//Prod
export const configuration = {
    auth: {
        clientId: '7437f387-c1cc-4420-a5c2-7689661fa2d9',
        authority: 'https://login.microsoftonline.com/6d9ffa34-d51c-405b-9da5-3cb0b5af760f',
        postLogoutRedirectUri: window.location.protocol + '//' + window.location.host,
        redirectUri: window.location.protocol + '//' + window.location.host,
        // redirectStartPage: window.location.protocol + '//' + window.location.host + '/home',
        // navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage"
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const graphLoginRequest = {
    scopes: ["User.Read"]
};

//Dev
// export const apiLoginRequest = {
//     scopes: ["api://48bcf903-2105-4076-be4d-b926a4240ad6/access_as_user"]
// }

//Prod
export const apiLoginRequest = {
    scopes: ["api://f117c395-a7c8-4ad2-b8a7-c0089b31254c/access_as_user"]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
};