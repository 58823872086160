import axios from "axios"
import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/Data';

const dataService = {

    transformWithLayout: async (info) => {
        let url = null;
        if (info['Extension'] === 'xlsx')
            url = `${mainUrl}/ValidateExcel`;
        else if (info['Extension'] === 'csv')
            url = `${mainUrl}/ValidateCSV`;
        else if (info['Extension'] === 'txt')
            url = `${mainUrl}/ValidateTXT`;

        try {
            let response = await axios.post(url, info);
            return response.data;
        }
        catch (e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    getProcessLogs: async (runId, token) => {
        let url = `${mainUrl}/Logs?RunId=${runId}`;
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        try {
            let res = await axios.get(url, config);
            return res.data;
        } catch (e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    getUserLogs: async (userId, token) => {
        let url = `${mainUrl}/Logs/${userId}`;
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        try {
            let res = await axios.get(url, config);
            return res.data;
        } catch (e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    getUserProcesses: async (userId, token) => {
        let url = `${mainUrl}/Process/${userId}`;
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        try {
            let res = await axios.get(url, config);
            return res.data;
        }
        catch (e) {
            // TODO - log error to server
            console.log(e);
            return [];
        }
    }
}

export default dataService;