// Packages
import PropTypes from 'prop-types'

// Styles
import './CloseButton.css'

function CloseButton({ onClick }) {
    return (
        <div className='closeButtonDiv' onClick={ onClick }></div>
    );
}

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default CloseButton;