import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import "./LoaderSpinner.css";

const LoaderSpinner = (props) => {
  const { promiseInProgress } = usePromiseTracker({area: props.area});

  return (
    promiseInProgress && (
      <div className="spinner-overlay">
        <div className="spinner">
          <Loader type="Rings" color={props.color} height={100} width={100} />
        </div>
      </div>
    )
  );
};

export default LoaderSpinner;