// Packages
import { useRef, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { InteractionType } from "@azure/msal-browser";

// Styles
import './FilesComponent.css';

// Services
import filesService from "../../../Services/HttpService/FilesService";
import userService from "../../../Services/HttpService/UserService";
import { newFile, newFolder } from "../../../Services/Helpers/filesHelper";
import * as BlobStorageService from '../../../Services/Other/BlobStorageService';

// React Components
import {MsalAuthenticationTemplate, useMsal} from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { Redirect } from 'react-router-dom';
import MainFrame from '../../Reusable/MainFrame/MainFrame';
import ItemThing from '../../Reusable/ItemThing/ItemThing';
import NewFolderModal from '../../Reusable/CustomModal/NewFolderModal/NewFolderModal';
import FileInfoModal from '../../Reusable/CustomModal/FileInfoModal/FileInfoModal';
import FileContextMenu from '../../Reusable/CustomContextMenu/FileContextMenu/FileContextMenu';
import RenameModal from '../../Reusable/CustomModal/RenameModal/RenameModal';
import FolderInfoModal from '../../Reusable/CustomModal/FolderInfoModal/FolderInfoModal';
import ConfirmationModal from '../../Reusable/CustomModal/ConfirmationModal/ConfirmationModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ItemThingHorizontal from '../../Reusable/ItemThingHorizontal/ItemThingHorizontal';
import StorageInfoBar from '../../Reusable/StorageInfoBar/StorageInfoBar';
import LinksBreadcrumb from '../../Reusable/LinksBreadcrumb/LinksBreadcrumb';
import InformationModal from '../../Reusable/CustomModal/InformationModal/InformationModal';
import RepeatedFileModal from '../../Reusable/CustomModal/RepeatedFileModal/RepeatedFileModal';
import RepeatedFileRenameModal from '../../Reusable/CustomModal/RepeatedFileRenameModal/RepeatedFileRenameModal';
import MainFrameAlt from '../../Reusable/MainFrameAlt/MainFrameAlt';
import { apiLoginRequest } from '../../../authConfig';

function FileComponentContent({ showContextMenu, setShowContextMenu, asyncFileUpload, fileExtensions }) {
    const fileInputRef = useRef(null);
    const [ showNewFolderModal, setShowNewFolderModal ] = useState(false);
    const [ showFileInfoModal, setShowFileInfoModal ] = useState(false);
    const [ showFolderInfoModal, setShowFolderInfoModal ] = useState(false);
    const [ showRenameModal, setShowRenameModal ] = useState(false);
    const [ showDeleteConfirmationModal, setShowDeleteConfirmationModal ] = useState(false);
    const [ showRepeatedFolderModal, setShowRepeatedFolderModal ] = useState(false);
    const [ showRepeatedFileModal, setShowRepeatedFileModal ] = useState(false);
    const [ contextFile, setContextFile ] = useState(null);
    const [ currentContext, setCurrentContext ] = useState([]);
    const [ defaultContext, setDefaultcontext ] = useState([]);
    const [ treeContext, setTreeContext ] = useState([]);
    const [ cachedTreeContext, setCachedTreeContext ] = useState([]);
    const [ ancestors, setAncestors ] = useState([]);
    const [ parentId, setParentId ] = useState(null);
    const [ lookUpInput, setLookUpInput ] = useState('');
    const [ selectedNode, setSelectedNode ] = useState(null);
    const [ selectedNodes, setSelectedNodes ] = useState([]);
    const [ uploadFileEvent, setUploadEvent ] = useState(null);
    const [ uploadFileName, setUploadFileName ] = useState('');
    const [ deleteFileEvent, setDeleteFileEvent ] = useState(null);
    const [ storageInfo, setStorageInfo ] = useState(null);
    const [ navItems, setNavItems ] = useState([
        {
            name: "My Files",
            id: null,
            active: true
        }
    ]);
    const menu = useSelector(state => state.menu.selectedMenu);
    const systemId = useSelector(state => state.systems.selectedSystem);
    const systemsList = useSelector(state => state.systems.systemsList);
    const { instance, accounts, inProgress } = useMsal();
    const [ azureToken, setAzureToken ] = useState('');

    useEffect(() => {
        getAzureToken();
    }, []);

    useEffect(() => {
        getAzureToken();
    }, [ instance, accounts, inProgress ]);

    useEffect(() => {
        loadContent();
        //getStorageInfo();
    }, [ parentId, systemId ] )

    const getAzureToken = () => {
        if (inProgress === 'none' && accounts.length > 0) {
            instance.acquireTokenSilent({
                account: accounts[0],
                ...apiLoginRequest
            }).then(res => {
                setAzureToken(res.accessToken);
            })
        }
    }

    const onNavItemClick = (index) => {
        let localAncestors = ancestors.slice(0, index+1);
        console.log(localAncestors);
        let up = localAncestors.pop();
        console.log(up);
        goToNavItem(index);
        setAncestors(localAncestors);
        setParentId(up);
    }

    const addNavItem = (name, id) => {
        navItems.forEach(item => {
            item.active = false
        });
        setNavItems(
            [
                ...navItems,
                {
                    name: name,
                    id: id,
                    active: true
                }
            ]
        )
    }

    const goToNavItem = (index) => {
        let items = navItems.slice(0, index+1);
        if(items[items.length-1]) items[items.length-1].active = true;
        setNavItems(items)
    }

    const clickFileInput = () => {
        fileInputRef.current.click();
    };

    const loadContent = () => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let nodes = await filesService.getNodes(systemId, parentId, authResponse.accessToken);
                if (parentId || parentId === 0)
                    nodes.unshift({ Id: ancestors[ancestors.length - 1], Name: '...', Item_Type: 'Folder', Visible: true });
                
                if(fileExtensions != null){
                    let extensions = fileExtensions.split(',').map(item => item.slice(1));
                    nodes = nodes.filter(item => item.Item_Type === 'Folder' || extensions.includes(item.Ext.toLowerCase()) );
                }

                setCurrentContext(nodes);
                setDefaultcontext(nodes);

                let info = await userService.getStorageInfo(systemId, authResponse.accessToken);
                setStorageInfo(prettifyStorageInfo(info));

                let tree = await filesService.getLookUpTree(systemId, parentId, authResponse.accessToken);
                setTreeContext(tree);
                setCachedTreeContext(tree);
            }
        })();
    };

    const localUploadFile = (event) => {
        const localName = event.target.files[0].name.split('.')[0];
        if(checkItemCoincidence(localName, 'File')){
            setUploadEvent(event);
            setUploadFileName(localName);
            setShowRepeatedFileModal(true);
        }else{
            asyncFileUpload(event, parentId, loadContent, onUploadComplete, menu.Menu_Id);
        }
    }

    const checkItemCoincidence = (fileName, type) => {
        return currentContext.findIndex(x => x.Name === fileName && x.Item_Type === type) != -1;
    }

    const checkNumberOfItemCoincidences = (fileName, type) => {
        return currentContext.filter(x => x.Name === fileName && x.Item_Type === type).length;
    }

    const onUploadComplete = (parent_Id, newFileId) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0 && parent_Id == parentId){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let fileInfo = await filesService.getFileInfo(newFileId, authResponse.accessToken);

                fileInfo.Visible = true;
                fileInfo.File_Path = newFileId + '.' + fileInfo.File_Type_Ext;
                await filesService.updateFile(newFileId, fileInfo, authResponse.accessToken);
                
                let nodes = await filesService.getNodes(systemId, parentId, authResponse.accessToken);
                if (parentId || parentId === 0)
                    nodes.unshift({ ID: ancestors[ancestors.length - 1], Name: '...', Item_Type: 'Folder', Visible: true });

                for(let node of nodes){
                    if(node.Id == newFileId){
                        node.lastUploaded = true;
                    }
                }
                setCurrentContext(nodes);
                setDefaultcontext(nodes);

                let info = await userService.getStorageInfo(systemId, authResponse.accessToken);
                setStorageInfo(prettifyStorageInfo(info));

                let tree = await filesService.getLookUpTree(systemId, parentId, authResponse.accessToken);
                setTreeContext(tree);
                setCachedTreeContext(tree);
            }
        })();
    }

    const prettifyStorageInfo = (info) => {
        if(info){
            info.Percentage_Used = Math.round((info.Storage_Used/info.Max_Storage)*100);
            info.Storage_Used = (info.Storage_Used / 1024);
            info.Storage_Available = (info.Storage_Available / 1024);
            info.Storage_Used = info.Storage_Used < 1024 ? info.Storage_Used.toFixed(2) + ' MB' : (info.Storage_Used / 1024).toFixed(2) + ' GB';
            info.Storage_Available = info.Storage_Available < 1024 ? info.Storage_Available.toFixed(2) + ' MB' : (info.Storage_Available / 1024).toFixed(2) + ' GB';
            if(info.Percentage_Used > 80) info.Is_Running_Out = true;
            return info
        }
        return null;
    }

    const createNewFolder = (folderName) => {
        if(checkItemCoincidence(folderName, 'Folder')){
            setShowRepeatedFolderModal(true);
            return;
        }
        const folderInfo = newFolder(folderName, systemId, menu.Menu_Id, parentId);
        filesService.createNewFolder(folderInfo, azureToken);

        setShowNewFolderModal(false);
        setTimeout(() => {
            loadContent();
        }, 1000)
    }

    const toggleFolderModal = (val) => {
        setShowNewFolderModal(val);
    };

    const toggleFileInfoModal = (val) => {
        setShowFileInfoModal(val);
    }

    const toggleFolderInfoModal = (val) => {
        setShowFolderInfoModal(val);
    }

    const toggleRenameModal = (val) => {
        setShowRenameModal(val);
    }

    const toggleDeleteConfirmationModal = (val) => {
        setShowDeleteConfirmationModal(val);
    }

    const toggleRepeatedFolderModal = (val) => {
        setShowRepeatedFolderModal(val);
    }

    const toggleRepeatedFileModal = (val) => {
        setShowRepeatedFileModal(val);
    }

    const triggerFileInfoModal = (fileId, type) => {
        setSelectedNode({ id: fileId, type: type });
        if(fileId === -1) return;
        if(type === "File"){
            setShowFileInfoModal(true);
        }else if (type === "Folder"){
            setShowFolderInfoModal(true);
        }
    }

    const triggerRenameModal = (id, type) => {
        setShowRenameModal(true);
        setSelectedNode({id: id, type: type});
    }

    const handleNodeInteraction = (node) => {
        if (node.Item_Type === 'Folder') {
            if (node.Name === '...') {
                let localAncestors = ancestors.slice();
                let up = localAncestors.pop();
                goToNavItem(navItems.length-2);
                setAncestors(localAncestors);
                setParentId(up);
            } else {
                let localAncestors = ancestors.slice();
                localAncestors.push(parentId);
                setAncestors(localAncestors);
                setParentId(node.Id);
                addNavItem(node.Name, node.Id);
            }
        } else {

        }
    }

    const handleChangeLookUpInput = (evt) => {
        setLookUpInput(prev => {
            const value = evt.target.value.toLowerCase();
            const more = value.length > lookUpInput.length;

            if (value) {
                let tempContext;
                if (more) {
                    if (prev === '')
                        tempContext = [ ...treeContext ];
                    else
                        tempContext = [ ...currentContext ];
                }
                else tempContext = [ ...cachedTreeContext ];
                tempContext = tempContext.filter(node => node.Name.toLowerCase().includes(value));
                setCurrentContext(tempContext);
            } else {
                let context = [ ...defaultContext ];
                setCurrentContext(context);
            }

            return value;
        });
    }

    const handleDragOver = (evt, type) => {
        if (type === 'Folder') {
            evt.preventDefault();
        }
    }

    const handleDragEnter = (evt, type) => {
        if (type === 'Folder') {
            evt.preventDefault();
        }
    }

    const handleDeleteDrop = evt => {
        const node = JSON.parse(evt.dataTransfer.getData('text/plain'));
        console.log(node);
        if (!node) return;
        setDeleteFileEvent({
            ...node,
            ID: node.Id,
            type: node.Item_Type 
        });
        setShowDeleteConfirmationModal(true);

        // if (node.Item_Type === 'Folder') {
        //     (async () => {
        //         await filesService.deleteFolderRef(node.Id, azureToken);
        //         loadContent();
        //     })();
        // } else if (node.Item_Type === 'File') {
        //     (async () => {
        //         await filesService.deleteFileRef(node.Id, azureToken);
        //         loadContent();
        //     })();
        // }

        evt.preventDefault();
    }

    const handleDeleteItem = (fileInfo) => {
        if (!fileInfo) return;
        setDeleteFileEvent({
            ...fileInfo
        });
        setShowDeleteConfirmationModal(true);
    }

    const handleChangeReferenceDrop = (evt, parentId) => {
        const node = JSON.parse(evt.dataTransfer.getData('text/plain'));
        if (!node) return;

        if (node.Item_Type === 'Folder') {
            (async () => {
                let folderInfo = await filesService.getFolderInfo(node.Id, azureToken);
                folderInfo = {
                    ...folderInfo,
                    Folder_Id: node.Id,
                    Parent_Id: parentId
                }
                await filesService.updateFolder(folderInfo, azureToken);
                loadContent();
            })();
        } else if (node.Item_Type === 'File') {
            (async() => {
                let fileInfo = await filesService.getFileInfo(node.Id, azureToken);
                console.log(fileInfo);
                fileInfo = {
                    ...fileInfo,
                    Folder_Id: parentId,
                    Visible: true
                }
                await filesService.updateFile(node.Id, fileInfo, azureToken);
                loadContent();
            })();
        }

        evt.preventDefault();
    }

    const handleDragStart = (evt, node) => {
        const data = JSON.stringify(node);
        const format = 'text/plain';
        evt.dataTransfer.setData(format, data);
    }

    const handleRightClick = (evt, node) => {
        evt.preventDefault();

        if(node.Name == '...') return;

        const fileInfo = {
            x: evt.clientX,
            y: evt.clientY,
            ID: node.Id,
            type: node.Item_Type,
            lock: node.System_Lock
        }

        setContextFile(fileInfo);
        setShowContextMenu(true);
    }

    const handleDoubleClick = (node) => {
        let info = {
            ID: node.Id,
            type: node.Item_Type,
        }
        openFile(info);
    }

    const handleDeleteAction = (fileNode) => {
        
        setDeleteFileEvent(fileNode);
        setShowDeleteConfirmationModal(true);
    }

    const handleDeleteConfirmation = () => {
        let event = {...deleteFileEvent};
        console.log(event);
        deleteItem(event);
        setDeleteFileEvent(null);
        setShowDeleteConfirmationModal(false);
    }

    const handleCancelRepeatedFile = () => {
        setShowRepeatedFileModal(false);
    }

    const handleAcceptReplaceFile = (newFileName) => {
        if(!checkItemCoincidence(newFileName, 'File')){
            const fileUploadEvent = {...uploadFileEvent};
            setUploadEvent(null);
            setUploadFileName('');
            asyncFileUpload(fileUploadEvent, parentId, loadContent, onUploadComplete, menu.Menu_Id, newFileName);
            setShowRepeatedFileModal(false);
        }
    }

    const handleReplaceRepeatedFile = () => {

    }

    const handleKeepRepeatedFile = () => {
        const fileUploadEvent = {...uploadFileEvent};
        // const localName = fileUploadEvent.target.files[0].name.split('.')[0] + '(copy)';
        // const ext = fileUploadEvent.target.files[0].name.split('.')[1];
        // fileUploadEvent.target.files[0].name = localName+ext;
        setUploadEvent(null);
        asyncFileUpload(fileUploadEvent, parentId, loadContent, onUploadComplete, menu.Menu_Id, true);
    }

    const deleteItem = (fileInfo) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const type = fileInfo.type;

                if (type === 'Folder') {
                    await filesService.deleteFolderRef(fileInfo.ID, authResponse.accessToken);
                    loadContent();
                } else if (type === 'File') {
                    await filesService.deleteFileRef(fileInfo.ID, authResponse.accessToken);
                    loadContent();
                }
            }
        })();
    }

    const downloadFile = (node) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const type = node.type;

                if (type === 'File') {
                    let fileInfo = await filesService.getFileInfo(node.ID, authResponse.accessToken);
                    console.log(fileInfo);
                    const storageFileName = fileInfo.File_Path;
                    const localSystemsList = [ ...systemsList ];
                    const localSystem = localSystemsList.filter(sys => sys["System_Id"] === systemId);
                    const container = localSystem[0]["Container"];                    
                    try{
                        let download = await BlobStorageService.downloadFile(container,storageFileName);
                        let downloadBody = await download.blobBody;
                        downloadBlob(downloadBody, fileInfo.File_Name+'.'+fileInfo.File_Type_Ext);
                    }
                    catch(err){
                        console.log(err);
                    }
                    
                }
            }
        })();
    }

    const openFile = (node) => {
        console.log(node);
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const type = node.type;

                if (type === 'File') {
                    let fileInfo = await filesService.getFileInfo(node.ID, authResponse.accessToken);
                    const storageFileName = node.ID + '.' + fileInfo.File_Type_Ext;
                    const localSystemsList = [ ...systemsList ];
                    const localSystem = localSystemsList.filter(sys => sys["System_Id"] === systemId);
                    const container = localSystem[0]["Container"];                    
                    try{
                        let downloadUrl = await BlobStorageService.getFileUrl(container,storageFileName);
                        downloadURI(downloadUrl, fileInfo.File_Name);
                    }
                    catch(err){
                        console.log(err);
                    }
                    
                }
            }
        })();
    }

    function downloadBlob(blob, name){
        var blobUrl = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = blobUrl;
        link.download = name;
        link.target = '_blank'
        link.click();
    }

    function downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link.target = '_blank'
        link.click();
     }

    const getNodeType = (node) => {
        if(node.Item_Type === 'Folder'){
            if(node.Name === '...') return 'folder-std';
            if(node.System_Lock) return 'folder-sys'; 
            else return 'folder';
        }
        return 'file';
    }

    return (
        <div className="flex-grow-1 container-fluid d-flex flex-column w-100" onClick={() => setShowContextMenu(false)}>
            <div className="flex-grow-1 container-fluid d-flex flex-column px-5 pt-4 pb-3">
                <div className="row" >
                    <div className='col-6'>
                        <h6 className="mb-4 filesActionsTitle"><strong>ACTIONS</strong></h6>
                    </div>
                    <div className="col-6 w-100 mb-3">
                        <div className="col-md-6 float-right d-flex filesSearchBar">
                            <input
                                type="text"
                                className="filesSearchBarInput mr-2"
                                value={lookUpInput}
                                onChange={handleChangeLookUpInput}
                            />
                            <div className="d-flex justify-content-center align-items-center filesSearchBarIcon">
                                <FontAwesomeIcon icon={faSearch} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100">
                    <div className="d-flex align-items-center justify-content-start filesComponentActionsBox mb-3">
                        <div className="card py-2 px-3 mr-4">
                            <ItemThingHorizontal
                                title="New Folder"
                                type="folder"
                                policy="itemThingGrayHover"
                                titleSpace="mt-1"
                                measures={{width: "1.2em"}}
                                handleClick={() => toggleFolderModal(true)}
                            />
                        </div>
                        <div className="card py-2 px-3 mr-4">
                            <ItemThingHorizontal
                                title="Upload"
                                type="upload"
                                policy="itemThingGrayHover"
                                titleSpace="mt-1"
                                measures={{width: "1.2em"}}
                                handleClick={clickFileInput}
                            />
                        </div>
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id={`tooltip-delete`}>
                                    Drag elements to this icon to delete them.
                                </Tooltip>
                            }
                            >
                            <div className="card py-2 px-3 mr-4"
                                onDragOver={(evt) => evt.preventDefault()}
                                onDragEnter={(evt) => evt.preventDefault()}
                                onDrop={handleDeleteDrop}
                            >
                                <ItemThingHorizontal
                                    title="Delete"
                                    type="delete"
                                    policy="itemThingGrayHover"
                                    titleSpace="mt-1"
                                    measures={{width: "1.2em"}}
                                />
                            </div>
                        </OverlayTrigger>
                        <div className="card py-2 px-3 mr-4">
                            <ItemThingHorizontal
                                title="Refresh"
                                type="refresh"
                                policy="itemThingGrayHover"
                                titleSpace="mt-1"
                                measures={{width: "1.2em"}}
                                handleClick={() => loadContent()}
                            />
                        </div>

                    </div>
                    <LinksBreadcrumb items={navItems} onClick={onNavItemClick} styleClass="small-nav-breadcrumb"/>
                    <div className="d-flex flex-wrap py-3 filesComponentNodesFrame bg-light">
                        {
                            currentContext ?
                                currentContext.map((node, index) => {
                                    return(
                                        node.Visible &&
                                        <div
                                            className="col-sm-3 my-4"
                                            key={index + node.Id}
                                            draggable={true}
                                            onDragOver={(evt) => handleDragOver(evt, node.Item_Type)}
                                            onDragEnter={(evt) => handleDragEnter(evt, node.Item_Type)}
                                            onDrop={(evt) => handleChangeReferenceDrop(evt, node.Id)}
                                            onDragStart={(evt) => handleDragStart(evt, node)}
                                        >
                                            <ItemThing
                                                title={`${node.Name}${node.Item_Type === 'Folder' ? "" : '.'+node.Ext}`}
                                                type={getNodeType(node)}
                                                measures={{ width: "3em", height: "3em" }}
                                                policy={`itemThingYellowFocus itemThingYellowHover ${node.Name === "..." ? 'itemThingLighter' : ''}`}
                                                itemStyleClasses={node.lastUploaded ? 'border border-warning' : ''}
                                                handleClick={() => handleNodeInteraction(node)}
                                                handleDoubleClick={() => handleDoubleClick(node)}
                                                handleContextMenu={(evt) => handleRightClick(evt, node)}
                                            />
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>
                </div>
                {storageInfo && (
                    <StorageInfoBar storageInfo={storageInfo} />
                )}
                

                <input 
                    type="file" name="file" hidden ref={fileInputRef} 
                    onChange={evt => localUploadFile(evt)} 
                    onClick={(event)=> { 
                        event.target.value = null
                    }}
                />
            </div>
            <NewFolderModal
                show={showNewFolderModal}
                onHide={() => toggleFolderModal(false)}
                onCloseBtnClick={ () => toggleFolderModal(false) }
                onCreateBtnClick={createNewFolder}
            />
            <FileInfoModal
                show={showFileInfoModal}
                onHide={() => toggleFileInfoModal(false)}
                node={selectedNode}
                loadContent={loadContent}
            />
            <FolderInfoModal
                show={showFolderInfoModal}
                onHide={() => toggleFolderInfoModal(false)}
                node={selectedNode}
                loadContent={loadContent}
            />
            <RenameModal
                loadContent={loadContent}
                onHide={() => toggleRenameModal(false)}
                show={showRenameModal}
                node={selectedNode}
            />
            <ConfirmationModal 
                show={showDeleteConfirmationModal}
                onHide={() => toggleDeleteConfirmationModal(false)}
                onAcceptBtnClick={handleDeleteConfirmation}
                onCancelBtnClick={() => toggleDeleteConfirmationModal(false)}
                title='Delete item'
                text="Are you sure that you want to delete this item? This action can't be reversed."
            />
            <InformationModal
                show={showRepeatedFolderModal}
                onHide={() => toggleRepeatedFolderModal(false)}
                onAcceptBtnClick={() => toggleRepeatedFolderModal(false)}
                title='Repeated folder'
                text="A folder with this name already exists inside this folder, please choose a different name."
            />
            {/* <InformationModal
                show={showRepeatedFileModal}
                onHide={() => toggleRepeatedFileModal(false)}
                onAcceptBtnClick={() => toggleRepeatedFileModal(false)}
                title='Repeated file'
                text="A file with this name already exists inside this folder, please upload the file with a different name."
            /> */}
            <RepeatedFileRenameModal
                show={showRepeatedFileModal}
                onHide={handleCancelRepeatedFile}
                fileName={uploadFileName}
                onAcceptBtnClick={handleAcceptReplaceFile}
                onCancelBtnClick={handleCancelRepeatedFile}
            />
            {
                showContextMenu &&
                <FileContextMenu
                    fileInfo={contextFile}
                    loadContent={loadContent}
                    deleteAction={handleDeleteItem}
                    downloadAction={downloadFile}
                    openFileAction={openFile}
                    triggerInfoModal={triggerFileInfoModal}
                    triggerRenameModal={triggerRenameModal}
                />
            }
        </div>
    );
}

function FilesComponent({ asyncFileUpload, fileExtensions=null }) {
    const systemId = useSelector(state => state.systems.selectedSystem);
    const [ showContextMenu, setShowContextMenu ] = useState(false);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {
                systemId === -1 ?
                    <Redirect to='/' />
                    :
                    <div onClick={() => setShowContextMenu(false)}>
                        <MainFrameAlt
                            topStyle="align-items-center"
                            title="MY FILES"
                            content={
                                <FileComponentContent
                                    showContextMenu={showContextMenu}
                                    setShowContextMenu={setShowContextMenu}
                                    asyncFileUpload={asyncFileUpload}
                                    fileExtensions={fileExtensions}
                                />
                            }
                        />
                        
                    </div>
            }
        </MsalAuthenticationTemplate>
    );
}

export default FilesComponent;