// packages
import PropTypes from 'prop-types';
import { useState, useEffect } from "react";

// styles

// services
import layoutsService from "../../../../Services/HttpService/LayoutService";

// components
import Modal from "react-bootstrap/Modal";
import BlueButton from "../../BlueButton/BlueButton";
import TextInputComponent from "../../TextInputComponent/TextInputComponent";
import SwitchComponent from "../../SwitchComponent/SwitchComponent";

function LayoutParamsModal({ show, onHide, onTrigger, layoutId }) {
    const [ removeRows, setRemoveRows ] = useState(0);
    const [ removeColumns, setRemoveColumns ] = useState(0);
    const [ hasHeaders, setHasHeaders ] = useState(true);
    const [ pivotRow, setPivotRow ] = useState( -1);

    useEffect(() => {
        (async () => {
            if (!layoutId)
                return;

            let params = await layoutsService.getLayoutDefaultParameterValues(layoutId);
            if (!params)
                return;

            setRemoveRows(params.RemoveRow);
            setRemoveColumns(params.RemoveColumn);
            setHasHeaders(params.HasHeader);
            setPivotRow(params.PivotRow);
        })();
    }, [layoutId]);

    const handleChange = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;

        switch(name) {
            case 'removeRowsInput':
                setRemoveRows(value);
                break;
            case 'removeColumnsInput':
                setRemoveColumns(value);
                break;
            case 'pivotRowInput':
                setPivotRow(value);
                break;
            default:
                break;
        }
    }

    const handleHasHeaderChange = (nextValue) => {
        setHasHeaders(nextValue);
    }

    const triggerTransformation = () => {
        if (removeRows < 0 || (!removeRows && removeRows !== 0)) {
            // TODO - alert over form
            return;
        }

        if (removeColumns < 0 || (!removeColumns && removeColumns !== 0)) {
            // TODO - alert over form
            return;
        }

        onTrigger(removeRows, removeColumns, hasHeaders);
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop='static'
            keyboard='false'
            centered={true}
        >
            <Modal.Header closeButton>
                Layout General Parameters
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column'>
                    <TextInputComponent
                        title='Remove Rows'
                        inputName='removeRowsInput'
                        inputType='number'
                        inputValue={removeRows}
                        onChange={handleChange}
                    />
                    <TextInputComponent
                        title='Remove Columns'
                        inputName='removeColumnsInput'
                        inputType='number'
                        inputValue={removeColumns}
                        onChange={handleChange}
                    />
                    <SwitchComponent
                        onChange={handleHasHeaderChange}
                        title='Has Headers'
                        checked={hasHeaders}
                        onColor='#004c84'
                    />
                    <TextInputComponent
                        title='Pivot Row'
                        inputName='pivotRowInput'
                        inputType='number'
                        inputValue={pivotRow}
                        onChange={handleChange} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <BlueButton
                    onClick={triggerTransformation}
                    title='Begin Process'
                    large={false}
                    disabled={false}
                />
            </Modal.Footer>
        </Modal>
    );
}

LayoutParamsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onTrigger: PropTypes.func.isRequired,
    layoutId: PropTypes.number.isRequired
}

export default LayoutParamsModal;