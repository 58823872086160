// packages
import PropTypes from 'prop-types';

// styles
import './MoveButton.css';

// graphic Components
import Button from 'react-bootstrap/Button';

function MoveButton({ onClick, forward }) {
    return (
        <Button
            onClick={onClick}
            className='rounded-circle backButton'
            size='lg'>
            {forward ? '>' : '<'}
        </Button>
    );
}

MoveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    forward: PropTypes.bool
}

export default MoveButton;