// packages
import {useEffect, useState} from 'react';
import { useSelector } from "react-redux";

// styles
import './RenameModal.css';

// services
import filesService from "../../../../Services/HttpService/FilesService";

// react components
import Modal from 'react-bootstrap/Modal';
import TextInputComponent from '../../TextInputComponent/TextInputComponent';
import BlueButton from "../../BlueButton/BlueButton";
import GrayButton from "../../GrayButton/GrayButton";
import { useMsal } from '@azure/msal-react';
import { apiLoginRequest } from '../../../../authConfig';


function RenameModal({ show, onHide, node, loadContent }) {
    const [ nodeInfo, setNodeInfo ] = useState('');
    const [ nameInputValue, setNameInputValue ] = useState('');
    const { instance, accounts, inProgress } = useMsal();
    const systemId = useSelector(state => state.systems.selectedSystem);

    const handleNameChange = (evt) => {
        const value = evt.target.value;
        setNameInputValue(value);
    }

    useEffect(() => {
        if (!node) return;

        if (node.type === 'File') {
            (async () => {
                if(inProgress === 'none' && accounts.length > 0){
                    let authResponse = await instance.acquireTokenSilent({
                        account: accounts[0],
                        ...apiLoginRequest
                    });
                    let res = await filesService.getFileInfo(node.id, authResponse.accessToken);

                    if (res) {
                        setNameInputValue(res.File_Name);
                        setNodeInfo({
                            ...res,
                            File_Id: node.id
                        });
                    }
                }
            })();
        } else if (node.type === 'Folder') {
            (async () => {
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let res = await filesService.getFolderInfo(node.id, authResponse.accessToken);

                if (res) {
                    setNameInputValue(res.Folder_Name);
                    setNodeInfo({
                        ...res,
                        Folder_Id: node.id
                    });
                }
            })();
        }
    }, [node])

    const changeNodeName = () => {
        if (!nameInputValue) {
            // let user know
            return;
        }

        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                if (node.type === 'Folder') {
                    let folderInfo = {
                        ...nodeInfo,
                        Folder_Name: nameInputValue
                    }
                    await filesService.updateFolder(folderInfo, authResponse.accessToken);
                    onHide();
                    loadContent();
                } else if (node.type === 'File') {
                    let fileInfo = {
                        ...nodeInfo,
                        File_Name: nameInputValue
                    }
                    await filesService.updateFile(node.id, fileInfo, authResponse.accessToken);
                    onHide();
                    loadContent();
                }
            }
            
        })();
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
        >
            {
                node &&
                <Modal.Body>
                    <TextInputComponent
                        title={node.type === 'Folder' ? 'Folder Name' : 'File Name'}
                        inputName='elementNameInput'
                        inputValue={nameInputValue}
                        onChange={handleNameChange}
                    />
                    <div className='row mx-2 d-flex justify-content-end'>
                        <div className='mr-2'>
                            <GrayButton onClick={onHide} title='Cancel' />
                        </div>
                        <BlueButton onClick={changeNodeName} title='Change Name' />
                    </div>
                </Modal.Body>
            }
        </Modal>
    );
}

export default RenameModal;