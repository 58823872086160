// packages
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// styles
import '../../TransformationProcess.css';

// services
// graphic components
import TransformFileUpload from "./Children/TransformFileUpload";
import MoveButton from "../../../../Reusable/MoveButton/MoveButton";
import FileResourceSelector from "./Children/FileResourceSelector";

function FileChooseStage(props) {
    const history = useHistory();

    /*useEffect(() => {
        props.onSelectFile(0);
    }, []);*/

    const handleBackButtonClick = () => {
        navigateBack();
    }

    const navigateBack = () => {
        history.goBack();
    }

    return (
        <div className="flex-grow-1 d-flex flex-column container-fluid my-4">
            <div className="flex-grow-1 row mt-4 mx-3">
                <FileResourceSelector
                    onSelectResource={props.onSelectFile}
                    selectedResource={props.selectedFile}
                    path={props.path}
                />
                <TransformFileUpload
                    nextClick={props.nextClick}
                    onSelectFile={props.onSelectFile}
                    path={props.path}
                />
            </div>
            <div className="d-flex justify-content-start mt-3 mx-3">
                <MoveButton onClick={handleBackButtonClick} />
            </div>
        </div>
    );
}

FileChooseStage.propTypes = {
    onSelectFile: PropTypes.func.isRequired,
    selectedFile: PropTypes.any.isRequired,
    path: PropTypes.string.isRequired
}

export default FileChooseStage;