import { useState } from "react";
import { CardGroup } from "react-bootstrap";
import PreviewCard from "./PreviewCard";

function PreviewGroup(props){

    return (
        <div className="d-flex flex-wrap justify-content-around flex-grow-1 h-100">
            {props.items.map(item => {
                return(
                    <PreviewCard 
                        image={item.image}
                        name={item.name}
                        description={item.description}
                        getChildren={props.getChildren}
                        id={item.id}
                        type={props.itemsType}
                        workbook={item.workbookId}
                        key={item.id}
                        selectItem={props.selectItem}
                        isSelected={props.selectedItems.findIndex(x => x.id == item.id) !== -1}
                    />
                )
            })}
            <div className="filling-empty-space-card"></div>
            <div className="filling-empty-space-card"></div>
            <div className="filling-empty-space-card"></div>
        </div>
    )
}

export default PreviewGroup