import { Pagination } from "react-bootstrap";

function CatalogMasterPagination({ pagesNumber, activePage, movePage }){

    let pages = []

    const handleMovePage = (index) => {
        movePage(index);
    }

    if(pagesNumber > 7){
        
        const start = activePage > 3 ? activePage - 2 : 2;
        const end = activePage < pagesNumber - 3 ? activePage + 2 : pagesNumber - 1;
        // const centralPagesIndexes = [...Array(pagesNumber).keys()].slice(start, end)
        pages.push(<Pagination.Item active={1==activePage} key={1} onClick={() => handleMovePage(1)}>{1}</Pagination.Item>)
        if(activePage > 3) pages.push(<Pagination.Ellipsis key={-2} disabled />)
        for(var i = start; i<=end; i++){
            let index = i;
            let page = <Pagination.Item active={i==activePage} key={i} onClick={() => handleMovePage(index)}>{i}</Pagination.Item>
            pages.push(page)
        }
        if(activePage < pagesNumber - 3) pages.push(<Pagination.Ellipsis key={-1} disabled />)
        pages.push(<Pagination.Item active={pagesNumber==activePage} key={pagesNumber} onClick={() => handleMovePage(pagesNumber)}>{pagesNumber}</Pagination.Item>)
    }else{
        for(var i = 1; i<=pagesNumber; i++){
            let page = <Pagination.Item active={i==activePage} onClick={() => handleMovePage(i)} key={i}>{i}</Pagination.Item>
            pages.push(page)
        }
    }

    return (
        <Pagination>
            <Pagination.First disabled={activePage==1} onClick={() => movePage(1)} />
            <Pagination.Prev disabled={activePage==1} onClick={() => movePage(activePage-1)} />
            {pages}
            <Pagination.Next disabled={activePage==pagesNumber} onClick={() => movePage(activePage+1)} />
            <Pagination.Last disabled={activePage==pagesNumber} onClick={() => movePage(pagesNumber)} />
        </Pagination>
    )
}

export default CatalogMasterPagination