// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';

// Styles
import './CustomInputModal.css';

// Graphic Components
import TextInputComponent from "../../TextInputComponent/TextInputComponent";
import BlueButton from "../../BlueButton/BlueButton";
import GrayButton from "../../GrayButton/GrayButton";
import Modal from "react-bootstrap/Modal";

function CustomInputModal({show, onHide, title, primaryText, secondaryText, acceptBtnText, cancelBtnText, onAcceptBtnClick, onCancelBtnClick}){
    const [ input, setInput ] = useState('');

    const handleChange = (event) => {
        setInput(event.target.value);
    };

    const handleAcceptButtonClick = () => {
        setInput('');
        onAcceptBtnClick(input);
    }

    const handleCancelButtonClick = () => {
        setInput('');
        onCancelBtnClick();
    }

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="inputModal"
        >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h3 className="msg-title">
                        {title}
                    </h3>
                    <p className="msg-body my-2 text-dark">
                        {primaryText}
                    </p>
                    <p className="msg-body my-2 text-muted">
                        {secondaryText}
                    </p>
                    <TextInputComponent
                        inputType='text'
                        onChange={handleChange}
                        inputName='customInput'
                        inputValue={input}
                    />
                    <div className='d-flex justify-content-center'>
                        <GrayButton
                            onClick={handleCancelButtonClick}
                            title={cancelBtnText}
                            large={true} 
                        />
                        <div className='mx-3'></div>
                        <BlueButton
                            onClick={handleAcceptButtonClick}
                            title={acceptBtnText}
                            large={true}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

CustomInputModal.propTypes = {
    onCancelBtnClick: PropTypes.func.isRequired,
    onAcceptBtnClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    acceptBtnText: PropTypes.string.isRequired,
    cancelBtnText: PropTypes.string
}

export default CustomInputModal