// Packages
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// Styles
import './RepeatedFileRenameModal.css';

// Graphic Components
import BlueButton from "../../BlueButton/BlueButton";
import TextInputComponent from '../../TextInputComponent/TextInputComponent';
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';

function RepeatedFileRenameModal({show, onHide, fileName, onAcceptBtnClick, onCancelBtnClick}){
    const [ nameInputValue, setNameInputValue ] = useState(fileName);

    useEffect(() => {
        setNameInputValue(fileName)
    },[fileName]);

    const handleNameChange = (evt) => {
        const value = evt.target.value;
        setNameInputValue(value);
    }

    const handleAcceptBtnClick = () => {
        onAcceptBtnClick(nameInputValue);
    }

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="repeatedFileModal"
        >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h3 className="msg-title">
                        Action needed
                    </h3>
                    <p className="msg-body my-4 text-dark">
                        A file with the same name already exists in this folder. Please choose a different name to save this file.
                    </p>
                    <TextInputComponent
                        title='File Name'
                        inputName='elementNameInput'
                        inputValue={nameInputValue}
                        onChange={handleNameChange}
                    />
                    <div className='row mx-2 d-flex justify-content-end'>
                        <BlueButton
                            onClick={handleAcceptBtnClick}
                            title={'Accept'}
                            large={true}
                        />
                        <Button 
                            onClick={onCancelBtnClick}
                            size={'lg'}
                        >Cancel</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

RepeatedFileRenameModal.propTypes = {
    fileName: PropTypes.string.isRequired,
    onAcceptBtnClick: PropTypes.func.isRequired,
    onCancelBtnClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}

export default RepeatedFileRenameModal