// Packages
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useParams, useHistory, useLocation } from "react-router-dom";
import { setSelectedMenu } from "../../../Redux/Reducers/menuSlice";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { filterName } from "../../../Services/Helpers/userHelpers";

// Styles
import './Home.css'

// Services
import userService from "../../../Services/HttpService/UserService";

// Graphic Components
import ItemThing from "../../Reusable/ItemThing/ItemThing";
import MainFrame from "../../Reusable/MainFrame/MainFrame";
import { useRouteMatch } from "react-router-dom";
import { apiLoginRequest } from "../../../authConfig";

function HomeContent() {
    const [ menuItems, setMenuItems ] = useState([]);
    const [ userName, setUserName ] = useState('');
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);
    const userSystems = useSelector((state) => state.systems.systemsList);
    const [ currentSystem, setCurrentSystem ] = useState("");
    const history = useHistory();
    const location = useLocation();
    const { path, url } = useRouteMatch();
    const { menuId } = useParams();
    const dispatch = useDispatch();
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        if(menuId) getItems(menuId);
        else getItems(null);
    }, [location, selectedSystem, accounts]);

    const getItems = (parentId) => {
        console.log(parentId);
        ( async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const userId = accounts[0].username.replace('.com', '');

                let availableMenus = await userService.getAvailableMenus(userId, selectedSystem, parentId, authResult.accessToken)
                setMenuItems(availableMenus);

                const localUserName = filterName(accounts[0].name);
                setUserName(localUserName);
            }
        })();
    }

    useEffect(() => {
        const temp = userSystems.filter(sys => sys.System_Id === selectedSystem);
        if (temp[0]) {
            setCurrentSystem(temp[0]["System_Dsc"]);
        }
    }, [ selectedSystem, userSystems ])

    const handleClick = (menuItem) => {
        if(!menuItem.Url){
            // getItems(menuItem.Menu_Id)
            dispatch(setSelectedMenu(menuItem));
            history.push(`${url}/${menuItem.Menu_Id}`);
        }else{
            dispatch(setSelectedMenu(menuItem));
            history.push(menuItem.Url);
        }
    }

    return (
        <>
            <div className="position-absolute w-100 homeUserFrame d-flex flex-column align-items-center">
                <div className="d-flex flex-column justify-content-center text-center homeUserBox px-5 mx-3">
                    <h1 className="homeTitle">WELCOME BACK {userName.toUpperCase()}</h1>
                    {/* <h1 className="homeTitle">WELCOME BACK JOHN SMITH</h1> */}
                    <h3 className="homeSubtitle">{currentSystem.toUpperCase()}</h3>
                </div>
            </div>

            <div className="w-100 position-absolute homeItemsFrame d-flex justify-content-center">
                <div className="w-100 row py-4 justify-content-center">
                    <div className="col-sm-1"></div>
                    {
                        menuItems ? menuItems.sort((a,b) => {return a.Order_M - b.Order_M}).map((item, index) => {
                            let block = 
                                (<div className="col-sm-2 d-flex justify-content-center align-items-center mb-5 mt-4" key={item.Menu_Id}>
                                    <ItemThing
                                        type={item.Menu_Dsc}
                                        title={item.Menu_Dsc}
                                        policy="itemThingYellowHover"
                                        handleClick={ () => handleClick(item) }
                                        titleSpace="mt-3"
                                        measures={{ width: "5em", height: "5em" }}
                                        disabled={!item.Enabled}
                                        // disabled={item.Menu_Dsc !== 'My Files' && item.Menu_Dsc !== 'File Transformation' && item.Menu_Dsc !== 'Configurations' && item.Menu_Dsc !== 'Dashboards' && item.Menu_Dsc !== 'Model Mantainance' && item.Menu_Dsc !== 'Data Puller'}
                                    />
                                </div>)
                            
                            let number = Math.floor(Math.random() * (500 - 100)) + 100;
                            if((index+1)%5===0){
                                return [
                                    block,
                                    <div className="col-sm-1" key={number}></div>,
                                    <div className="col-sm-1" key={number+1}></div>
                                ]
                            }else{
                                return block;
                            }
                        }) : null
                    }
                    <div className="col-sm-1"></div>
                </div>
            </div>

        </>
    );
}

function Home() {
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={apiLoginRequest}>
        {
            selectedSystem === -1 ?
                <Redirect to='/' />
                :
                <MainFrame frameContext='menu' content={ <HomeContent /> } />
        }
        </MsalAuthenticationTemplate>
    );

}

export default Home;