// Packages
import PropTypes from 'prop-types'

// Styles
import './Avatar.css'

// Services

// Graphic Components
import avatar from '../../../Utils/svg_icons/avatar.svg'
// import avatar from '../../../Utils/img/profilepicture-01.png'

function Avatar({ name, url, organization }) {
    return(
        <div className='d-flex flex-column align-items-center'>
            <div>
                <img src={ url || avatar } alt='user avatar default' className='avatar' />
            </div>
            <div className='mt-3'>
                <strong className='avatarTitle'> { name } </strong>
            </div>
            <div>
                <strong className='avatarOrganization'> { organization } </strong>
            </div>
        </div>
    );
}

Avatar.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    organization: PropTypes.string.isRequired
}

export default Avatar;