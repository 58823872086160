// packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import {useSelector} from "react-redux";


import BlueButton from '../../../../Reusable/BlueButton/BlueButton';
import ViewsList from './ViewsList';

function ViewsListContainer() {
    const { path } = useRouteMatch();
    const history = useHistory();

    const handleAddButtonClick = () => {
        history.push(`${path}/add/source`);
    }

    return (
        <div className="flex-grow-1 d-flex flex-column container-fluid my-4">
            <div className='d-flex justify-content-start mt-3'>
                <BlueButton onClick={handleAddButtonClick} title="Add views" />
            </div>
            <div className='flex-grow-1 row mt-4'>
                <ViewsList />
            </div>
        </div>
    )
}

export default ViewsListContainer