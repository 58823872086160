// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';

// Styles
import './ConfirmationModal.css';

// Graphic Components
import BlueButton from "../../BlueButton/BlueButton";
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';

function ConfirmationModal({show, onHide, onAcceptBtnClick, onCancelBtnClick, title, text, acceptBtnText = 'Accept', cancelBtnText='Cancel'}) {

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="confirmationModal"
        >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h3 className="msg-title">
                        {title}
                    </h3>
                    <p className="msg-body my-4 text-dark">
                        {text}
                    </p>
                    <BlueButton
                        onClick={onAcceptBtnClick}
                        title={acceptBtnText}
                        large={true}
                    />
                    <Button 
                        onClick={onCancelBtnClick}
                        size={'lg'}
                    >{cancelBtnText}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

ConfirmationModal.propTypes = {
    onAcceptBtnClick: PropTypes.func.isRequired,
    onCancelBtnClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default ConfirmationModal