// packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useHistory, useRouteMatch } from 'react-router-dom';


import BlueButton from '../../../../Reusable/BlueButton/BlueButton';
import EditRolesSite from './EditRolesSite';

function EditPermissionsContainer(props) {
    const { path } = useRouteMatch();
    const history = useHistory();

    const handleSaveBtnClick = () => {
        
    }

    return (
        <div className="flex-grow-1 d-flex flex-column container-fluid my-4">
            <div className='flex-grow-1 row mt-4'>
                <EditRolesSite path={props.path} />
            </div>
            
        </div>
    )
}

export default EditPermissionsContainer