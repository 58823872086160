// Packages
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from "redux-persist";

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Services
import store from "./Redux/store";

// Graphic Components
import App from './Components/App/App';

let persistAgent = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <Provider store={store} >
            <PersistGate loading={null} persistor={persistAgent} >
                <App />
            </PersistGate>
        </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
