// Packages
import PropTypes from "prop-types";

// Styles
import './Icon.css';

// Graphic Components
import gensa from '../../../Utils/svg_icons/Gensa-logo.svg';
import gensa_sm from '../../../Utils/svg_icons/logogensa.svg'

function Icon(props) {
    let specs = determineLogo(props.specs);

    return (
        <div className='iconWrapper'>
            <img src={specs.logo} className={specs.className} alt={props.specs} onClick={props.handleClick}/>
        </div>
    );
}

Icon.propTypes = {
    specs: PropTypes.string,
    handleClick: PropTypes.func
}

function determineLogo(specs) {

    switch(specs) {
        case 'gensa-white-md':
            return { logo: gensa, className: 'paintWhiteMd' };
        case 'gensa-white-sm':
            return { logo: gensa_sm, className: 'paintWhiteSm' }
        case 'gensa-blue':
            return { logo: gensa, className: 'blueMd' };
        default:
            return { logo: gensa };
    }
}

export default Icon;