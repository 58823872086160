// Packages
import PropTypes from 'prop-types';

// Styles
import './LinkThing.css'

// Graphic Components
import settings from '../../../Utils/svg_icons/settings.svg'
import logout from '../../../Utils/svg_icons/logout-icon.svg'
import about from '../../../Utils/svg_icons/about.svg';
import help from '../../../Utils/svg_icons/help.svg';
import historical from '../../../Utils/svg_icons/time.svg'
import home from '../../../Utils/svg_icons/home.svg'

function LinkThing(props) {

    let icon;

    switch(props.icon) {
        case 'settings':
            icon = settings;
            break;
        case 'logout':
            icon = logout;
            break;
        case 'about':
            icon = about;
            break;
        case 'help':
            icon = help;
            break;
        case 'historical':
            icon = historical;
            break;
        case 'home':
            icon = home;
            break;
        default:
            break;
    }

    return (
        <div onClick={() => props.onClick()}>
            <img src={icon} className='linkThingIcon mr-3' alt={`${props.title} icon`} />
            <label className='linkThingLabel'>{props.title}</label>
        </div>
    );
}

LinkThing.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    icon: PropTypes.string
}

export default LinkThing;