import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/User';
const axios = require('axios').default;
const graphUrl = 'https://graph.microsoft.com/v1.0';

const userService = {

    getUserInfo: async (userId, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            const response = await axios.get(`${mainUrl}/${userId}`,config);
            let data = response.data;

            return data;
        }
        catch (e) {
            // TODO: log failure to server
            console.log(e);
            return [];
        }
    },

    getAvailableSystems: async (userId, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            const response = await axios.get(`${mainUrl}/GetSystems/${userId}`,config);
            let data = response.data;

            return data;
        }
        catch (e) {
            // TODO: log failure to server
            console.log(e);
            return [];
        }
    },

    getAvailableMenus: async (userId, systemId, parentId, token) => {
        try {
            let url;
            if (parentId)
                url = `${mainUrl}/GetMenus/${userId}/${systemId}/${parentId}`
            else
                url = `${mainUrl}/GetMenus/${userId}/${systemId}`;

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            const response = await axios.get(url, config);
            let data = response.data;

            return data;
        }
        catch (e) {
            // TODO: log failure to server
            console.log(e);
        }
    },

    getRoles: async (systemId, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            const response = await axios.get(`${apiMainUrl}/api/System/GetRoles/${systemId}`,config);
            let data = response.data;

            return data;
        }
        catch (e) {
            // TODO: log failure to server
            console.log(e);
            return [];
        }
    },
    getStorageInfo: async (systemId, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            const response = await axios.get(`${apiMainUrl}/api/System/Storage/${systemId}`,config);
            let data = response.data;

            return data;
        }
        catch (e) {
            // TODO: log failure to server
            console.log(e);
            return null;
        }
    },
    getSignedUserPhoto: async (token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                responseType: 'blob'
            }
            const response = await axios.get(`${graphUrl}/me/photo/$value`,config);
            console.log(response);
            return response.data;
        }
        catch(e) {

        }
    }
}

export default userService;