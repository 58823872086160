// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';

// Styles
import './InputModal.css';

// Graphic Components
import TextInputComponent from "../../TextInputComponent/TextInputComponent";
import BlueButton from "../../BlueButton/BlueButton";
import Modal from "react-bootstrap/Modal";

function InputModal({show, onHide, onCreateBtnClick}){
    const [ newFolderName, setNewFolderName ] = useState('');

    const handleChange = (event) => {
        setNewFolderName(event.target.value);
    };

    const handleCreateButtonClick = () => {
        onCreateBtnClick(newFolderName);
    }

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="inputModal"
        >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h3 className="msg-title">
                        Create folder
                    </h3>
                    <p className="msg-body my-4 text-dark">
                        Enter a description for the folder that will contain the workbooks and views you selected.
                    </p>
                    <TextInputComponent
                        inputType='text'
                        title='New Folder Name'
                        onChange={handleChange}
                        inputName='newFolderName'
                        inputValue={newFolderName}
                    />
                    <BlueButton
                        onClick={handleCreateButtonClick}
                        title={'Accept'}
                        large={true}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

InputModal.propTypes = {
    onCloseBtnClick: PropTypes.func.isRequired,
    onCreateBtnClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}

export default InputModal