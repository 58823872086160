// packages
import PropTypes from 'prop-types';

// styles
import './FileContextMenu.css';

// services
import filesService from "../../../../Services/HttpService/FilesService";

// react components

function FileContextMenu({ fileInfo, loadContent, deleteAction, downloadAction, openFileAction, triggerInfoModal, triggerRenameModal }) {

    const deleteItem = () => {
        deleteAction(fileInfo);
    }

    const downloadItem = () => {
        downloadAction(fileInfo);
    }

    const openItem = () => {
        openFileAction(fileInfo);
    }

    const renameItem = () => {
        const id = fileInfo.ID;
        const type = fileInfo.type;

        triggerRenameModal(id, type);
    }

    const readItemProperties = () => {
        const nodeId = fileInfo.ID;
        const type = fileInfo.type;

        triggerInfoModal(nodeId, type);
    }

    return (
        <>
            { !fileInfo.lock ?
                <div className='d-flex flex-column align-items-start fileContextMenuFrame py-1'
                    style={{ position: 'absolute', left: `${fileInfo.x}px`, top: `${fileInfo.y}px` }}>
                    <span
                        className='py-1 pl-2 w-100 fileContextMenuItem'
                        onClick={readItemProperties}
                    >
                        Properties
                    </span>
                    <span
                        className='py-1 pl-2 w-100 fileContextMenuItem'
                        onClick={openItem}
                    >
                        Open
                    </span>
                    <span
                        className='py-1 pl-2 w-100 fileContextMenuItem'
                        onClick={downloadItem}
                    >
                        Download
                    </span>
                    <span
                        className='py-1 pl-2 w-100 fileContextMenuItem'
                        onClick={renameItem}
                    >
                        Rename
                    </span>
                    <span
                        className='py-1 pl-2 w-100 fileContextMenuItem'
                        onClick={deleteItem}
                    >
                        Delete
                    </span>
                </div> :
                <div className='d-flex flex-column align-items-start fileContextMenuFrame py-1'
                    style={{ position: 'absolute', left: `${fileInfo.x}px`, top: `${fileInfo.y}px` }}>
                    <span
                        className='py-1 pl-2 w-100 fileContextMenuItem'
                        onClick={readItemProperties}
                    >
                        Properties
                    </span>
                </div>}
        </>
    );
}

FileContextMenu.propTypes = {
    fileInfo: PropTypes.any.isRequired,
    loadContent: PropTypes.func.isRequired,
    triggerInfoModal: PropTypes.func,
    triggerRenameModal: PropTypes.func
}

export default FileContextMenu;