// packages
import {Switch, Route, useRouteMatch, Redirect} from 'react-router-dom';
import { useEffect, useState } from 'react';
import {MsalAuthenticationTemplate, useMsal} from '@azure/msal-react';
import { useSelector } from 'react-redux';

// styles
import './ActiveProcesses.css';

// services
import dataService from '../../../Services/HttpService/DataService';

// components
import MainFrame from '../../Reusable/MainFrame/MainFrame';
import ProcessLog from './Children/ProcessLog/ProcessLog';
import UserProcesses from './Children/UserProcesses/UserProcesses';
import {InteractionType} from "@azure/msal-browser";
import MainFrameAlt from '../../Reusable/MainFrameAlt/MainFrameAlt';
import ProcessesHistory from '../../Reusable/ProcessesHistory/ProcessesHistory';


function ActiveProcessesContent() {
    const { path } = useRouteMatch();
    const [ processes, setProcesses ] = useState([]);
    const { instance, accounts, inProgress } = useMsal();

    // useEffect(() => {
    //     (async () => {
    //         if (inProgress === 'none' && accounts.length > 0) {
    //             const userId = accounts[0].username.replace('.com', '');
    //             let authResult = await instance.acquireTokenSilent({
    //                 account: accounts[0],
    //                 scopes: ["api://48bcf903-2105-4076-be4d-b926a4240ad6/access_as_user"]
    //             });
    //             let res = await dataService.getUserProcesses(userId, authResult.accessToken);
    //             setProcesses(res);
    //         }
    //     })();
    // }, [accounts, inProgress]);

    return (
        <Switch>
            {/* <Route exact path={path}>
                <UserProcesses processes={processes} />
            </Route>
            <Route path={`${path}/:id`}>
                <ProcessLog processes={processes} />
            </Route> */}
            <Route exact path={path}>
                <ProcessesHistory path={path} newProcessEnabled={false} showMenu={true} />
            </Route>
        </Switch>
    );
}

function ActiveProcesses() {
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {
                selectedSystem === -1 ?
                    <Redirect to='/' />
                    :
                    <MainFrameAlt
                        title='PROCESSES'
                        content={<ActiveProcessesContent/>}
                        topStyle="align-items-center"
                    />
            }
        </MsalAuthenticationTemplate>
    );
}

export default ActiveProcesses;