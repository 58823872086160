// packages
import { useState, useEffect } from 'react';

// styles
import './FilesProgressSideBar.css';

// services
// React Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons/faFileUpload';
import CloseButton from '../../../../Reusable/CloseButton/CloseButton';
import awesomeFile from '../../../../../Utils/svg_icons/awesome-file.svg';
import ProgressBar from 'react-bootstrap/ProgressBar';

function FilesProgressSideBar({ files, barVisible, visibilityChange, handleUploadAbort, handleClearAll }) {
    const handleVisibilityChange = (val) => {
        visibilityChange(val);
    }

    useEffect(() => {
        if (barVisible) {
            // before doing this we should locate ourselves at the start of the page again
            window.scrollTo(0,0);
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }, [barVisible])

    if (!barVisible) {
        return (
            <div
                className='fileProgressButton d-flex justify-content-center align-items-center'
                onClick={() => handleVisibilityChange(true)}
            >
                <FontAwesomeIcon
                    icon={faFileUpload}
                    size='1x'
                    color='#FFFFFF'
                />
            </div>
        );
    } else {
        return (
            <div className='fileProgressSideBarFrame'>
                <div className='container-fluid d-flex justify-content-end mt-2'>
                    <CloseButton onClick={() => handleVisibilityChange(false)} />
                </div>
                <div className='container-fluid mt-3'>
                    <h5 className='fileProgressTitle'><strong>Progress</strong></h5>
                    <div className='container-fluid mt-2 p-0 d-flex justify-content-between'>
                        <span className='fileProgressSubtitle'>Loading {files.length} elements</span>
                        <button className='btn btn-xs btn-outline-dark clear-btn' onClick={() => handleClearAll()}>Clear all</button>
                    </div>         
                </div>
                <div className='files-list'>
                    {
                        files && files.map((file) => (
                            <div className='container-fluid mt-3 d-flex' key={file.id}>
                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <img
                                        src={awesomeFile}
                                        alt={`alternative file icon`}
                                    />
                                </div>
                                <div className='col-10 pl-1 pt-0 d-flex flex-column'>
                                    <div className='d-flex justify-content-between'>
                                        <span className='fileProgressInfo'>{file.name}</span>
                                        <span
                                            className='fileInfoX'
                                            onClick={() => handleUploadAbort(file.controller, file.id, file.progress)}
                                        >
                                            x
                                        </span>
                                    </div>
                                    <ProgressBar
                                        now={file.progress}
                                        className='progress-bar-blueGensa'
                                    />
                                    <span className='fileProgressState'>
                                        {file.progress < 100 ? `${file.progress}%` : 'Completed!'}
                                    </span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default FilesProgressSideBar;