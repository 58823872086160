import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import BlueButton from "../../Reusable/BlueButton/BlueButton";
import { InteractionType } from "@azure/msal-browser";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

// styles
import './DistributorsTest.css';

// Services
import MainFrameAlt from "../../Reusable/MainFrameAlt/MainFrameAlt";

function DistributorTestContent(props) {
    const defaultDistributors = [
        { desc: "Comercial Turrucarreña", state: 2},{ desc: "Horizontales Apízar Castro", state: 0},{ desc: "María José del Norte", state: 2},
        { desc: "Mario Alpízar Retana", state: 2},{ desc: "Omar Chacón", state: 0},
    ]

    const testedDistributors = [
        { desc: "Comercial Turrucarreña", state: 2},{ desc: "Horizontales Apízar Castro", state: 1},{ desc: "María José del Norte", state: 2},
        { desc: "Mario Alpízar Retana", state: 2},{ desc: "Omar Chacón", state: 1},
    ]
    const [ distributors, setDistributors ] = useState(defaultDistributors)

    const getStatusClass = (status) => {
        if (status === -1) return "disconnected";
        else if (status === 0) return "loading";
        else if (status === 1) return "connected";
    }

    const getSymbolClass = (status) => {
        if (status === 1) return "success-checkmark";
        else if (status === -1) return "fail-mark";
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const testConnection = () => {
        (async () => {
            setDistributors(defaultDistributors);
            await sleep(1000);
            setDistributors(testedDistributors);
        })();
    }

    return (
        <div className="flex-grow-1 container-fluid d-flex flex-column w-75 align-items-center">
            <h5 className="text-muted text-center mx-5 my-4">Use this screen to verify the connection to the distributor's services</h5>
            <div className="services-list card d-flex flex-column align-items-center">
                {/* <div className="service-item">
                    <p className="service-item-text">Backend API</p>
                    <div className={`service-item-status dot ${getStatusClass(servicesStatus.backend)}`}>
                        <div className={`${getSymbolClass(servicesStatus.backend)}`}></div>
                    </div>
                </div>
                <div className="service-item">
                    <p className="service-item-text">Blob Storage</p>
                    <div className={`service-item-status dot ${getStatusClass(servicesStatus.blob)}`}>
                        <div className={`${getSymbolClass(servicesStatus.blob)}`}></div>
                    </div>
                </div> */}
                {distributors.map((item, j) => {
                    let statusElement;
                    if(item.state === 2){
                        statusElement = <div className="service-item-status">Manual</div>
                    }else{
                        statusElement = (
                            <div className={`service-item-status dot ${getStatusClass(item.state)}`}>
                                <div className={`${getSymbolClass(item.state)}`}></div>
                            </div>
                        )
                    }
                    return (
                        <div className="service-item">
                            <p className="service-item-text">{item.desc}</p>
                            {statusElement}
                        </div>
                    )
                })}
                <div className="d-flex w-100 justify-content-center mt-4">
                    <BlueButton onClick={testConnection} title='Run test' />
                </div>
            </div>
        </div>
    )
}

function DistributorsTest() {
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {
                selectedSystem === -1 ?
                    <Redirect to='/' />
                    :
                    <MainFrameAlt
                        title='API CONNECTIONS'
                        content={<DistributorTestContent />}
                        topStyle="align-items-center"
                    />
            }
        </MsalAuthenticationTemplate>
    );
}

export default DistributorsTest;