import React from 'react';

//styles
import './SearchBar.css'

const SearchBar = ({keyword,setKeyword}) => {
  return (
    <input 
     key="random1"
     value={keyword}
     placeholder={"Filter items by name"}
     onChange={(e) => setKeyword(e.target.value)}
     className="search-bar form-control"
    />
  );
}

export default SearchBar