import React, { useEffect, useState } from "react";
import { service, factories, models, IEmbedConfiguration } from "powerbi-client";
import "./PowerBIReport.css";
import { useMsal } from "@azure/msal-react";

const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

let genAccessToken = '';
let genEmbedUrl = '';
let reportContainer = <></>;

function PowerBIReport({workspaceId, elementId, elementType, elementEmbedurl}){
    // const workspaceId = '0917fa3d-1c56-464f-97f5-091deea9c6a1';
    const reportId = '46aeebf0-fa76-4b92-99b1-f199f2de192c';

    const [ accessToken, setAccessToken ] = useState('');
    const [ embedUrl, setEmbedUrl ] = useState('');
    const [ error, setError ] = useState([]);

    const { instance, accounts, inProgress } = useMsal();

    let reportRef = React.createRef();
    let loading = (
        <div
            id="reportContainer"
            ref={reportRef} >
            Loading the report...
        </div>
    )

    useEffect(() => {
        if (reportRef !== null) {
            reportContainer = reportRef["current"];
        }

        // User input - null check
        if (elementId === "") {
            setError(["Please assign values to workspace Id and report Id in Config.ts file"])
        } else {

            // Authenticate the user and generate the access token
            authenticate();
        }
        
        return function cleanup(){
            powerbi.reset(reportContainer);
        }
    }, []);

    function authenticate() {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let scopes = [];
                try{
                    if(elementType === 'report') scopes.push('https://analysis.windows.net/powerbi/api/Report.Read.All')
                    else if(elementType === 'dashboard') scopes.push('https://analysis.windows.net/powerbi/api/Dashboard.Read.All')
                    let authResponse = await instance.acquireTokenSilent({
                        account: accounts[0],
                        scopes: scopes
                    });
                    genAccessToken = authResponse.accessToken;
                    console.log(authResponse);
                    setAccessToken(authResponse.accessToken);
                    // tryRefreshUserPermissions();
                    getembedUrl();
                }catch(error){
                    if (error.name === "InteractionRequiredAuthError") {
                        instance.acquireTokenRedirect({
                            account: accounts[0],
                            scopes: scopes
                        });
                    }
                    else {
                        setError([error.toString()])
                    }
                }
                
            }
            // else{
            //     let response = await instance.loginPopup();
            // }
        })();
    }

    // Power BI REST API call to refresh User Permissions in Power BI
    // Refreshes user permissions and makes sure the user permissions are fully updated
    // https://docs.microsoft.com/rest/api/power-bi/users/refreshuserpermissions
    function tryRefreshUserPermissions() {
        fetch("https://api.powerbi.com/v1.0/myorg/RefreshUserPermissions", {
            headers: {
                "Authorization": "Bearer " + genAccessToken
            },
            method: "POST"
        })
        .then(function (response) {
            if (response.ok) {
                console.log("User permissions refreshed successfully.");
            } else {
                // Too many requests in one hour will cause the API to fail
                if (response.status === 429) {
                    console.error("Permissions refresh will be available in up to an hour.");
                } else {
                    console.error(response);
                }
            }
        })
        .catch(function (error) {
            console.error("Failure in making API call." + error);
        });
    }

    // Power BI REST API call to get the embed URL of the report
    function getembedUrl() {
        console.log(elementType);
        let type = elementType === 'report' ? '/reports/' : '/dashboards/';
        let url = `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}${type}${reportId}`;
        fetch(url, {
            headers: {
                "Authorization": "Bearer " + genAccessToken
            },
            method: "GET"
        })
            .then(function (response) {
                const errorMessage = [];
                errorMessage.push("Error occurred while fetching the embed URL of the report")
                errorMessage.push("Request Id: " + response.headers.get("requestId"));

                response.json()
                    .then(function (body) {
                        // Successful response
                        if (response.ok) {
                            genEmbedUrl = body["embedUrl"];
                            setEmbedUrl(genEmbedUrl);
                            renderReport();
                        }
                        // If error message is available
                        else {
                            errorMessage.push("Error " + response.status + ": " + body.error.code);

                            setError(errorMessage);
                        }

                    })
                    .catch(function () {
                        errorMessage.push("Error " + response.status + ":  An error has occurred");

                        setError(errorMessage);
                    });
            })
            .catch(function (error) {

                // Error in making the API call
                setError(error);
            })
    }

    // Show username in the UI
    function setUsername(username) {
        const welcome = document.getElementById("welcome");
        if (welcome !== null)
            welcome.innerText = "Welcome, " + username;
    }

    function renderReport(){
        if (error.length) {
            // Cleaning the report container contents and rendering the error message in multiple lines          
            error.forEach(line => {
                reportContainer.appendChild(document.createTextNode(line));
                reportContainer.appendChild(document.createElement("br"));
            });
        }
        else if (genAccessToken !== "" && elementEmbedurl !== "") {
            const embedConfiguration = {
                type: elementType,
                tokenType: models.TokenType.Aad,
                genAccessToken,
                elementEmbedurl,
                id: elementId,
                /*
                // Enable this setting to remove gray shoulders from embedded report
                settings: {
                    background: models.BackgroundType.Transparent
                }
                */
            };
    
            const report = powerbi.embed(reportContainer, embedConfiguration);
    
            // Clear any other loaded handler events
            report.off("loaded");
    
            // Triggers when a content schema is successfully loaded
            report.on("loaded", function () {
                console.log("Report load successful");
            });
    
            // Clear any other rendered handler events
            report.off("rendered");
    
            // Triggers when a content is successfully embedded in UI
            report.on("rendered", function () {
                console.log("Report render successful");
            });
    
            // Clear any other error handler event
            report.off("error");
    
            // Below patch of code is for handling errors that occur during embedding
            report.on("error", function (event) {
                const errorMsg = event.detail;
    
                // Use errorMsg variable to log error in any destination of choice
                console.error(errorMsg);
            });
        }
    }

    return loading;
}

export default PowerBIReport;