// Packages
import PropTypes from "prop-types";

// Styles
import './TextInputComponent.css';

function TextInputComponent(props) {
    return (
        <div className='mb-3'>
            <label className='textInputTitle'>{props.title}</label>
            <input
                className='form-control'
                type={props.inputType}
                disabled={props.inputDisabled}
                onChange={props.onChange}
                value={props.inputValue}
                name={props.inputName}
            />
        </div>
    );
}

TextInputComponent.propTypes = {
    title: PropTypes.string,
    inputType: PropTypes.string,
    inputDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    inputValue: PropTypes.any,
    inputName: PropTypes.string
}

export default TextInputComponent;