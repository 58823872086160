import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Card, Popover } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";

import "./ColumnFilter.css";

function ColumnFilter({title, values, defaultSelectedValues, className='', onValueSelection, onRemoveSelection, name, Id, attributeType, filterType}) {
    const [ lookUpInput, setLookUpInput ] = useState('');
    const [ selectedValues, setSelectedValues ] = useState([...defaultSelectedValues]);

    // useEffect(() => {
    //     verifySelectedValues();
    // }, [values]);

    let handleChangeLookUpInput = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setLookUpInput(lowerCase);
    };

    const filteredData = values.filter((el) => {
        //if no input the return the original
        if (lookUpInput === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.value.toLowerCase().includes(lookUpInput)
        }
    });

    const verifySelectedValues = () => {
        const valuesIds = values.map(x => x.id);
        const found = selectedValues.some(r=> valuesIds.includes(r))
        if (found) {
            let newSelectedValues = selectedValues.splice().filter(x => valuesIds.includes(x));
            setSelectedValues(newSelectedValues);
            onValueSelection(Id, name, newSelectedValues);
        }
    }

    const handleCheckboxChange = (itemId, checked) => {
        if(checked){
            let newSelectedValues = [];
            if(itemId === '-1'){ 
                newSelectedValues = values.map(x => x.id); 
            }
            else{
                newSelectedValues = [...selectedValues, itemId];
            }
            // onValueSelection(Id, name, newSelectedValues);
            setSelectedValues(newSelectedValues);
        }else{
            let newSelectedValues = []
            if(itemId !== '-1'){
                newSelectedValues = selectedValues.slice().filter(x => x !== itemId && x !== '-1');
            }
            setSelectedValues(newSelectedValues);
            // onValueSelection(Id, name, newSelectedValues);
        }
    }

    const handleApplyFilters = () => {
        onValueSelection(Id, name, selectedValues);
    }

    const handleRemoveSelection = () => {
        setSelectedValues([])
        onRemoveSelection(Id, name);
    }

    let customFilter = [];
    if (attributeType === 'number') {
        if (filterType === 'greater' || filterType === 'between') {
            customFilter.push(
                <div className="form-group" key='min'>
                    <input className="form-control form-control-sm" type="number" step='0.01' placeholder="Min"></input>
                </div>
            )
        }
        if (filterType === 'between') {
            customFilter.push(
                <div className="form-group text-center mb-0" key='to'>
                <label className="form-control-label"> to </label>
            </div>
            )
        }
        if (filterType === 'lesser' || filterType === 'between') {
            customFilter.push(
                <div className="form-group" key='max'>
                    <input className="form-control form-control-sm" type="number" step='0.01' placeholder="Max"></input>
                </div>
            )
        }
    } else if (attributeType === 'text') {
        let label = '';
        if (filterType === 'includes') label = 'Values that include the text:';
        else if (filterType === 'equals') label = 'Values equals to:';
        customFilter.push(
            <div className="form-group">
                <label className="form-control-label">{label}</label>
                <input className="form-control form-control-sm" type="text"></input>
            </div>
        )
    }

    return (
        <Popover id={`popover-${name}`} className={`filter-card mx-4 ${className}`}>
            <Popover.Title className="filter-title">{title}</Popover.Title>
            <Popover.Content>
                <div className="d-flex searchBar">
                    <input
                        type="text"
                        className="searchBarInput"
                        value={lookUpInput}
                        onChange={handleChangeLookUpInput}
                    />
                    <div className="d-flex justify-content-center align-items-center searchBarIcon">
                        <FontAwesomeIcon icon="search" />
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-xs btn-link" onClick={handleRemoveSelection}>Clear &times;</button>
                </div>
                <div className="options-list-container mt-2 box-grow">
                    <div className="options-list">
                        {filteredData.map((item, index) => {
                            return (
                                <div className="form-check" key={index}>
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        checked={selectedValues.includes(item.id)} 
                                        id={item.id} 
                                        onChange={(e) => handleCheckboxChange(item.id, e.target.checked)} 
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {item.value}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="special-filter-container">
                    {customFilter}
                </div>
                <div className="filter-card-actions">
                    <button className="btn btn-outline-primary btn-xs" onClick={handleApplyFilters}>Apply</button>
                </div>
            </Popover.Content>
        </Popover>
    )
}

export default ColumnFilter;