import Alert from "react-bootstrap/Alert";
import { useState } from "react"
import { useHistory } from 'react-router-dom';

//services
import * as TableauDataService from "../../../../../../../Services/HttpService/TableauDataService"

//graphic components
import MoveButton from "../../../../../../Reusable/MoveButton/MoveButton";

function SourceSelectionStage(props) {
    const [inputText, setInputText] = useState({
        server: "",
        user: "",
        pass: "",
        site: "",
    })
    const [ showAlert, setShowAlert ] = useState(false);
    const history = useHistory();

    const onChange = e => {
        setInputText({
            ...inputText,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await (async () => {
            try{
                let response = await TableauDataService.signin(inputText.server.replace(/^https?:\/\//,''), inputText.user, inputText.pass, inputText.site);
                setInputText({
                    server: "",
                    user: "",
                    pass: "",
                    site: "",
                })
                console.log(response);
                history.push(`${props.path}/selection/projects`);
            }
            catch(e){
                console.log(e);
                setShowAlert(true);
            }
        })();
    }

    return (
        <div className="container-fluid flex-grow-1 align-items-center d-flex justify-content-center">
            <div className="card d-flex justify-content-center w-50">
                <form onSubmit={handleSubmit} className="p-4">
                    <div className="form-group">
                        <label>Server</label>
                        <input
                            type="text"
                            className="input-text form-control"
                            placeholder="Server url"
                            value={inputText.server}
                            name="server"
                            onChange={onChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Username</label>
                        <input
                            type="text"
                            className="input-text form-control"
                            value={inputText.user}
                            name="user"
                            onChange={onChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="input-text form-control"
                            value={inputText.pass}
                            name="pass"
                            onChange={onChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Site</label>
                        <input
                            type="text"
                            className="input-text form-control"
                            placeholder="Site name"
                            value={inputText.site}
                            name="site"
                            onChange={onChange}
                        />
                    </div>
                    <div className="d-flex w-100 mb-3 justify-content-end">
                        {/* <MoveButton onClick={null} forward={true} /> */}
                        <button className="input-submit btn btn-sm btn-primary">Go</button>
                    </div>
                    {showAlert && 
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            It looks like there was an error connecting to the server. Please check your credentials, server URL and site name.
                            If you are still unable to connect, get in touch with your system administrator.
                        </Alert>
                    }
                </form>
            </div>
        </div>
    )
}

export default SourceSelectionStage