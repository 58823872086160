import { React, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FileAccessComponent from './FileAccessComponent';

function FileAccessModal({ fileInfo, show, onHide }){

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop='static'
            keyboard='false'
            centered={true}
            className="task-info-modal"
            size="xl"
        >
            <Modal.Header closeButton >
                Access Management
            </Modal.Header>
            <Modal.Body>
                <FileAccessComponent fileInfo={fileInfo} />
            </Modal.Body>
        </Modal>
    )
}

export default FileAccessModal;