import { Link } from "react-router-dom"

function LinksBreadcrumb(props){

    return(
        <nav aria-label="breadcrumb" className={props.styleClass}>
            <ol className="breadcrumb">
                {props.items.map((item, index) => {
                    return(
                        <li key={index} className={`breadcrumb-item ${item.active ? 'active' : ''}`}>
                            {item.active ? <span>{item.name}</span> : <Link to={item.url} onClick={() => props.onClick(index)}>{item.name}</Link>}
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}

export default LinksBreadcrumb