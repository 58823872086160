import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import LinksBreadcrumb from "../../Reusable/LinksBreadcrumb/LinksBreadcrumb";
import ViewNavContainer from "./Children/ViewNavContainer";
import MainFrame from "../../Reusable/MainFrame/MainFrame";
import MainFrameAlt from "../../Reusable/MainFrameAlt/MainFrameAlt";
import TableauViewContainer from "../../Reusable/TableauEmbeddedView/TableauViewContainer"
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

function DashboardVisualizationContent(props){
    const { path } = useRouteMatch();
    const [ currentView, setCurrentView ] = useState('')
    const [ currentSite, setCurrentSite ] = useState('');
    const [ currentWorkbook, setCurrentWorkbook ] = useState('')
    const [ currentViewsList, setCurrentViewsList ] = useState([])
    const [ currentViewId, setCurrentViewId ] = useState('')
    const [ navItems, setNavItems ] = useState([]);

    useEffect(() => {
        setNavItems([
            {
                name: 'Folders',
                url: `${path}`,
                active: true
            }
        ])
    }, []);

    const setEmbeddedViewList = (siteName, workbookName, viewList, viewName, viewId) => {
        setCurrentSite(siteName)
        setCurrentWorkbook(workbookName)
        setCurrentView(viewName)
        setCurrentViewsList(viewList)
        setCurrentViewId(viewId)
    }

    const onLinkClick = (index) => {
        let items = navItems.slice(0, index+1);
        if(items[items.length-1]) items[items.length-1].active = true;
        setNavItems(items)
    }

    const addNavItem = (name, url) => {
        navItems.forEach(item => {
            item.active = false
        });
        setNavItems(
            [
                ...navItems,
                {
                    name: name,
                    url: url,
                    active: true
                }
            ]
        )
    }

    return (
        <div className="flex-grow-1 d-flex flex-column h-100">
            <Switch>
                <Route path={`${path}/views/:id?`}>
                    <MainFrameAlt
                        title="Dashboards"
                        topStyle="align-items-center"
                        content={(
                            <div className="d-flex flex-column flex-grow-1">
                                <LinksBreadcrumb items={navItems} onClick={onLinkClick} />
                                <TableauViewContainer
                                    path={path}
                                    workbookName={currentWorkbook}
                                    viewName={currentView}
                                    viewId={currentViewId}
                                    viewsList={currentViewsList}
                                    addNavItem={addNavItem}
                                    tableauUsername={''}
                                />
                            </div>)}
                    />
                </Route>
                <Route path={`${path}/workbooks/:id?`}>
                    <MainFrameAlt
                        title="DASHBOARDS"
                        topStyle="align-items-center"
                        content={(
                            <div className="d-flex flex-column flex-grow-1">
                                <LinksBreadcrumb items={navItems} onClick={onLinkClick} />
                                <ViewNavContainer
                                    path={path}
                                    setViews={setEmbeddedViewList}
                                    addNavItem={addNavItem}
                                    navToWorkbook={onLinkClick}
                                    itemsType={3}
                                />
                            </div>)}
                    />
                </Route>
                <Route path={`${path}/folders/:id?`}>
                    <MainFrameAlt
                        title="DASHBOARDS"
                        topStyle="align-items-center"
                        content={(
                            <div className="d-flex flex-column flex-grow-1">
                                <LinksBreadcrumb items={navItems} onClick={onLinkClick} />
                                <ViewNavContainer
                                    path={path}
                                    setViews={setEmbeddedViewList}
                                    addNavItem={addNavItem}
                                    navToWorkbook={onLinkClick}
                                    itemsType={2}
                                />
                            </div>)}
                    />
                </Route>
                <Route path=''>
                    <MainFrameAlt
                        title="DASHBOARDS"
                        topStyle="align-items-center"
                        content={(
                            <div className="d-flex flex-column flex-grow-1">
                                <LinksBreadcrumb items={navItems} onClick={onLinkClick} />
                                <ViewNavContainer
                                    path={path}
                                    setViews={setEmbeddedViewList}
                                    addNavItem={addNavItem}
                                    navToWorkbook={onLinkClick}
                                    itemsType={1}
                                />
                            </div>)}
                    />
                </Route>
            </Switch>
        </div>
    )
}

function DashboardVisualization(){
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {
                selectedSystem === -1 ?
                    <Redirect to='/' />
                    :
                    <DashboardVisualizationContent/>
            }
        </MsalAuthenticationTemplate>
    )
}

export default DashboardVisualization