// graphic components
import Button from 'react-bootstrap/Button';

//services
import * as DashboardDataService from "../../../../../Services/HttpService/DashboardDataService"

//packages
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";

//styles
import './ViewList.css';
import { useHistory, useRouteMatch } from 'react-router';
import ConfirmationModal from '../../../../Reusable/CustomModal/ConfirmationModal/ConfirmationModal';
import EditPermissionsContainer from '../EditPermissions/EditPermissionsContainer';
import { apiLoginRequest } from '../../../../../authConfig';

function ViewsList(props) {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [ items, setItems ] = useState([]);
    const [ showConfirmModal, setShowConfirmModal ] = useState(false);
    const [ deleteFolderId, setDeleteFolderId ] = useState(null);
    const selectedSystem = useSelector((state) => state.systems.selectedSystem);
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        getItems();
    }, [])

    useEffect(() => {
        getItems();
    }, [selectedSystem, inProgress, accounts]);

    const getItems = () => {
        (async () => {
            if (inProgress === 'none' && accounts.length > 0) {
                let authResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const userId = accounts[0].username.replace('.com', '');
                let data = await DashboardDataService.getSites(selectedSystem, userId, authResult.accessToken);
                data = data.map(x => {
                    return {
                        id: x.Site_Id,
                        server: x.Server_Url,
                        site: x.Site_Url,
                        desc: x.Site_Name,
                    }
                });
                setItems(data);
            }
        })();
    }

    const deleteItem = () => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let res = await DashboardDataService.deleteSite(deleteFolderId, authResult.accessToken);
                let newItems = items.slice().filter(x => x.id !== deleteFolderId);
                console.log(newItems);
                setItems(newItems);
                setShowConfirmModal(false);
            }
        })();
    }

    const handleDeleteFolder = (id) => {
        setDeleteFolderId(id);
        setShowConfirmModal(true);
    }

    const editItem = (id) => {
        let url = `${path}/edit/${id}/selection/projects`;
        history.push(url);
    }

    const editPermissionss = (id) => {
        let url = `${path}/permissions/${id}/`;
        history.push(url);
    }

    return (
        <div className="flex-grow-1 row m-4">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                Server
                            </th>
                            <th>
                                Site
                            </th>
                            <th>
                                Description
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.server}</td>
                                    <td>{item.site}</td>
                                    <td>{item.desc}</td>
                                    <td className='actions'>
                                        <Button variant="outline-info" size="sm" onClick={() => editPermissionss(item.id)}>Permissions</Button>
                                        <Button variant="outline-dark" size="sm" onClick={() => editItem(item.id)}>Edit</Button>
                                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteFolder(item.id)}>Delete</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <ConfirmationModal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                onAcceptBtnClick={deleteItem}
                onCloseBtnClick={() => setShowConfirmModal(false)}
                onCancelBtnClick={() => setShowConfirmModal(false)}
                title={"Delete folder"}
                text={"This folder will be deleted permanently."}
            />
        </div>
    )
}

export default ViewsList