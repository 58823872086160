// packages
import propTypes from 'prop-types';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {MsalAuthenticationTemplate, useMsal} from '@azure/msal-react';

// styles
import './FileInfoModal.css';

// services
import filesService from '../../../../Services/HttpService/FilesService';

// graphic components
import Modal from 'react-bootstrap/Modal';
import TextInputComponent from '../../TextInputComponent/TextInputComponent';
import BlueButton from "../../BlueButton/BlueButton";
import GrayButton from "../../GrayButton/GrayButton";
import { apiLoginRequest } from '../../../../authConfig';

function FileInfoModal({ show, onHide, node, loadContent }) {
    const [ fileInfo, setFileInfo ] = useState(null);
    const [ nodeName, setNodeName ] = useState('');
    const { instance, accounts, inProgress } = useMsal();
    const [ azureToken, setAzureToken ] = useState('');
    const systemId = useSelector(state => state.systems.selectedSystem);

    useEffect(() => {
        getAzureToken();
    }, []);

    const getAzureToken = () => {
        if (inProgress === 'none' && accounts.length > 0) {
            instance.acquireTokenSilent({
                account: accounts[0],
                ...apiLoginRequest
            }).then(res => {
                setAzureToken(res.accessToken);
            })
        }
    }

    const handleNameChange = (evt) => {
        const value = evt.target.value;
        setNodeName(value);
    }

    const triggerNameChange = () => {
        if (!nodeName) {
            // let user know
            return;
        }

        (async () => {
            if (node.type === 'Folder') {
                let folderInfo = {
                    System_Id: systemId,
                    Folder_Name: nodeName,
                    Folder_Id: node.id
                }
                await filesService.updateFolder(folderInfo, azureToken);
                onHide();
                loadContent();
            } else if (node.type === 'File') {
                let fileInfo = await filesService.getFileInfo(node.id, azureToken);
                console.log(fileInfo);
                fileInfo = {
                    ...fileInfo,
                    File_Id: node.id,
                    File_Name: nodeName,
                    Visible: true
                }
                await filesService.updateFile(node.id, fileInfo, azureToken);
                onHide();
                loadContent();
            }
        })();
    }

    useEffect(() => {
        if (!node) return;

        if (node.type === 'File') {
            (async () => {
                if(inProgress === 'none' && accounts.length > 0){
                    let authResponse = await instance.acquireTokenSilent({
                        account: accounts[0],
                        ...apiLoginRequest
                    });
                    let res = await filesService.getFileInfo(node.id, authResponse.accessToken);

                    if (res) {
                        setFileInfo(res);
                        setNodeName(res.File_Name);
                    }
                }
            })();
        }
    }, [node])

    return (
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
        >
            <Modal.Body>
                <div className='text-center fileInfoModalTitle'>
                    <h5><strong>{node && node.type.toUpperCase() + ' INFO'}</strong></h5>
                </div>
                {
                    fileInfo &&
                        <div>
                            <div className='row mx-2 mt-4'>
                                <label className='col-3 fileInfoModalAttribute'>Path: </label>
                                <span className='col-9'>{fileInfo.File_Path}</span>
                            </div>
                            <div className='row mx-2 mt-2'>
                                <label className='col-3 fileInfoModalAttribute'>Extension: </label>
                                <span className='col-9'>{fileInfo.File_Type_Ext}</span>
                            </div>
                            <div className='row mx-2 mt-2'>
                                <label className='col-3 fileInfoModalAttribute'>Owner: </label>
                                <span className='col-9'>{fileInfo.Owner}</span>
                            </div>
                            <div className='row mx-2 mt-2'>
                                <label className='col-3 fileInfoModalAttribute'>Creation: </label>
                                <span className='col-9'>{fileInfo.Date_Created}</span>
                            </div>
                            <div className='row mx-2 mt-2'>
                                <label className='col-3 fileInfoModalAttribute'>Modified: </label>
                                <span className='col-9'>{fileInfo.Date_Modified}</span>
                            </div>
                            <div className='row mx-2 mt-2'>
                                <label className='col-3 fileInfoModalAttribute'>Size: </label>
                                <span className='col-9'>{fileInfo.Size + ' KB'}</span>
                            </div>
                            <div className='mx-4 mt-3'>
                                <TextInputComponent
                                    title='Name'
                                    inputName='elementNameInput'
                                    inputValue={nodeName}
                                    onChange={handleNameChange}
                                />
                            </div>
                            <div className='row mx-2 mt-5 d-flex justify-content-end'>
                                <div className='mr-2'>
                                    <GrayButton onClick={onHide} title='Cancel' />
                                </div>
                                <BlueButton onClick={triggerNameChange} title='Change Name' />
                            </div>
                        </div>
                }
            </Modal.Body>
        </Modal>
    );
}

FileInfoModal.propTypes = {
    show: propTypes.bool.isRequired,
    onHide: propTypes.func.isRequired,
    node: propTypes.any,
    loadContent: propTypes.func
}

export default FileInfoModal;
