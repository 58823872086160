import { React, useState } from 'react'
import Select from 'react-select'

//styles
import './FileAccessComponent.css'

const userOptions = [
    { value: 'dcruz@gencogroup.tech', label: 'Diego Cruz' },
    { value: 'lrosales@gensagroup', label: 'Luis Rosales' },
    { value: 'bnavas@gensagroup', label: 'Byron Navas' }
]

const rolesOptions = [
    { value: '1', label: 'Role 1' },
    { value: '2', label: 'Role 2' },
    { value: '3', label: 'Role 3' }
]

function FileAccessComponent({fileInfo}){
    const [ systemShared, setSystemShared ] = useState(false);

    return (
        <div className='access-management-container'>
            <div className='information-row row'>
                <div className='col justify-content-center d-flex'>
                    <h3>FileName.ext</h3>
                </div>
            </div>
            <div className='users-container row'>
                <div className='users-r col'>
                    <Select options={userOptions} isMulti className='basic-multi-select' classNamePrefix='select' />
                    <label className='text-muted'>Any user in this list will be able to see this file and its content.</label>
                </div>
                <div className='users-rw col'>
                    <Select options={userOptions} isMulti className='basic-multi-select' classNamePrefix='select' />
                    <label className='text-muted'>Any user in this list will be able to see and modify the file</label>
                </div>
            </div>
            <div className='roles-container row'>
                <div className='roles-r col'>
                    <Select options={rolesOptions} isMulti className='basic-multi-select' classNamePrefix='select' />
                    <label className='text-muted'>Users with any role in this list will be able to see this file and its content.</label>
                </div>
                <div className='roles-rw col'>
                    <Select options={rolesOptions} isMulti className='basic-multi-select' classNamePrefix='select' />
                    <label className='text-muted'>Users with any role in this list will be able to see and modify the file</label>
                </div>
            </div>
            <div className='system-container row'>
                <div className='col'>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id='system-check' checked={systemShared} onChange={() => setSystemShared(!systemShared)} />
                        <label className="form-check-label" htmlFor="system-check">
                            Share with everybody in this system.
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileAccessComponent;