// Packages
import PropTypes from 'prop-types';

// Styles
import './ColumnFormat.css'

// Graphic Components
import TextInputComponent from "../TextInputComponent/TextInputComponent";

function ColumnFormat(props) {

    return (
        <div>
            <label className="columnFormatTitle">Name: {props.name}</label>
            {
                props.fields.map(field => {
                    switch (field.Option_Type.toLowerCase()) {
                        case "dropdownlist":
                            return null;
                        case "text":
                            return <TextInputComponent title={field.Column_Dsc} inputType="text" key={field.Column_Id} />
                        case "integer":
                            return <TextInputComponent title={field.Column_Dsc} inputType="number" key={field.Column_Id} />
                        case "checkbox":
                            return null;
                        default:
                            return null;
                    }
                })
            }
        </div>
    );
}

ColumnFormat.propTypes = {
    name: PropTypes.string,
    fields: PropTypes.array
}

export default ColumnFormat;