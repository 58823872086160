// Packages
import PropTypes from 'prop-types';

// Styles
import './VizServerChangeModal.css';

// Graphic Components
import BlueButton from "../../BlueButton/BlueButton";
import Modal from "react-bootstrap/Modal";

function VizServerChangeModal({show, onHide, onAcceptBtnClick}){

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="vizServerChangeModal"
        >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h3 className="msg-title">
                        Logout from viz server
                    </h3>
                    <p className="msg-body my-4 text-dark">
                        You are about to close the current viz server session. You will be redirected to the server selection screen and will be asked for your credentials again.
                    </p>
                    <BlueButton
                        onClick={() => onAcceptBtnClick()}
                        title={'Accept'}
                        large={true}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default VizServerChangeModal