//graphic components
import { Link } from "react-router-dom"


function DashboardConfigBreadcrumb(props){

    return(
        <nav aria-label="breadcrumb" className="d-flex justify-content-between">
            <ol className="breadcrumb w-100 mb-0 py-2">
                {props.breadcrumbItems.map((item, index) => {
                    return(
                        <li key={index} className={`breadcrumb-item ${item.active ? 'active' : ''}`}>
                            {item.active ? <span>{item.name}</span> : <Link to={item.url} onClick={() => props.breadcrumbOnClick(index)}>{item.name}</Link>}
                        </li>
                    )
                })}
            </ol>
            <div className="breadcrumb mb-0 p-1">
                 <button className="btn btn-sm btn-outline-dark text-nowrap" type="button" onClick={() => props.handleLogout()}>Change viz server</button>
             </div>
        </nav>
    )
}

export default DashboardConfigBreadcrumb