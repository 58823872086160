import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { Link } from "react-router-dom";
import BlueButton from "../../../../../../../Reusable/BlueButton/BlueButton";

//styles
import styles from "./SelectedItemsPanel.css"

function CustomToggle({ children, eventKey, callback  }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        callback && callback(eventKey),
    );
  
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        className="mx-4 btn btn-sm"
      >
        {children}
      </button>
    );
  }

function SelectedItemsPanel(props){
    const [ collapsed, setCollapsed ] = useState(true);

    const collapsableChange = (eventKey) => {
        console.log(props.items)
        setCollapsed(!collapsed)
    }
    
    return(
        <div className="selected-items-container card w-100">
            <Accordion>
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div>
                            <CustomToggle eventKey="0" callback={collapsableChange}>
                                {collapsed ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
                            </CustomToggle>
                            <span>You have selected {props.items.length} items</span>
                        </div>
                        <div className="mr-4">
                            <BlueButton title="Save selection" onClick={() => props.onSaveClick()} />
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="table-responsive">
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Project</th>
                                            <th>Workbook</th>
                                            <th>View</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.items.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>P{index}</td>
                                                    <td>W{index}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-link" onClick={() => props.goToWorkbook(item.workbookId)}>{item.name}</button>
                                                    </td>
                                                    <td>
                                                        <button type="button" className="btn btn-sm btn-outline-danger py-1" onClick={() => props.removeSelectedItem(item.id)}>&times;</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <ul>
                                {props.items.map((item, index) => {
                                    return (
                                        <li key={index}>{item.name}</li>
                                    )
                                })}
                            </ul> */}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            
        </div>
    )
}

export default SelectedItemsPanel