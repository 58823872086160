// packages
import PropTypes from 'prop-types';

// styles
import './ResourceSelector.css';

// graphic components
import file from '../../../Utils/svg_icons/file.svg';
import folder from '../../../Utils/svg_icons/folder.svg';
import layout from '../../../Utils/svg_icons/layout.svg';

function ResourceSelector({ context, onClick, onDoubleClick, selectedIndex }) {
    return(
        context ? context.map((node, index) => {
            let selectedClass = selectedIndex === node.ID && (node.Item_Type === 'Layout' || node.Item_Type === 'File');
            let classyClass = selectedClass ? 'resourceSelectorResource d-flex align-items-center px-2 resourceSelectorResourceSelected'
                : 'resourceSelectorResource d-flex align-items-center px-2 ';
            return (
                <div
                    tabIndex={index+1}
                    key={node.Id+node.Item_Type}
                    className={classyClass}
                    onClick={() => onClick(node.Id, node.Item_Type, node.Name, node.Ext)}
                    onDoubleClick={() => onDoubleClick(node.Id, node.Item_Type, node.Name, node.Ext)}
                >
                    {
                        node.Item_Type === 'Folder' ?
                            <img src={folder} className='resourceSelectorResourceIcon mr-2' alt='Folder' />
                            :
                            node.Item_Type === 'File' ?
                                <img src={file} className='resourceSelectorResourceIcon mr-2' alt='File'/>
                                :
                                <img src={layout} className='resourceSelectorResourceIcon mr-2' alt='Layout'/>
                    }
                    <span>{node.Name + (node.Item_Type === 'File' ? '.'+node.Ext : '')}</span>
                </div>
        )}) : null
    );
}

ResourceSelector.propTypes = {
    context: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number
}

export default ResourceSelector;