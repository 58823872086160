// external packages
import { useEffect, useState } from 'react';

// internal components
import './CreateLayout.css';
import MainFrame from "../../Reusable/MainFrame/MainFrame";
import CreateLayoutContentPageOne from "./CreateLayoutComponentPageOne";
import CreateLayoutContentPageTwo from "./CreateLayoutComponentPageTwo";
import MainFrameAlt from '../../Reusable/MainFrameAlt/MainFrameAlt';

// internal services
//import layoutCreateClient from "../../../Services/HttpService/Layouts/Create/layoutCreateClient";

function CreateLayout() {
    const [ page, setPage ] = useState(1);
    const [ layoutName, setLayoutName ] = useState('');
    const [ layoutShortName, setLayoutShortName ] = useState('');
    const [ layoutDsc, setLayoutDsc ] = useState('');
    const [ hasHeaders, setHasHeaders ] = useState(false);
    const [ rowsToRemove, setRowsToRemove ] = useState(0);
    const [ layoutTypeId, setLayoutTypeId ] = useState(-1);
    const [ layoutFileTypes, setLayoutFileTypes ] = useState([]);
    const [ hasPivot, setHasPivot ] = useState(false);
    const [ defaultLayoutId, setDefaultLayoutId ] = useState(-1);
    const [ defaultLayoutOptions, setDefaultLayoutOptions] = useState([]);
    const [ layoutColumns, setLayoutColumns ] = useState([]);
    const [ fullColumns, setFullColumns ] = useState([]);
    const [ currentColumnOptions, setCurrentColumnOptions ] = useState([]);
    const [ formatOptions, setFormatOptions ] = useState([]);

    useEffect(() => {

        /*let getLayoutCreateInfo = async () => {
            let info = await layoutCreateClient.getDefaultLayouts();

            if (info === undefined)
                return;

            // set file types
            setLayoutTypeId(info.fileTypes[0].Layout_Type_Id);
            let fileTypes = info.fileTypes.map(type => ({
                value: type.Layout_Type_Id,
                dsc: `${type.Layout_Type_Dsc} ${type.Layout_Type_Ext}`
            }))
            setLayoutFileTypes(fileTypes);

            // set default layout options
            setDefaultLayoutId(info.defaultLayouts[0].Default_Layout_Id);
            let defaultOptions = info.defaultLayouts.map(opt => ({
                value: opt.Default_Layout_Id,
                dsc: opt.Default_Layout_Dsc
            }));
            setDefaultLayoutOptions(defaultOptions);

            // set layout column options
            setFullColumns(info.columns);
            let columnOptions = info.columns
                .filter(col => col.Default_Layout_Id === info.defaultLayouts[0].Default_Layout_Id)
                .map(col => ({
                    value: col.Column_Id,
                    dsc: col.Column_Dsc
                }));
            columnOptions.unshift({ value: -1, dsc: ""});
            setCurrentColumnOptions(columnOptions);

            // set layout columns.
            let columns = info.columns
                .filter(col => col.Default_Layout_Id === info.defaultLayouts[0].Default_Layout_Id)
                .map(col => ({
                    isIgnored: false,
                    isPivot: false,
                    value: "",
                    match: -1,
                    columns: columnOptions,
                    formattingOptions: []
                }));
            setLayoutColumns(columns);
        }
        getLayoutCreateInfo();

        let getFormattingInfo = async () => {
            let formattingInfo = await layoutCreateClient.getDefaultLayoutFormattingOptions(defaultLayoutId)

            if (formattingInfo === undefined) {
                return;
            }

            setFormatOptions(formattingInfo);
        }
        getFormattingInfo();*/

    }, [])

    const handlePageChange = (newPage) => {

        if (newPage === 2) {
            // validate that all columns were either allocated or ignored
            let validator = [ ...layoutColumns ]
                .filter(col => col.match === -1 || (!col.value && !col.isIgnored));
            if (validator.length > 0) {
                alert('You need to either assign or ignore every column')
            } else {
                setPage(newPage);
            }
        } else {
            setPage(newPage);
        }
    }

    const createLayout = () => {
        const layout = {
            Layout_Name: layoutName,
            Layout_Short: layoutShortName,
            Layout_Dsc: layoutDsc,
            Header: hasHeaders,
            Remove_Rows: rowsToRemove,
            Folder_Id: 1,
            Layout_Type_Id: layoutTypeId,
            Has_Pivot: hasPivot,
            Default_Id: defaultLayoutId
        }

        const exec = async () => {
            //let res = await layoutCreateClient.createNewLayout(layout)

            // evaluate response
        }
        exec();
    }

    const handleChange = (event) => {
        const value = event.target.value;

        switch(event.target.name) {
            case "layoutName":
                setLayoutName(value);
                break;
            case "layoutShortName":
                setLayoutShortName(value);
                break;
            case "layoutDsc":
                setLayoutDsc(value);
                break;
            case "RowsToRemove":
                if (value === '') {
                    setRowsToRemove(0);
                    break;
                }

                try {
                    let n = parseInt(value);
                    if (n < 0)
                        setRowsToRemove(0);
                    else
                        setRowsToRemove(n);
                }
                catch {
                    setRowsToRemove(0)
                }
                break;
            case "layoutTypeId":
                setLayoutTypeId(parseInt(value));
                break;
            case "defaultLayoutId":
                setDefaultLayoutId(parseInt(value));
                // set layout column options
                let columnOptions = [ ...fullColumns ]
                    .filter(col => col.Default_Layout_Id === value)
                    .map(col => ({
                        value: col.Column_Id,
                        dsc: col.Column_Dsc
                    }));
                columnOptions.unshift({ value: -1, dsc: ""});
                setCurrentColumnOptions(columnOptions);

                // set layout columns.
                let columns = [ ...layoutColumns ]
                    .filter(col => col.Default_Layout_Id === value)
                    .map(col => ({
                        isIgnored: false,
                        isPivot: false,
                        value: "",
                        match: 1000,
                        columns: columnOptions,
                        formattingOptions: []
                    }));
                setLayoutColumns(columns);

                break;
            default:
                break;
        }
    }

    const handleColumnPivotChange = (newValue, index) => {
        let columnsUpdate = [ ...layoutColumns ]
        columnsUpdate[index].isPivot = newValue;
        setLayoutColumns(columnsUpdate);
    }

    const handleIgnoredChange = (newValue, index) => {
        let columnsUpdate = [ ...layoutColumns ]
        columnsUpdate[index].isIgnored = newValue;
        setLayoutColumns(columnsUpdate);
    }

    const handleColumnNameChange = (newValue, index) => {
        let columnsUpdate = [ ...layoutColumns ]
        columnsUpdate[index].value = newValue;
        setLayoutColumns(columnsUpdate);
    }

    const handleColumnMatchChange = (newValue, index) => {
        let columnsUpdate = [ ...layoutColumns ]
        columnsUpdate[index].match = parseInt(newValue);

        // updating available options
        let options = [ ...currentColumnOptions ]

        let cache = [];
        columnsUpdate.forEach(col => {
            if (!cache.includes(col.match) && col.match !== -1) {
                cache.push(parseInt((col.match)));
            }
        });

        options = options.map(opt => {
            if (cache.includes(opt.value)) {
                return {
                    ...opt,
                    hidden: true
                };
            } else {
                return opt;
            }
        });

        // updating formatting options
        if (parseInt(newValue) !== -1) {
            let newFormatOpts = formatOptions.filter(opt => opt.Default_Column_Id === parseInt(newValue))
            columnsUpdate[index].formattingOptions = newFormatOpts;
        }

        columnsUpdate = columnsUpdate.map(col => ({
            ...col,
            columns: options
        }));

        setLayoutColumns(columnsUpdate);
    }

    const handleHasHeadersChange = (nextHasHeaders) => {
        setHasHeaders(nextHasHeaders);
    }

    const handleHasPivotChange = nextHasPivot => {
        setHasPivot(nextHasPivot)
    }

    let pageContent = null;
    switch(page) {
        case 1:
            pageContent = < CreateLayoutContentPageOne
                defaultOptionsControls={ { options: defaultLayoutOptions, value: defaultLayoutId, name: "defaultLayoutId", handler: handleChange } }
                layoutNameControls = { { value: layoutName, name: "layoutName", handler: handleChange } }
                layoutShortNameControls = { { value: layoutShortName, name: "layoutShortName", handler: handleChange } }
                layoutDscControls = { { value: layoutDsc, name: "layoutDsc", handler: handleChange } }
                rowsToRemoveControls = { { value: rowsToRemove, name: "RowsToRemove", handler: handleChange } }
                hasHeadersControls = { { checked: hasHeaders, handler: handleHasHeadersChange } }
                pivotControls = { { checked: hasPivot, handler: handleHasPivotChange } }
                fileTypesControls = { { options: layoutFileTypes, value: layoutTypeId, name: "layoutTypeId", handler: handleChange } }
                columnControls = {
                    {
                        columns: layoutColumns,
                        pivotHandler: handleColumnPivotChange,
                        ignoreHandler: handleIgnoredChange,
                        valueHandler: handleColumnNameChange,
                        matchHandler: handleColumnMatchChange
                    }
                }
                onHandlePageChange = {handlePageChange}
            />
            break;
        case 2:
            pageContent = < CreateLayoutContentPageTwo columns={layoutColumns} />
            break;
        default:
            break;
    }

    return(
        <MainFrameAlt topStyle="align-items-center" content={ pageContent } />
    );
}

export default CreateLayout;