
export const filterName = (rawName) => {
    if (!rawName)
        return '';

    const localUserNameParts = rawName.split(' ');
    let localUserName = '';

    if (localUserNameParts.length === 0)
        return '';

    if (localUserNameParts.length === 1) {
        localUserName = localUserNameParts[0];
    } else if (localUserNameParts.length === 2) {
        localUserName = localUserNameParts[0] + ' ' + localUserNameParts[1];
    } else {
        localUserName = localUserNameParts[0] + ' ' + localUserNameParts[2];
    }

    return localUserName;
}