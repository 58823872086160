// packages
import { Link } from 'react-router-dom';

// styles
import './UserProcesses.css';

// services

// components
import Table from "react-bootstrap/Table";


function UserProcesses({ processes }) {
    return (
        <div className='container my-5'>
            <Table>
                <thead className='userProcessGray userProcessTblTitle'>
                    <tr>
                        <th>Process Id</th>
                        <th>Layout</th>
                        <th>File</th>
                        <th>Date | Time</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        processes.map((process, i) => {
                            let background = '';
                            if (i % 2 === 1)
                                background = 'userProcessGray';
                            else
                                background = 'userProcessLight';

                            return (
                                <tr className={background} key={process['RunId']}>
                                    <td className='userProcessTblContent'>
                                        <Link to={`processes/${process['RunId']}`}>
                                            { process['RunId'] }
                                        </Link>
                                    </td>
                                    <td className='userProcessTblContent'>{ process['LayoutName'] }</td>
                                    <td className='userProcessTblContent'>{ process['FileName'] }</td>
                                    <td className='userProcessTblContent'>
                                        {
                                            process['DateCreated'].replace('T', ' | ').replace(/\.[0-9]*/, '')
                                        }
                                    </td>
                                    <td className='userProcessTblContent'>{ process['status'] }</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    );
}

export default UserProcesses;