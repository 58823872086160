// packages
import { useState } from "react";
import { Switch, Route, useRouteMatch, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//graphic components
import TableauViewContainer from "./Children/TableauViewContainer";
import LinksBreadcrumb from "../../../../Reusable/LinksBreadcrumb/LinksBreadcrumb";
import DashboardConfigBreadcrumb from "./Children/DashboardConfigBreadcrumb";
import VizServerChangeModal from "../../../../Reusable/CustomModal/VizServerChangeModal/VizServerChangeModal";
import MainFrame from "../../../../Reusable/MainFrame/MainFrame";
import MainFrameAlt from "../../../../Reusable/MainFrameAlt/MainFrameAlt";
import EditSelection from "./EditSelection";

function EditViewsContainer(props){
    const { path } = useRouteMatch();
    const { id } = useParams();
    const [ showLogoutModal, setShowLogoutModal ] = useState(false);
    const [ currentView, setCurrentView ] = useState('')
    const [ currentWorkbook, setCurrentWorkbook ] = useState('')
    const [ currentViewsList, setCurrentViewsList ] = useState([])
    const [ currentViewId, setCurrentViewId ] = useState('')
    const [ navItems, setNavItems ] = useState([
        {
            name: 'Projects',
            url: `${props.path}/edit/${id}/selection/projects`,
            active: true
        }
    ]);

    const history = useHistory();

    const setEmbeddedViewList = (workbookName, viewList, viewName, viewId) => {
        setCurrentWorkbook(workbookName)
        setCurrentView(viewName)
        setCurrentViewsList(viewList)
        setCurrentViewId(viewId)
    }

    const onLinkClick = (index) => {
        let items = navItems.slice(0, index+1);
        if(items[items.length-1]) items[items.length-1].active = true;
        setNavItems(items)
    }

    const addNavItem = (name, url) => {
        navItems.forEach(item => {
            item.active = false
        });
        setNavItems(
            [
                ...navItems,
                {
                    name: name,
                    url: url,
                    active: true
                }
            ]
        )
    }

    const logoutFromVizServer = () => {
        localStorage.removeItem('TableauToken')
        localStorage.removeItem('TableauSiteId')
        localStorage.removeItem('TableauServerUrl')
        localStorage.removeItem('TableauUsername')
        localStorage.removeItem('TableauSite')
        history.push(`${path}/source`)
    }

    const handleAcceptLogout = () => {
        setShowLogoutModal(false);
        logoutFromVizServer();
    }

    const handleHideLogoutModal = () => {
        setShowLogoutModal(false);
    }

    return (
        <Switch>
            {/* <Route path={`${path}/source`}>
                <MainFrame
                    title="Dashboard Configurations"
                    topStyle="align-items-center"
                    content={(<SourceSelectionStage 
                        path={path}
                    />)}
                />
            </Route>
            <Route path={`${path}/selection/site`}>
                <MainFrame
                    title="Dashboard Configurations"
                    topStyle="align-items-center"
                    content={(<SiteSelectionStage
                        path={path}
                        addNavItem={addNavItem}
                    />)}
                />
            </Route> */}
            <Route path={`${path}/selection/:fatherType?/:id?/:childType?`}>
                <MainFrameAlt
                    title="Dashboard Configurations"
                    topStyle="align-items-center"
                    content={(
                    <div className="d-flex flex-column flex-grow-1">
                        <LinksBreadcrumb items={navItems} onClick={onLinkClick} />
                        <EditSelection
                            path={props.path}
                            setViews={setEmbeddedViewList}
                            addNavItem={addNavItem}
                            navToWorkbook={onLinkClick}
                            folderId={id}
                        />
                        <VizServerChangeModal show={showLogoutModal} onHide={handleHideLogoutModal} onAcceptBtnClick={handleAcceptLogout} />
                    </div>)}
                />
            </Route>
            <Route path={`${path}/views/:id?`}>
                <MainFrameAlt
                    title="Dashboard Configurations"
                    topStyle="align-items-center"
                    content={(
                        <div className="d-flex flex-column flex-grow-1">
                            <LinksBreadcrumb items={navItems} onClick={onLinkClick} />
                            <TableauViewContainer
                                path={path}
                                workbookName={currentWorkbook}
                                viewName={currentView}
                                viewId={currentViewId}
                                viewsList={currentViewsList}
                                addNavItem={addNavItem}
                                folderId={id}
                            />
                            <VizServerChangeModal show={showLogoutModal} onHide={handleHideLogoutModal} onAcceptBtnClick={handleAcceptLogout} />
                        </div>)}
                />
            </Route>        
        </Switch>
    )
}

export default EditViewsContainer