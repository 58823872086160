import BlueButton from "../../Reusable/BlueButton/BlueButton";
import MainFrame from "../../Reusable/MainFrame/MainFrame";
import MainFrameAlt from "../../Reusable/MainFrameAlt/MainFrameAlt";
import BackButton from "../../Reusable/MoveButton/MoveButton";
import FileAccessComponent from "../Files/FileAccessComponent";
import FileAccessModal from "../Files/FileAccessModal";

function TestContent() {

    return (
        <FileAccessModal show={true} />
    )
}

function Test() {
    return (
        <MainFrameAlt content={<TestContent/>} frameContext="not menu"/>
    );
}

export default Test;