import axios from "axios"
import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/Files';

const filesService = {

    getNodes: async (systemId, parentId, token) => {
        try {
            let local_url;
            if (parentId || parentId === 0)
                local_url = `${mainUrl}/${systemId}?ParentId=${parentId}`;
            else
                local_url = `${mainUrl}/${systemId}`;

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            const response = await axios.get(local_url, config);
            let data = response.data;

            return data;
        }
        catch (e) {
            // TODO: log error to server
            console.log(e);
            return [];
        }
    },

    getMenuNodes: async(systemId, menuId, parentId, token) => {
        try {
            let local_url;
            if (parentId || parentId === 0)
                local_url = `${mainUrl}/${systemId}/${menuId}/?ParentId=${parentId}`;
            else
                local_url = `${mainUrl}/${systemId}/${menuId}`;

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            const response = await axios.get(local_url, config);
            let data = response.data;

            return data;
        }
        catch (e) {
            // TODO: log error to server
            console.log(e);
            return [];
        }
    },

    createNewFileRef: async (fileInfo, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let response = await axios.post(mainUrl, fileInfo, config);
            return response.data;
        }
        catch(e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    createNewFolder: (folderData, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            axios.post(`${mainUrl}/Folder`, folderData, config)
                .then(result => {
                    console.log(result);
                });
        }
        catch(e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    getFileInfo: async (fileId, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let response = await axios.get(`${mainUrl}/Info/${fileId}`, config);
            console.log(response);
            return response.data[0];
        }
        catch (e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    getFolderInfo: async (folderId, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let response = await axios.get(`${mainUrl}/Folder/Info/${folderId}`, config);
            console.log(response);
            return response.data[0];
        }
        catch (e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    deleteFolderRef: async (folderId, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let res = await axios.delete(`${mainUrl}/Folder/${folderId}?delete=0`, config)
            return res;
        }
        catch (e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    deleteFileRef: async (fileId, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let res = await axios.delete(`${mainUrl}/${fileId}?delete=0`, config)
            return res;
        }
        catch(e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    updateFile: async (fileId, fileInfo, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let res = await axios.put(`${mainUrl}/${fileId}`, fileInfo, config);
            return res;
        }
        catch (e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    updateFolder: async (folderInfo, token) => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let res = await axios.put(`${mainUrl}/Folder/${folderInfo.Folder_Id}`, folderInfo, config);
            return res;
        }
        catch (e) {
            // TODO: log error to server
            console.log(e);
        }
    },

    getLookUpTree: async (systemId, parentId, token) => {
        try {
            let localUrl;
            if (parentId || parentId === 0) {
                localUrl = `${mainUrl}/Tree?SystemId=${systemId}&ParentId=${parentId}`;
            }
            else {
                localUrl = `${mainUrl}/Tree?SystemId=${systemId}`;
            }

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let res = await axios.get(localUrl, config);
            let tree = res.data.items;

            return tree;
        } catch (e) {
            // TODO: log error to server
            console.log(e);
        }
    }
}

export default filesService;