// Packages
import PropTypes from 'prop-types';

// Styles
import './AboutInfoModal.css';

// Graphic Components
import BlueButton from "../../BlueButton/BlueButton";
import Modal from "react-bootstrap/Modal";
import Icon from '../../Icon/Icon';
import gensa from '../../../../Utils/svg_icons/Gensa-logo.svg';

function AboutInfoModal({show, onHide}){

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="informationModal"
        >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <div className="text-left">
                    <div className='d-flex justify-content-start'>
                        <div className='about-logo mr-3'>
                            <img src={gensa} className='icon-sm' alt='GENSA'></img>
                        </div>
                        <div className='about-title'>
                            <h3 className="msg-title">
                                G3 CATMAN
                            </h3>
                            <p className='text-muted text-small'>
                                Version 0.1
                            </p>
                        </div>
                    </div>
                    <p className="msg-body my-4 text-dark">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Quick Overview 0.1</li>
                            <li class="list-group-item">My Files 1.0</li>
                            <li class="list-group-item">Data Puller 1.0</li>
                            <li class="list-group-item">Catalogs 1.0</li>
                        </ul>
                    </p>
                    <p className='text-muted text-small'>
                        Gensa Group. All rights reserved. G3 Catman, the logo and Gensa Group are registerd trademarks
                    </p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

AboutInfoModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}

export default AboutInfoModal