// packages
import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// styles
import './LayoutResourceSelector.css';

// services
import layoutsService from "../../../../../../Services/HttpService/LayoutService";

// graphic components
import BlueButton from "../../../../../Reusable/BlueButton/BlueButton";
import ResourceSelector from "../../../../../Reusable/ResourceSelector/ResourceSelector";

function LayoutResourceSelector(props) {
    const [ currentContext, setCurrentContext ] = useState([]);
    const [ parentId, setParentId ] = useState(0);
    const [ ancestors, setAncestors ] = useState([]);
    const systemId = useSelector(state => state.systems.selectedSystem);
    const menu = useSelector(state => state.menu.selectedMenu);

    useEffect(() => {
        ( async () => {
            let nodes = await layoutsService.getNodes(systemId, menu.Menu_Id, parentId);
            if (parentId)
                nodes.unshift({ ID: ancestors[ancestors.length-1], Name: '...', Item_Type: 'Folder' });
            setCurrentContext(nodes);
        })();
    }, [parentId, systemId]);

    const filterSelection = (resourceId, resourceType, resourceName) => {
        if (resourceType === 'Layout')
            props.onSelectResource(resourceId);
        else {
            if (resourceName === '...') {
                let localAncestors = [ ...ancestors ];
                localAncestors.pop();
                setAncestors(localAncestors);
                setParentId(resourceId);
            } else {
                let localAncestors = [ ...ancestors ];
                localAncestors.push(parentId);
                setAncestors(localAncestors);
                setParentId(resourceId);
            }
        }
    }

    const handleNextButtonClick = () => {
        if (props.selectedResource) {
            props.onTriggerTransformation();
        }
    }

    const handleResourceDoubleClick = (resourceId, resourceType, resourceName) => {
        if (resourceType === 'Layout') {
            props.onSelectResource(resourceId);
            props.onTriggerTransformation();
        } else {
            if (resourceName === '...') {
                let localAncestors = [ ...ancestors ];
                localAncestors.pop();
                setAncestors(localAncestors);
                setParentId(resourceId);
            } else {
                let localAncestors = [ ...ancestors ];
                localAncestors.push(parentId);
                setAncestors(localAncestors);
                setParentId(resourceId);
            }
        }
    }

    return(
        <div className='col-sm-6 px-4 py-1 d-flex flex-column align-items-center layoutResourceSelectorBorder'>
            <h5 className="mb-3 layoutResourceSelectorTitle">
                <strong>EXISTING LAYOUTS</strong>
            </h5>
            <div className="h-75 w-100">
                <ResourceSelector
                    onDoubleClick={handleResourceDoubleClick}
                    context={currentContext}
                    onClick={filterSelection}
                    selectedIndex={props.selectedResource}
                />
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3 w-100">
                <div className="mr-1">
                    <BlueButton
                        onClick={handleNextButtonClick}
                        title='Transform'
                        large={true}
                        disabled={props.selectedResource < 0 || !props.inputValidated}
                    />
                </div>
                <div className="ml-1">
                    <BlueButton
                        onClick={() => console.log('preview still on development')}
                        title='Preview'
                        large={true}
                        disabled={props.selectedResource < 0}
                    />
                </div>
            </div>
        </div>
    );
}

LayoutResourceSelector.propTypes = {
    onSelectResource: PropTypes.func,
    selectedResource: PropTypes.any,
    inputValidated: PropTypes.bool,
    onTriggerTransformation: PropTypes.func
}

export default LayoutResourceSelector;