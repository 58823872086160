// Components
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import BlueButton from "../BlueButton/BlueButton";
import ItemThingHorizontal from '../ItemThingHorizontal/ItemThingHorizontal';
import DataPullerTaskInfoModal from '../CustomModal/DataPullerTaskInfoModal/DataPullerTaskInfoModal';

// Services 
import * as ProcessesService from "../../../Services/HttpService/ProcessesDataService";
import * as BlobStorageService from '../../../Services/Other/BlobStorageService';

// Styles
import './ProcessesHistory.css';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner';
import { apiLoginRequest } from '../../../authConfig';
import { SelectInputModal } from '../CustomModal/SelectInputModal/SelectInputModal';

function ProcessesHistory({path, newProcessEnabled, showMenu=false, MenuId=null, hasProcessOptions, processOptions, onOptionSelection }) {
    const [ showTaskInfoModal, setShowTaskInfoModal ] = useState(false);
    const [ showPipelinesModal, setShowPipelinesModal ] = useState(false);
    const history = useHistory();
    const [ processes, setProcesses ] = useState([]);
    const [ selectedProcess, setSelectedProcess ] = useState({});
    const [ filters, setFilters ] = useState({
        startDate: '', endDate: '', taskName: '', taskStatus: ''
    });
    
    const systemId = useSelector(state => state.systems.selectedSystem);
    const systemsList = useSelector(state => state.systems.systemsList);
    const { instance, accounts, inProgress } = useMsal();

    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    useEffect(() => {
        getProcesses();
        setFilters({...filters, startDate:toDateInputValue(new Date()), endDate:toDateInputValue(new Date()) })
    }, []);

    const handleNextButtonClick = () => {
        if(hasProcessOptions) setShowPipelinesModal(true);
        else history.push(path+'/execute');
    }

    const handleOptionSelection = (input) => {
        onOptionSelection(input)
        history.push(path+'/execute');
    }

    const toDateInputValue = (date) => {
        var local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    }

    const getProcesses = (startDate=null, endDate=null, searchText=null, status=null) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                const user = accounts[0].username.replace('.com', '');
                let systemProcesses = await trackPromise(ProcessesService.getSystemProcesses(systemId, user, MenuId, authResponse.accessToken, startDate, endDate, searchText, status));
                setProcesses(systemProcesses);
            }
        })();
    }

    const getProcessInfo = (ProcessId) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });
                let process = (await ProcessesService.getProcessInfo(ProcessId, authResponse.accessToken))[0];
                process.logs = await ProcessesService.getProcessLogs(ProcessId, authResponse.accessToken);
                process.queries = await ProcessesService.getProcessQueries(ProcessId, authResponse.accessToken);

                setSelectedProcess(process);
                setShowTaskInfoModal(true);
            }
        })();
    }

    const downloadFile = (ProcessId) => {
        (async () => {
            if(inProgress === 'none' && accounts.length > 0){
                let authResponse = await instance.acquireTokenSilent({
                    account: accounts[0],
                    ...apiLoginRequest
                });

                let process = (await ProcessesService.getProcessInfo(ProcessId, authResponse.accessToken))[0];
                
                const storageFileName = process.File_Path;
                const localSystemsList = [ ...systemsList ];
                const localSystem = localSystemsList.filter(sys => sys["System_Id"] === systemId);
                const container = localSystem[0]["Container"];             
                try{
                    let download = await BlobStorageService.downloadFile(container,storageFileName);
                    let downloadBody = await download.blobBody;
                    downloadBlob(downloadBody, storageFileName.split('/').slice(1));
                }
                catch(err){
                    console.log(err);
                }
            }
        })();
    }

    function downloadBlob(blob, name){
        var blobUrl = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = blobUrl;
        link.download = name;
        // link.target = '_blank'
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
    }

    const getStatusClass = (status) => {
        if(status === 'Started') return 'pill bg-primary text-white'
        else if(status === 'Completed') return 'pill bg-success text-white'
        else if(status === 'Failed') return 'pill bg-danger text-white'
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault()
        getProcesses(filters.startDate, filters.endDate, filters.taskName, filters.taskStatus);
    }

    const onFilterChange = e => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    }

    return (
        <div className="ProcessesHistoryContainer d-flex flex-column">
            <div className="d-flex justify-content-start mt-4 box-grow-no-scroll">
                <div className="list-filters-panel br-1 px-5">
                    <form onSubmit={handleSubmitSearch}>
                        <div className='form-group'>
                            <label>Start date</label>
                            <input type='date' className='form-control' name='startDate' value={filters.startDate} onChange={onFilterChange}></input>
                        </div>
                        <div className='form-group'>
                            <label>End date</label>
                            <input type='date' className='form-control' name='endDate' value={filters.endDate} onChange={onFilterChange}></input>
                        </div>
                        <div className='form-group'>
                            <label>Status</label>
                            <select className='custom-select' name='taskStatus' value={filters.taskStatus} onChange={onFilterChange}>
                                <option value=''>Any</option>
                                <option value='Started'>Started</option>
                                <option value='Completed'>Completed</option>
                                <option value='Failed'>Failed</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label>Task name</label>
                            <input type='text' className='form-control' name='taskName' value={filters.taskName} onChange={onFilterChange}></input>
                        </div>
                        <div className='w-100'>
                            <button type="submit" className="btn btn-primary w-100">Filter</button>
                        </div>
                    </form>
                </div>
                <div className="processes-list px-5 w-100 d-flex flex-column">
                    <div className='table-actions d-flex'>
                        <div className="card px-3 mr-4 mb-2">
                            <ItemThingHorizontal
                                title="Refresh"
                                type="refresh"
                                policy="itemThingGrayHover"
                                titleSpace="mt-1"
                                measures={{ width: "1.2em" }}
                                handleClick={() => getProcesses()}
                            />
                        </div>
                        {newProcessEnabled && (
                            <div className='card px-3 mr-4 mb-2'>
                                <ItemThingHorizontal
                                    title="New process"
                                    type="file"
                                    policy="itemThingGrayHover"
                                    titleSpace="mt-1"
                                    measures={{ width: "1.2em" }}
                                    handleClick={handleNextButtonClick}
                                />
                            </div>
                        )}
                        
                    </div>
                    <div className='table-responsive h-100 overflow-y-auto'>
                        <table className='table table-stripped table-bordered table-sm'>
                            <thead className='userProcessGray userProcessTblTitle text-center'>
                                <tr className=''>
                                    <th>Process Id</th>
                                    {showMenu ? <th>Type</th> : null}
                                    <th>Task Name</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {processes.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.Process_Id}</td>
                                            {showMenu ? <td>{item.Menu_Dcs}</td> : null}
                                            <td>{item.Description}</td>
                                            <td>{new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(item.Date_Start+'Z'))}</td>
                                            <td>{item.Date_End ? new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(item.Date_End+'Z')) : '--'}</td>
                                            <td>
                                                <div className={getStatusClass(item.Status)}>
                                                    {item.Status}
                                                </div>
                                            </td>
                                            <td>
                                                {item.Status === 'Completed' &&
                                                <button className='btn btn-xs btn-outline-primary mx-2' onClick={() => downloadFile(item.Process_Id)}>Download file</button>
                                                }
                                                <button className='btn btn-xs btn-outline-dark mx-2' onClick={() => getProcessInfo(item.Process_Id)}>View details</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <LoaderSpinner color="#0077CC" />
                    </div>
                </div>
            </div>
            
                <div className="actions-row d-flex justify-content-end mx-4 my-2">
                {newProcessEnabled && (
                    <BlueButton
                        title="Start new process"
                        onClick={handleNextButtonClick}
                        large
                    />
                )}
                </div>
            <DataPullerTaskInfoModal
                show={showTaskInfoModal}
                onHide={() => setShowTaskInfoModal(false)}
                processInfo={selectedProcess}
                onRefresh={getProcessInfo}
            />
            {hasProcessOptions && (
                <SelectInputModal
                    show={showPipelinesModal}
                    onHide={() => setShowPipelinesModal(false)}
                    title="Select process type"
                    primaryText="Choose an option from the list below"
                    optionsList={processOptions}
                    acceptBtnText="Ok"
                    cancelBtnText="Cancel"
                    onAcceptBtnClick={handleOptionSelection}
                    onCancelBtnClick={() => setShowPipelinesModal(false)}
                />
            )}
        </div>
    )
}

export default ProcessesHistory;