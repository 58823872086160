
export const newFile = (name, container, type, contextId, systemId, menuId, owner, size) => {
    return {
        File_Id: -1,
        File_Name: name,
        File_Path: `${name}.${type}`,
        Url_blob: `https://stgg3appdev.blob.core.windows.net/${container}/${name}.${type}`,
        File_Type_Ext: type,
        Folder_Id: contextId,
        System_Id: systemId,
        Menu_Id: menuId,
        Active: true,
        Owner: owner,
        Date_Created: null,
        Last_Modified: owner,
        Date_Modified: null,
        Size: size
    }
}

export const newFolder = (name, systemId, menuId, parentId) => {
    return {
        Folder_Name: name,
        Parent_Id: parentId,
        System_Id: systemId,
        Menu_Id: menuId
    }
}