import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Services
//services
import * as TableauDataService from "../../../../../../../Services/HttpService/TableauDataService"

function SiteSelectionStage(props){
    let site = localStorage.getItem("TableauSite");
    const [ sites, setSites ] = useState([]);
    const [ currentSite, setCurrentSite ] = useState(site);

    const history = useHistory();

    useEffect(() => {
        getSites();
    }, []);

    const getSites = () => {
        (async () => {
            let serverUrl = localStorage.getItem("TableauServerUrl");
            let data = await TableauDataService.getSitesForServer(serverUrl);
            setSites(data.sites.site);
        })();
    }

    const handleSelectChange = (e) => {
        setCurrentSite(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(currentSite != site){
            (async () => {
                let serverUrl = localStorage.getItem("TableauServerUrl");
                await TableauDataService.switchSite(serverUrl, currentSite);
            })();
        }
        props.addNavItem('Projects', `${props.path}/selection/projects`);
        history.push(`${props.path}/selection/projects`);
    }

    return (
        <div className="container-fluid d-flex mt-4 justify-content-center flex-grow-1 align-items-center">
            <div className="card d-flex justify-content-center w-50">
                <form className="p-4" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Select a site</label>
                        <select className="form-control" defaultValue={site} value={currentSite} onChange={handleSelectChange}>
                            {sites.map((item, index) => {
                                return (
                                    <option key={index} value={item.contentUrl}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="d-flex w-100 mb-3 justify-content-end">
                        <button className="input-submit btn btn-sm btn-primary">Go</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SiteSelectionStage