// Packages
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage';

// Slices
import systemsReducer from '../Redux/Reducers/systemsSlice';
import menuReducer from '../Redux/Reducers/menuSlice';

const reducers = combineReducers({
    systems: systemsReducer,
    menu: menuReducer
});

const persisConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persisConfig, reducers);

export default configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});