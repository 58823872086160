// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';

// Styles
import './CatalogEditorModal.css';

// Graphic Components
import Modal from "react-bootstrap/Modal";
import BlueButton from '../../Reusable/BlueButton/BlueButton';
import GrayButton from '../../Reusable/GrayButton/GrayButton';

function CatalogEditorModal({show, onHide, title, columns, onAcceptBtnClick, onCancelBtnClick}){
    const [ input, setInput ] = useState('');

    const handleChange = (event) => {
        setInput(event.target.value);
    };

    const handleAcceptButtonClick = () => {
        setInput('');
        onAcceptBtnClick(input);
    }

    const handleCancelButtonClick = () => {
        setInput('');
        onCancelBtnClick();
    }

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard='false'
            centered={true}
            dialogClassName="catalogEditorModal"
        >
            <Modal.Header closeButton>
                <h3 className="msg-title">
                    {title}
                </h3>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p className="msg-body my-2 text-dark">
                        {/* {primaryText} */}
                    </p>
                </div>
                <div className='h-100 d-flex flex-column'>
                <form className='catalog-editor-form flex-gap-1 box-grow-scroll h-100'>
                    {columns.map((item, index) => {
                        // {
                        //     "Column_Id": 4,
                        //     "Column_Name": "DeployPrueba",
                        //     "Column_Desc": "Prueba",
                        //     "Type": "prueba",
                        //     "Precision": 1,
                        //     "Nullable": false,
                        //     "Edit": true,
                        //     "Value": "deployed",
                        //     "Filter": "all",
                        //     "System_Id": 9
                        // }
                        let formControl = <></>
                        if (item.Type == 'text') {
                            formControl = <input type='text' className="form-control" id={item.Column_Name} name={item.Column_Name} required={!item.Nullable} disabled={!item.Edit} />
                        }
                        else if (item.Type == 'number') {
                            formControl = <input type='number' step={item.Precision} className="form-control" id={item.Column_Name} name={item.Column_Name} required={!item.Nullable} disabled={!item.Edit} />
                        }
                        else if (item.Type == 'textArea') {
                            formControl = <textarea className='form-control' id={item.Column_Name} name={item.Column_Name} required={!item.Nullable} disabled={!item.Edit} rows='3' />
                        }
                        else if (item.Type == 'dropdown') {
                            formControl = (
                                <select className='custom-select' id={item.Column_Name} name={item.Column_Name} required={!item.Nullable} >
                                    <option value=''> - </option>
                                </select>
                            )
                        }
                        else if (item.Type == 'boolean') {
                            formControl = (
                                <div className='form-control'>
                                    <div class="form-check form-check-inline">
                                        <input type='radio' className="form-check-input" id={item.Column_Name + '1'} name={item.Column_Name} value='True' />
                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type='radio' className="form-check-input" id={item.Column_Name + '2'} name={item.Column_Name} value='False' />
                                        <label class="form-check-label" for="inlineRadio1">No</label>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div class="form-group catalog-form-group" key={index}>
                                <label htmlFor={item.Column_Name}>{item.Column_Desc}</label>
                                {formControl}
                            </div>
                        )
                    })}
                </form>
                <div className='d-flex justify-content-center my-3'>
                    <GrayButton
                        onClick={handleCancelButtonClick}
                        title='Cancel'
                        large={true}
                    />
                    <div className='mx-3'></div>
                    <BlueButton
                        onClick={handleAcceptButtonClick}
                        title='Save'
                        large={true}
                    />
                </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

CatalogEditorModal.propTypes = {
    onCancelBtnClick: PropTypes.func.isRequired,
    onAcceptBtnClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string,
    columns: PropTypes.array.isRequired
}

export default CatalogEditorModal