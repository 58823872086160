import axios from "axios"
import {apiMainUrl} from '../../apiConfig';
const mainUrl = apiMainUrl + '/api/DataPuller';

export async function getPipelinesBySystem(SystemId, MenuId, token) {
    try{
        let url = `${apiMainUrl}/api/Process/Pipeline/System/${SystemId}/${MenuId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getParametersGroups(SystemId, token) {
    try{
        let url = `${mainUrl}/Groups/${SystemId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getParametersGroupsBySystem(SystemId, token) {
    try{
        let url = `${apiMainUrl}/api/Process/Parameters/System/${SystemId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getParametersGroupsByPipeline(PipelineId, token) {
    try{
        let url = `${apiMainUrl}/api/Process/ParametersGroup/Pipeline/${PipelineId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getParametersByGroup(GroupId, token){
    try{
        let url = `${apiMainUrl}/api/Process/Parameter/Group/${GroupId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getParameters(GroupId, token) {
    try{
        let url = `${mainUrl}/Parameters/${GroupId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await axios.get(url, config);
        const data = response.data;
        return data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}

export async function getParameterValues(ParameterId, SystemId, relatedParametersValues, token) {
    try{
        let url = `${mainUrl}/Parameter/${ParameterId}&${SystemId}`
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let data = {
            parametersValues: relatedParametersValues
        };
        const response = await axios.post(url, data, config);
        return response.data;
    }
    catch(e){
        console.log(e);
        return [];
    }
}