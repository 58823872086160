// packages
import { useHistory, useRouteMatch  } from 'react-router-dom';

// styles
import '../../TransformationProcess.css';

// services
// graphic components
import InstructionsBox from "../../../../Reusable/InstructionsBox/InstructionsBox";

function ProcessInstructions() {
    const history = useHistory();
    const { path } = useRouteMatch();

    const handleNavigationButtonClick = () => {
        navigateToNextStage();
    }

    const navigateToNextStage = () => {
        history.push(`${path}/file`);
    }

    return (
        <div className="flex-grow-1 d-flex flex-column mt-5 px-5 mb-5">
            <h2 className="processInstructionsTitle mb-4">
                <strong>File Transformation Instructions</strong>
            </h2>
            <div className="container-fluid d-flex justify-content-center flex-wrap">
                <InstructionsBox
                    title="PREPARE FILE LAYOUT"
                    instructions="This is a layout to guide you to prepare your file."
                    buttonTitle="Click Here"
                    buttonClick={handleNavigationButtonClick}
                />
                <InstructionsBox
                    title="UPLOAD DATA"
                    instructions="If the data is not correct you will have to review it and upload it again,
                     in the required format."
                />
                <InstructionsBox
                    title="CONVERT FILE"
                    instructions="The file will begin processing."
                />
            </div>
        </div>
    );
}

export default ProcessInstructions;