// packages
import { useState } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// styles
// services
// graphic components
import ProcessInstructions from "./Stages/InstructionsStage/ProcessInstructions";
import FileChooseStage from "./Stages/FileChooseStage/FileChooseStage";
import LayoutChooseStage from "./Stages/LayoutChooseStage/LayoutChooseStage";
import MainFrame from "../../Reusable/MainFrame/MainFrame";
import StepsMap from "../../Reusable/StepsMap/StepsMap";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import MainFrameAlt from "../../Reusable/MainFrameAlt/MainFrameAlt";

function TransformationProcessContent() {
    const [ selectedFile, setSelectedFile ] = useState(-1);
    const [ selectedLayout, setSelectedLayout ] = useState(-1);
    const { path } = useRouteMatch();

    const handleFileSelection = (fileId) => {
        setSelectedFile(fileId);
    }

    const handleLayoutSelection = (layoutId) => {
        setSelectedLayout(layoutId);
    }

    return (
        <div className="flex-grow-1 d-flex flex-column">
            <Switch>
                <Route exact path={path}>
                    <StepsMap steps={['INSTRUCTIONS', 'FILE', 'LAYOUT']} active={0} />
                    <ProcessInstructions />
                </Route>
                <Route path={`${path}/file`}>
                    <StepsMap steps={['INSTRUCTIONS', 'FILE', 'LAYOUT']} active={1} />
                    <FileChooseStage
                        onSelectFile={handleFileSelection}
                        selectedFile={selectedFile}
                        path={path}
                    />
                </Route>
                <Route path={`${path}/layout`}>
                    {
                        selectedFile >= 0 ?
                            <>
                                <StepsMap steps={['INSTRUCTIONS', 'FILE', 'LAYOUT']} active={2} />
                                <LayoutChooseStage
                                    selectedLayout={selectedLayout}
                                    onSelectLayout={handleLayoutSelection}
                                    selectedFile={selectedFile}
                                    onSelectFile={handleFileSelection}
                                />
                            </>
                            :
                            <Redirect
                                to={{
                                    pathname: `${path}/file`
                                }}
                            />
                    }
                </Route>
            </Switch>
        </div>
    );
}

function TransformationProcess() {
    const systemId = useSelector(state => state.systems.selectedSystem);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {
                systemId === -1 ?
                    <Redirect to='/' />
                    :
                    <MainFrameAlt
                        title="File Transformation"
                        topStyle="align-items-center"
                        content={ < TransformationProcessContent /> }
                    />
            }
        </MsalAuthenticationTemplate>
    );
}

export default TransformationProcess;